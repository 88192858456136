import React from "react";
import { UseAnalytics, contactsParamsAtom } from "@/_actions/contactActions";
import { useAtom } from "jotai";
import { formatISO } from "date-fns";

export default function Analytics() {
  const [contactsParams] = useAtom(contactsParamsAtom);

  const created__gt = contactsParams?.created_range?.split(",")[0]
    ? formatISO(new Date(contactsParams?.created_range?.split(",")[0]))
    : null;
  const created__lt = contactsParams?.created_range?.split(",")[1]
    ? formatISO(new Date(contactsParams?.created_range?.split(",")[1]))
    : null;

  const { data: whatsappData, error: whatsappError } = UseAnalytics({
    event_type: "click",
    event_value: "contact_whatsapp",
    created__gt,
    created__lt,
  });

  const { data: callData, error: callError } = UseAnalytics({
    event_type: "click",
    event_value: "contact_call",
    created__gt,
    created__lt,
  });

  return (
    <div className="w-full p-2">
      <div className="card m-0 flex items-center space-x-3 rounded-md">
        <p className="text-xl font-bold">{whatsappData?.count}</p>
        <div className="flex items-center text-xs text-gray-500">
          Contactos directos en WhatsApp
        </div>
      </div>
      <div className="card m-0 flex items-center space-x-3 rounded-md">
        <p className="text-xl font-bold">{callData?.count}</p>
        <div className="flex items-center text-xs text-gray-500">
          Llamadas recibidas
        </div>
      </div>
    </div>
  );
}
