import React, { useState, useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import SimpleSteps from "@/components/navigation/Steps";
import GeneralDescription from "./general_description";
import LocationProperty from "./location";
import UploadMedia from "./upload_media";

export default function Create() {
  const [propertyId, setPropertyId] = useState(null);
  const [step, setStep] = useState(1);
  const navigate = useNavigate();
  localStorage.setItem("localId", propertyId);
  const gotoLocationStep = (response) => {
    setPropertyId(response.data.id || localStorage.getItem("localId"));
    setStep(2);
  };

  const gotoMediaStep = () => {
    try {
      setStep(3);
    } catch (err) {
      $log.error(err);
    }
  };

  const finish = () => {
    navigate("/properties");
  };

  function setStatus(index, currentIndex) {
    if (index <= currentIndex) {
      return "current";
    } else {
      return "upcoming";
    }
  }

  const propertyCreationSteps = useMemo(() => {
    return [
      {
        name: "Información General",
        description: "Inmobiliaria o agente independiente",
        href: "#",
        status: setStatus(1, step),
      },
      {
        name: "Ubicación",
        description: "Define para preparar tu plan",
        href: "#",
        status: setStatus(2, step),
      },
      {
        name: "Fotografías",
        description: "Contrata tu plan y accede a PRO",
        href: "#",
        status: setStatus(3, step),
      },
    ];
  }, [step]);

  const goBack = () => {
    setStep(step - 1);
  };

  return (
    <div className="m-5 rounded bg-white p-5">
      <SimpleSteps steps={propertyCreationSteps} />
      {(step === 1 || step === "1") && (
        <GeneralDescription
          callBack={gotoLocationStep}
          propertyId={propertyId}
          action="create"
        />
      )}
      {(step === 2 || step === "2") && (
        <LocationProperty
          callBack={gotoMediaStep}
          propertyId={propertyId}
          goBack={goBack}
          action="create"
        />
      )}
      {(step === 3 || step === "3") && (
        <UploadMedia
          callBack={finish}
          propertyId={propertyId}
          goBack={goBack}
          action="create"
        />
      )}
    </div>
  );
}
