import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  useMyProperties,
  useMyPropertiesCount,
} from "@/_actions/propertiesActions";
import PropertyTable from "../properties/PropertyTable";
import Pagination from "@/components/navigation/Pagination";
import { XIcon } from "@heroicons/react/outline";

import { propertiesParamsAtom } from "@/_actions/propertiesActions";
import { useAtom } from "jotai";

import Filters from "./filters";

export default function Properties() {
  const [params, setParams] = useAtom(propertiesParamsAtom);
  const [canPublish, setCanPublish] = useState(false);
  const [publishedPropertiesCount, setPublishedPropertiesCount] = useState(0);
  const [onlyRentProperties, setOnlyRentProperties] = useState(0);
  const [showRentsMessage, setShowRentsMessage] = useState(false);
  const { data: myProperties, mutate: mutateMyProperties } =
    useMyProperties(params);
  const { data: allPropertiesCount, mutate: mutateAllProperties } =
    useMyPropertiesCount();

  useEffect(() => {
    allPropertiesCount?.plan_free_rents >= 1
      ? setShowRentsMessage(true)
      : setShowRentsMessage(false);
    setCanPublish(allPropertiesCount?.can_publish);
  }, [allPropertiesCount]);

  useEffect(() => {
    setPublishedPropertiesCount(allPropertiesCount?.rest_of_properties);
    setOnlyRentProperties(allPropertiesCount?.rent_properties);
  }, [myProperties, allPropertiesCount]);
  const r = myProperties?.results || [];

  const navigate = useNavigate();

  return (
    <div className="divider-y space-y-4 sm:p-2 lg:p-4">
      {showRentsMessage && (
        <div className="flex justify-between rounded-lg border border-primary-default bg-[#E2EBFF] p-3 text-primary-default">
          <div>
            🥳 Ahora tu membresía incluye&nbsp;
            <span className="font-bold">
              {allPropertiesCount?.plan_free_rents} Publicaciones de Renta
              ¡GRATIS!&nbsp;
            </span>
            Muchas gracias por ser parte de Casas y Terrenos.
          </div>
          <XIcon
            className="w-6 rounded-full p-0.5 hover:bg-blue-50"
            onClick={() => setShowRentsMessage(false)}
          />
        </div>
      )}
      <div className="flex flex-1 items-center justify-between">
        <p className="title">Propiedades</p>

        {canPublish ? (
          <button
            onClick={() => navigate("/properties/create")}
            className="button-primary "
          >
            {publishedPropertiesCount === allPropertiesCount?.properties_plan
              ? "Crear propiedad en renta"
              : "Crear propiedad"}
          </button>
        ) : (
          <button className="button-primary" disabled={true}>
            Crear propiedad
          </button>
        )}
      </div>
      <Filters />
      <PropertyTable
        properties={r}
        mutateData={() => {
          mutateMyProperties();
          mutateAllProperties();
        }}
        publishedProperties={publishedPropertiesCount}
        rentProperties={onlyRentProperties}
      />
      <Pagination
        total={myProperties?.count}
        type={"Propiedad"}
        current={params.page}
        next={myProperties?.next}
        previous={myProperties?.previous}
        pageSize={10}
        onPageChange={(page) => setParams((prev) => ({ ...prev, page }))}
      />
    </div>
  );
}
