/* This example requires Tailwind CSS v2.0+ */
import React from "react";
import { useState, useEffect } from "react";
import { Switch } from "@headlessui/react";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Toggle({ status, onChange, role }) {
  const [enabled, setEnabled] = useState(status || false);

  return (
    <Switch
      disabled={role === "owner" ? true : false}
      checked={enabled}
      onChange={async () => {
        try {
          const e = !enabled;
          await onChange(e);
          setEnabled(e);
        } catch (err) {
          $log.error(err);
        }
      }}
      className="group relative inline-flex h-5 w-10 flex-shrink-0 cursor-pointer items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
    >
      <span
        aria-hidden="true"
        className="pointer-events-none absolute h-full w-full rounded-md bg-white"
      />
      <span
        aria-hidden="true"
        className={classNames(
          enabled
            ? role === "owner"
              ? "bg-blue-cyt-light"
              : "bg-blue-cyt"
            : "bg-gray-200",
          "pointer-events-none absolute mx-auto h-4 w-9 rounded-full transition-colors duration-200 ease-in-out"
        )}
      />
      <span
        aria-hidden="true"
        className={classNames(
          enabled ? "translate-x-5" : "translate-x-0",
          "pointer-events-none absolute left-0 inline-block h-5 w-5 transform rounded-full border border-gray-200 bg-white shadow ring-0 transition-transform duration-200 ease-in-out"
        )}
      />
    </Switch>
  );
}

Toggle.defaultProps = {
  onChange: () => true,
};
