import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";

export default function ModalProListings({ className }) {
  return (
    <div
      className={classNames(
        "absolute -mt-[338px] -ml-[80px] flex flex-col items-center",
        className
      )}
    >
      <div className="max-w-[270px] rounded-xl bg-[#0A2356] p-4">
        <div className="mt-2 text-sm text-white">
          <div className="mb-4">
            PRO Listings te permite comercializar vivienda nueva de
            desarrolladores reconocidos, que ha sido validada por nosotros, y
            acceder a comisiones diferenciadas para los miembros PRO de Casas y
            Terrenos.
          </div>
          <div>
            El inventario se compone de desarrollos inmobiliarios en ubicaciones
            de alta demanda, y en los niveles de precios más requeridos por el
            mercado.
          </div>
        </div>
        <div className="absolute bottom-0 left-0 right-0 mb-[-15px] flex justify-center"></div>
      </div>
      <div className="border-t-[20px] border-l-[15px] border-r-[15px] border-l-transparent border-r-transparent border-t-[#0A2356]"></div>
    </div>
  );
}

ModalProListings.propTypes = {
  className: PropTypes.string,
};
