import React, { Fragment } from "react";
import { ChevronDownIcon } from "@heroicons/react/outline";
import { Menu, Transition } from "@headlessui/react";

export default function Dropdown({ children, label }) {
  return (
    <Menu as="div" className="relative">
      <div>
        <Menu.Button className="input flex justify-between">
          {label}
          <ChevronDownIcon
            className="ml-2 -mr-1 h-5 w-5 text-violet-200 hover:text-violet-100"
            aria-hidden="true"
          />
        </Menu.Button>
      </div>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute z-50 mt-2 rounded-md border bg-white p-4 shadow-md">
          {children}
        </Menu.Items>
      </Transition>
    </Menu>
  );
}
