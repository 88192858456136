import React, { useState } from "react";
import { Formik, Field, Form, FieldArray } from "formik";
import { RadioGroup } from "@headlessui/react";
import acquisitionClient from "@/services/acquisitionApiClient";
import { useNavigate } from "react-router-dom";
import taxSystems from "@/constants/tax_systems";
import * as Yup from "yup";
import Notification from "@/components/overlays/Notification";

function removeEmpty(obj) {
  return Object.entries(obj)
    .filter(([_, v]) => v != "")
    .reduce((acc, [k, v]) => ({ ...acc, [k]: v }), {});
}

const Billing = () => {
  const navigate = useNavigate();
  const [showNotification, toggleNotification] = useState(false);
  const [textNotification, setTextNotification] = useState("");
  const [timeNotification, setTimeNotification] = useState(0);
  const handleToggleNotification = (text) => {
    setTextNotification(text);
    toggleNotification(true);
  };

  const onSubmit = async (values, actions) => {
    try {
      const { setSubmitting } = actions;
      let data = {
        ...removeEmpty(values),
        ...{
          trade_name:
            values.type === "PF"
              ? `${values.first_name} ${values.last_name} ${values.second_last_name}`
              : values.legal_name,
        },
      };
      await acquisitionClient.post("/customers/invoices/", data);
      setSubmitting(false);
      navigate("/unirse/confirmar-datos");
    } catch (err) {
      if (err?.response?.status === 404) {
        navigate("/unirse/confirmar-datos");
      } else if (
        err.response?.status === 400 &&
        err.response?.data.message.includes("Validación de timbrado")
      ) {
        toggleNotification(true);
        setTimeNotification(35000);
        handleToggleNotification(err.response?.data.message);
      }
      $log.error(err);
    }
  };
  const SignupSchema = Yup.object().shape({
    type: Yup.string().oneOf(["PF", "PM"]),
    first_name: Yup.string().when("type", {
      is: "PF",
      then: Yup.string().required("Campo requerido"),
    }),
    last_name: Yup.string().when("type", {
      is: "PF",
      then: Yup.string().required("Campo requerido"),
    }),
    second_last_name: Yup.string().when("type", {
      is: "PF",
      then: Yup.string().required("Campo requerido"),
    }),
    legal_name: Yup.string().when("type", {
      is: "PM",
      then: Yup.string().required("Campo requerido"),
    }),
    tax_identifier: Yup.string().required("Ingresa tu RFC"),
    tax_system: Yup.string()
      .required("Campo requerido")
      .oneOf(taxSystems.map((taxSystem) => taxSystem.id)),
    street: Yup.string().required("Campo requerido"),
    exterior: Yup.string().required("Campo requerido"),
    interior: Yup.string(),
    neighborhood: Yup.string().required("Campo requerido"),
    city: Yup.string().required("Campo requerido"),
    postal_code: Yup.string().required("Campo requerido"),
    state: Yup.string().required("Campo requerido"),
  });
  return (
    <Formik
      onSubmit={onSubmit}
      initialValues={{
        type: "PF",
        first_name: "",
        last_name: "",
        second_last_name: "",
        legal_name: "",
        exterior: "",
        interior: "",
        tax_identifier: "",
        tax_system: 601,
        street: "",
        neighborhood: "",
        city: "",
        state: "Aguascalientes",
        country: "MEX",
      }}
      validationSchema={SignupSchema}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        setFieldValue,
        isSubmitting,
      }) => (
        <Form
          onSubmit={handleSubmit}
          className="relative w-full space-y-3 md:mb-10"
        >
          <RadioGroup
            value={values.type}
            onChange={(val) => setFieldValue("type", val)}
          >
            <RadioGroup.Label className="form-title my-10  ">
              ¿Qué describe mejor tu situación?
            </RadioGroup.Label>
            <div className="flex flex-1  ">
              <RadioGroup.Option value="PF">
                {({ checked }) => (
                  <>
                    <RadioGroup.Label
                      as="div"
                      className={`cursor-pointer rounded-l-md border border-blue-800 py-2 px-3 text-center text-base font-medium leading-6 ${
                        checked
                          ? "bg-blue-800 text-white"
                          : "bg-white text-gray-800"
                      }`}
                    >
                      Persona Física
                    </RadioGroup.Label>
                  </>
                )}
              </RadioGroup.Option>
              <RadioGroup.Option value="PM">
                {({ checked }) => (
                  <>
                    <RadioGroup.Label
                      as="div"
                      className={`cursor-pointer rounded-r-md border border-blue-800 py-2 px-3 text-center text-base font-medium leading-6 ${
                        checked
                          ? "bg-blue-800 text-white"
                          : "bg-white text-gray-800"
                      }`}
                    >
                      Persona Moral
                    </RadioGroup.Label>
                  </>
                )}
              </RadioGroup.Option>
            </div>
          </RadioGroup>

          <div className="grid-cols-2 gap-6 space-y-3 md:grid md:space-y-0">
            {values.type === "PF" && (
              <>
                <div>
                  <label htmlFor="first_name" className=" font-medium">
                    Nombre
                  </label>
                  <Field
                    as="input"
                    type="text"
                    name="first_name"
                    className="input"
                  />
                  {errors.first_name && touched.first_name && (
                    <div className="text-sm text-red-600">
                      {errors.first_name}
                    </div>
                  )}
                </div>

                <div>
                  <label htmlFor="last_name" className=" font-medium">
                    Apellido Paterno
                  </label>
                  <Field
                    as="input"
                    type="text"
                    name="last_name"
                    className="input"
                  />
                  {errors.last_name && touched.last_name && (
                    <div className="text-sm text-red-600">
                      {errors.last_name}
                    </div>
                  )}
                </div>
                <div>
                  <label htmlFor="second_last_name" className=" font-medium">
                    Apellido Materno
                  </label>
                  <Field
                    as="input"
                    type="text"
                    name="second_last_name"
                    className="input"
                  />
                  {errors.second_last_name && touched.second_last_name && (
                    <div className="text-sm text-red-600">
                      {errors.second_last_name}
                    </div>
                  )}
                </div>
              </>
            )}

            {values.type === "PM" && (
              <div>
                <label htmlFor="legal_name" className=" font-medium">
                  Razón Social
                </label>
                <p className="text-xs ">
                  Sin el régimen societario (ej.: S.A. de C.V.).
                </p>
                <Field
                  as="input"
                  type="text"
                  name="legal_name"
                  className="input"
                />
                {errors.legal_name && touched.legal_name && (
                  <div className="text-sm text-red-600">
                    {errors.legal_name}
                  </div>
                )}
              </div>
            )}
            <div>
              <label htmlFor="tax_identifier" className=" font-medium">
                RFC
              </label>
              <Field
                as="input"
                type="text"
                name="tax_identifier"
                className="input"
              />
              {errors.tax_identifier && touched.tax_identifier && (
                <div className="text-sm text-red-600">
                  {errors.tax_identifier}
                </div>
              )}
            </div>
            <div>
              <label htmlFor="tax_system" className=" font-medium">
                Regimen Fiscal
              </label>
              <Field
                as="select"
                name="tax_system"
                className="input"
                value={values.tax_system}
              >
                {taxSystems.map((tax_system) => (
                  <option key={tax_system.id} value={tax_system.id}>
                    {tax_system.name}
                  </option>
                ))}
              </Field>
              {errors.tax_system && touched.tax_system && (
                <div className="text-sm text-red-600">{errors.tax_system}</div>
              )}
            </div>
          </div>

          <p className="space-y-8 font-normal">Domicilio:</p>
          <div className="grid-cols-2 gap-6 space-y-3 md:grid md:space-y-0">
            <div>
              <label htmlFor="street" className=" font-medium">
                Calle
              </label>
              <Field as="input" type="text" name="street" className="input" />
              {errors.street && touched.street && (
                <div className="text-sm text-red-600">{errors.street}</div>
              )}
            </div>
            <div>
              <label htmlFor="exterior" className=" font-medium">
                Número Exterior
              </label>
              <Field as="input" type="text" name="exterior" className="input" />
              {errors.exterior && touched.exterior && (
                <div className="text-sm text-red-600">{errors.exterior}</div>
              )}
            </div>
            <div>
              <label htmlFor="interior" className=" font-medium">
                Número Interior
              </label>
              <Field as="input" type="text" name="interior" className="input" />
              {errors.interior && touched.interior && (
                <div className="text-sm text-red-600">{errors.interior}</div>
              )}
            </div>

            <div>
              <label htmlFor="neighborhood" className=" font-medium">
                Colonia
              </label>
              <Field
                as="input"
                type="text"
                name="neighborhood"
                className="input"
              />
              {errors.neighborhood && touched.neighborhood && (
                <div className="text-sm text-red-600">
                  {errors.neighborhood}
                </div>
              )}
            </div>
            <div>
              <label htmlFor="city" className=" font-medium">
                Ciudad
              </label>
              <Field as="input" type="text" name="city" className="input" />
              {errors.city && touched.city && (
                <div className="text-sm text-red-600">{errors.city}</div>
              )}
            </div>
            <div>
              <label htmlFor="postal_code" className=" font-medium">
                Código Postal
              </label>
              <Field
                as="input"
                type="text"
                name="postal_code"
                className="input"
              />
              {errors.postal_code && touched.postal_code && (
                <div className="text-sm text-red-600">{errors.postal_code}</div>
              )}
            </div>
            <div>
              <label htmlFor="state" className="mb-1 text-sm font-medium">
                Estado
              </label>
              <Field as="select" className="select" name="state">
                <option value="Aguascalientes">Aguascalientes</option>
                <option value="Baja California">Baja California</option>
                <option value="Baja California Sur">Baja California Sur</option>
                <option value="Campeche">Campeche</option>
                <option value="Chiapas">Chiapas</option>
                <option value="Chihuahua">Chihuahua</option>
                <option value="CDMX">Ciudad de México</option>
                <option value="Coahuila">Coahuila</option>
                <option value="Colima">Colima</option>
                <option value="Durango">Durango</option>
                <option value="Estado de México">Estado de México</option>
                <option value="Guanajuato">Guanajuato</option>
                <option value="Guerrero">Guerrero</option>
                <option value="Hidalgo">Hidalgo</option>
                <option value="Jalisco">Jalisco</option>
                <option value="Michoacán">Michoacán</option>
                <option value="Morelos">Morelos</option>
                <option value="Nayarit">Nayarit</option>
                <option value="Nuevo León">Nuevo León</option>
                <option value="Oaxaca">Oaxaca</option>
                <option value="Puebla">Puebla</option>
                <option value="Querétaro">Querétaro</option>
                <option value="Quintana Roo">Quintana Roo</option>
                <option value="San Luis Potosí">San Luis Potosí</option>
                <option value="Sinaloa">Sinaloa</option>
                <option value="Sonora">Sonora</option>
                <option value="Tabasco">Tabasco</option>
                <option value="Tamaulipas">Tamaulipas</option>
                <option value="Tlaxcala">Tlaxcala</option>
                <option value="Veracruz">Veracruz</option>
                <option value="Yucatán">Yucatán</option>
                <option value="Zacatecas">Zacatecas</option>
              </Field>

              {errors.state && touched.state && (
                <div className="text-sm text-red-600">{errors.state}</div>
              )}
            </div>
          </div>
          <div className="sticky bottom-0 z-50 grid  w-full grid-cols-2 bg-white p-3 md:absolute md:bg-transparent md:p-5">
            <a href="/unirse/confirmar-datos" className="self-center underline">
              Saltar
            </a>
            <div className="sticky bottom-0 bg-white p-2 md:block md:p-0">
              <button
                disabled={isSubmitting}
                type="submit"
                className="group relative flex w-full justify-center rounded-md border border-transparent bg-blue-900 py-2 px-4 text-lg font-medium text-white hover:bg-blue-800 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
              >
                <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                  <svg
                    className="h-5 w-5 text-blue-900 group-hover:text-blue-800"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      fillRule="evenodd"
                      d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z"
                      clipRule="evenodd"
                    />
                  </svg>
                </span>
                Generar factura
              </button>
            </div>
          </div>
          <Notification
            open={showNotification}
            setOpen={toggleNotification}
            title={textNotification}
            timeOut={timeNotification}
            type="error"
          />
        </Form>
      )}
    </Formik>
  );
};

export default Billing;
