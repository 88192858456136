import React from "react";
import Select from "@/components/forms/Select.jsx";
import Dropdown from "@/components/elements/Dropdown";
import Checkbox from "@/components/forms/Checkbox";
import {
  useStates,
  useMunicipalities,
  useNeighborhoods,
} from "@/_actions/regionActions";
import {
  propertiesParamsAtom,
  propertiesParamsInventoryAtom,
} from "@/_actions/propertiesActions";

import { useAtom } from "jotai";

function clearFilters(setParams, type) {
  setParams(
    type === "inventory"
      ? {
          page: 1,
          full: true,
          status: "published",
          ordering: "-created",
        }
      : { page: 1, status: "published", ordering: "-created" }
  );
}

export default function Desktop({
  propertyTypes,
  operationTypes,
  origins,
  status,
  basic,
  type,
}) {
  const [params, setParams] = useAtom(
    type === "inventory" ? propertiesParamsInventoryAtom : propertiesParamsAtom
  );

  return (
    <>
      <h2 className="form-title pr-6">Filtros</h2>
      <div className="w-full space-y-2">
        <section className="flex w-full flex-wrap">
          <div className="mr-2 mt-2 flex-grow">
            <Dropdown
              name="property_type"
              label={`Tipo de propiedad ${
                params?.property_type?.length
                  ? `(${params.property_type.length})`
                  : ""
              }`}
            >
              <div
                role="group"
                aria-labelledby="checkbox-group"
                className="w-full space-y-3"
              >
                {propertyTypes.map((pt, i) => (
                  <div className="relative flex items-start" key={i}>
                    <div className="flex h-5 items-center">
                      <input
                        type="checkbox"
                        checked={
                          params?.property_type?.includes(pt.id) || false
                        }
                        onChange={(e) => {
                          if (e.target.checked) {
                            const property_type_array =
                              params?.property_type?.length > 0
                                ? [...params.property_type, pt.id]
                                : [pt.id];
                            setParams({
                              ...params,
                              property_type: property_type_array,
                            });
                          } else {
                            setParams({
                              ...params,
                              property_type: params?.property_type.filter(
                                (p) => p !== pt.id
                              ),
                            });
                          }
                        }}
                        id={pt.id}
                        className="text-primary-defult h-4 w-4 rounded border-gray-300"
                      />
                    </div>
                    <div className="ml-3 text-sm leading-5">
                      <label
                        htmlFor={pt.id}
                        className="font-medium text-gray-700"
                      >
                        {pt.name}
                      </label>
                    </div>
                  </div>
                ))}
              </div>
            </Dropdown>
          </div>
          <div className="mr-2 mt-2 flex-grow">
            <Dropdown
              name="operation_type"
              label={`Tipo de operación ${
                params?.operation_type?.length
                  ? `(${params.operation_type.length})`
                  : ""
              }`}
            >
              <div
                role="group"
                aria-labelledby="checkbox-group"
                className="w-full space-y-3"
              >
                {operationTypes.map((ot, i) => (
                  <div className="relative flex items-start" key={i}>
                    <div className="flex h-5 items-center">
                      <input
                        type="checkbox"
                        checked={
                          params?.operation_type?.includes(ot.id) || false
                        }
                        onChange={(e) => {
                          if (e.target.checked) {
                            const operation_type_array =
                              params?.operation_type?.length > 0
                                ? [...params.operation_type, ot.id]
                                : [ot.id];
                            setParams({
                              ...params,
                              operation_type: operation_type_array,
                            });
                          } else {
                            setParams({
                              ...params,
                              operation_type: params?.operation_type.filter(
                                (o) => o !== ot.id
                              ),
                            });
                          }
                        }}
                        id={ot.id}
                        className="text-primary-defult h-4 w-4 rounded border-gray-300"
                      />
                    </div>
                    <div className="ml-3 text-sm leading-5">
                      <label
                        htmlFor={ot.id}
                        className="font-medium text-gray-700"
                      >
                        {ot.name}
                      </label>
                    </div>
                  </div>
                ))}
              </div>
            </Dropdown>
          </div>
          {basic || type === "inventory" ? null : (
            <div className="mr-2 mt-2 flex-grow">
              <Select
                id="status"
                name="status"
                className="input"
                options={status}
                onChange={(e) => {
                  setParams({ ...params, status: e.target.value });
                }}
                value={params.status}
                label="Estatus"
              />
            </div>
          )}
          <div className="mt-2 flex-grow">
            <Dropdown name="range" label="Rango de precio">
              <div className="flex w-full items-center space-x-2">
                <input
                  id="price_min"
                  className="input"
                  name="price_min"
                  placeholder="Minimo"
                  type="number"
                  value={params.price_min || ""}
                  onChange={(e) => {
                    setParams({ ...params, price_min: e.target.value });
                  }}
                />
                <div>-</div>
                <input
                  id="price_max"
                  className="input"
                  placeholder="Maximo"
                  name="price_max"
                  type="number"
                  value={params.price_max || ""}
                  onChange={(e) => {
                    setParams({ ...params, price_max: e.target.value });
                  }}
                />
              </div>
            </Dropdown>
          </div>
        </section>
        <div className="flex justify-between">
          <section className="flex flex-wrap">
            <div className="mr-2 mt-2">
              <LocationSelect
                id="state"
                name="state"
                className="input"
                useOptions={useStates}
                label="Estado"
                onChange={(e) => {
                  setParams({ ...params, state: e.target.value });
                }}
                value={params.state ? params.state : ""}
              />
            </div>
            <div className="mr-2 mt-2">
              <LocationSelect
                id="municipality"
                name="municipality"
                className="input"
                selected={params?.state ? true : false}
                useOptions={() =>
                  useMunicipalities({ state_id: params?.state })
                }
                optionValue={params?.state}
                label="Municipio"
                onChange={(e) => {
                  setParams({ ...params, municipality: e.target.value });
                }}
                value={params.municipality ? params.municipality : ""}
              />
            </div>
            <div className="mr-2 mt-2">
              <LocationSelect
                id="colony"
                name="colony"
                className="input"
                selected={params?.municipality ? true : false}
                useOptions={() =>
                  useNeighborhoods({ municipality: params?.municipality })
                }
                optionValue={params?.municipality}
                label="Colonia"
                onChange={(e) => {
                  setParams({ ...params, colony: e.target.value });
                }}
                value={params.colony ? params.colony : ""}
              />
            </div>
            {basic ? null : (
              <div className="mr-2 mt-2">
                <Dropdown
                  name="origin"
                  label={`Origen ${
                    params?.origin?.length ? `(${params.origin.length})` : ""
                  }`}
                >
                  <div
                    role="group"
                    aria-labelledby="checkbox-group"
                    className="w-full space-y-3"
                  >
                    {origins.map((pt, i) => (
                      <div className="relative flex items-start" key={i}>
                        <div className="flex h-5 items-center">
                          <input
                            type="checkbox"
                            checked={params?.origin?.includes(pt.id) || false}
                            onChange={(e) => {
                              if (e.target.checked) {
                                const origin_array =
                                  params?.origin?.length > 0
                                    ? [...params.origin, pt.id]
                                    : [pt.id];
                                setParams({
                                  ...params,
                                  origin: origin_array,
                                });
                              } else {
                                setParams({
                                  ...params,
                                  origin: params?.origin.filter(
                                    (p) => p !== pt.id
                                  ),
                                });
                              }
                            }}
                            id={pt.id}
                            className="text-primary-defult h-4 w-4 rounded border-gray-300"
                          />
                        </div>
                        <div className="ml-3 text-sm leading-5">
                          <label
                            htmlFor={pt.id}
                            className="font-medium text-gray-700"
                          >
                            {pt.name}
                          </label>
                        </div>
                      </div>
                    ))}
                  </div>
                </Dropdown>
              </div>
            )}

            <div className="flex items-center align-middle">
              <label className="">
                <input
                  className="mr-2"
                  type="checkbox"
                  name="featured"
                  checked={params?.featured || false}
                  onChange={(e) => {
                    setParams({ ...params, featured: e.target.checked });
                  }}
                />
                Destacados
              </label>
            </div>
          </section>
          <div className="flex items-center pl-2 pt-1 ">
            <button
              className="button-primary"
              onClick={() => clearFilters(setParams, type)}
            >
              Limpiar Filtros
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

Desktop.defaultProps = {
  basic: false,
};

const LocationSelect = ({
  selected,
  useOptions,
  optionValue = "",
  ...props
}) => {
  const { data = [] } = useOptions(optionValue);
  return <Select {...props} options={data} selected={selected} />;
};
