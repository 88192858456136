import React from "react";

export default function Checkboxes({ label, field, id, ...props }) {
  return (
    <div className="relative flex items-start">
      <div className="flex h-5 items-center">
        <input
          type="checkbox"
          {...field}
          {...props}
          id={id}
          // checked={field.includes(id)}
          className="text-primary-defult h-4 w-4 rounded border-gray-300"
        />
      </div>
      <div className="ml-3 text-sm leading-5">
        <label htmlFor={id} className="font-medium text-gray-700">
          {label}
        </label>
      </div>
    </div>
  );
}
