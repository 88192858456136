import React, { Suspense } from "react";
import { createRoot } from "react-dom/client";
import "./index.css";
import { BrowserRouter } from "react-router-dom";
import { SWRConfig } from "swr";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import App from "./App";

Sentry.init({
  dsn: "https://34a140c4be23445385fcd863157f05a2@o268819.ingest.sentry.io/6397328",
  integrations: [new BrowserTracing()],
  tracesSampleRate: 0.5,
});

const container = document.getElementById("root");
const root = createRoot(container);

root.render(
  <BrowserRouter>
    <Suspense fallback="Cargando...">
      <SWRConfig
        value={{
          onError: (error, key) => {
            $log.error(new Error(`Error on request ${key}`, { cause: error }));
          },
        }}
      >
        <App />
      </SWRConfig>
    </Suspense>
  </BrowserRouter>
);
