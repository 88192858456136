import React, { useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import apiClient from "@/utils/apiClient";
import * as Yup from "yup";

const UserSchema = Yup.object().shape({
  first_name: Yup.string().required("Nombre es un campo requerido."),
  last_name: Yup.string().required("Apellido Paterno es un campo requerido."),
  second_last_name: Yup.string(),
  phone_number: Yup.string().required("Teléfono es un campo requerido."),
  whatsapp: Yup.string(),
});

export default function EditUserForm({ userData, callBack }) {
  const submit = async (values) => {
    try {
      await apiClient.patch(`/membership/${values.id}`, values);
      callBack && callBack(values);
    } catch (err) {
      $log.error(err);
    }
  };
  return (
    <>
      <Formik
        initialValues={userData}
        validationSchema={UserSchema}
        onSubmit={submit}
      >
        <Form className="block space-y-3">
          <div className="block">
            <label className="form-label" htmlFor="first_name">
              * Nombre
            </label>
            <Field
              id="first_name"
              className="input"
              type="text"
              name="first_name"
              placeholder="Nombre(s)"
            />
            <ErrorMessage
              render={(msg) => <div className="input-error">{msg}</div>}
              name="first_name"
            />
          </div>
          <div>
            <label className="form-label" htmlFor="last_name">
              * Apellido Paterno
            </label>
            <Field
              id="last_name"
              className="input"
              type="text"
              name="last_name"
              placeholder="Apellido Paterno"
            />
            <ErrorMessage
              render={(msg) => <div className="input-error">{msg}</div>}
              name="last_name"
            />
          </div>

          <div>
            <label className="form-label" htmlFor="second_last_name">
              Apellido Materno
            </label>
            <Field
              id="second_last_name"
              className="input"
              type="text"
              name="second_last_name"
              placeholder="Apellido Materno"
            />
            <ErrorMessage
              render={(msg) => <div className="input-error">{msg}</div>}
              name="second_last_name"
            />
          </div>

          <div>
            <label className="form-label" htmlFor="email">
              * Email
            </label>
            <Field
              disabled={true}
              id="email"
              className="input"
              type="email"
              name="email"
              placeholder="email"
              style={{ color: "grey", backgroundColor: "#e8f4fc" }}
            />
            <ErrorMessage
              render={(msg) => <div className="input-error">{msg}</div>}
              name="email"
            />
          </div>

          <div>
            <label className="form-label" htmlFor="whatsapp">
              Whatsapp
            </label>
            <Field
              id="whatsapp"
              className="input"
              type="text"
              name="whatsapp"
              placeholder="whatsapp"
            />
            <ErrorMessage
              render={(msg) => <div className="input-error">{msg}</div>}
              name="whatsapp"
            />
          </div>

          <div>
            <label className="form-label" htmlFor="phone_number">
              * Teléfono
            </label>
            <Field
              id="phone_number"
              className="input"
              type="text"
              name="phone_number"
              placeholder="teléfono"
            />
            <ErrorMessage
              render={(msg) => <div className="input-error">{msg}</div>}
              name="phone_number"
            />
          </div>

          <button className="button-primary w-full" type="submit">
            Editar Usuario
          </button>
        </Form>
      </Formik>
    </>
  );
}

EditUserForm.defaultProps = {
  callBack: null,
};
