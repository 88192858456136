import apiClient from "../utils/apiClient";
import useSWR from "swr";

/**
 * @typedef {Object} SWRObject
 * @property {object} data - The data returned by the swr call
 * @property {boolean} isLoading - State of the swr call
 */

/** @function
 * @name useBusiness
 * @description - Returns the business data for te current user client
 * @param {Number} clientId - id fro the client
 * @returns {SWRObject} - swr Object
 */
export const useBusiness = (clientId) => {
  const fetcher = (url) => apiClient.get(url).then((res) => res.data);
  const { data, error, mutate } = useSWR(`clients/pro/${clientId}`, fetcher);

  return { data, isLoading: !error && !data, error, mutate };
};

/** @function
 * @name updateBusiness
 * @description - Update image for client business
 * @param {Number} clientId - Id for the client
 * @param {Object} data - Data for the image to change
 */
export const updateBusiness = async (clientId, data) => {
  try {
    await apiClient.patch(`/clients/update/pro/${clientId}`, data);
  } catch (err) {
    $log.error(err);
  }
};

/**
 * @typedef {Object} SWRObject
 * @property {object} data - The data returned by the swr call
 * @property {boolean} isLoading - State of the swr call
 */

/** @function
 * @name usePermaLink
 * @description - Returns the actual permaLink for the clients
 * @returns {SWRObject} - swr Object
 */

export const usePermaLink = () => {
  const fetcher = (url) => apiClient.get(url).then((res) => res.data);
  const { data, error, mutate } = useSWR("/clients/permalink", fetcher);
  return { data, isLoading: !error && !data, error, mutate };
};

/** @function
 * @name updatePermaLink
 * @description - Update the permalink for clients
 * @param {object} data - data for the formik form
 */

export const updatePermaLink = async (data) => {
  try {
    await apiClient.patch("/clients/permalink", data);
  } catch (err) {
    $log.error(err);
  }
};
