import React, { useState, useEffect } from "react";
import { format, parseISO } from "date-fns";
import InfoBanner from "@/components/elements/InfoBanner";

import { getReceipts } from "@/_actions/receiptsActions";
const urlPlibo = import.meta.env.VITE_URL_PLIBO || "https://staging.plibo.app";
const date = (d) => {
  const parsedDate = parseISO(d);
  return format(parsedDate, "dd-MM-yyyy", new Date());
};

const currencyNumberParser = (value) => {
  return `$ ${parseFloat(value)
    .toFixed(2)
    .replace(/\B(?=(\d{3})+\.)/g, "$&,")}`;
};

export default function Receipts() {
  const [receiptsData, setReceiptsData] = useState();

  const getData = async () => {
    const d = await getReceipts();
    setReceiptsData(d.data.results);
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <div className="sm:p-2 lg:p-4">
      <InfoBanner />
      <div className="sm:flex sm:items-end sm:p-2 lg:p-4">
        <div className="space-y-2 sm:flex-auto">
          <h1 className="text-xl font-semibold text-gray-900">Recibos</h1>
          <p className="text-sm">
            Recuerda que solo tienes hasta el fin del mes en el que realizaste
            tú compra para solicitar la factura correspondiente. De lo contrario
            solo estará disponible el recibo.
          </p>
        </div>
      </div>
      {receiptsData && (
        <div className="mt-8 flex flex-col">
          <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
              <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                <table className="min-w-full divide-y divide-gray-300">
                  <thead className="bg-gray-50">
                    <tr>
                      <th
                        scope="col"
                        className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                      >
                        Fecha
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                      >
                        Número de folio
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                      >
                        Tipo de producto
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                      >
                        Costo
                      </th>
                      {receiptsData && (
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                        ></th>
                      )}
                    </tr>
                  </thead>
                  <tbody>
                    {receiptsData.map((receipt) => (
                      <tr key={receipt.id}>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                          {date(receipt.created)}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm font-medium text-gray-900">
                          {receipt.id}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                          {receipt.type === "receipt" ? "Recibo" : "Factura"}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                          {currencyNumberParser(receipt.total)}
                        </td>
                        <td className="whitespace-nowrap px-6 py-4 text-right text-sm font-medium">
                          {receipt.receipt_id && (
                            <a
                              href={`${urlPlibo}/autofacturacion?receiptId=${receipt.receipt_id}`}
                              className="hover:text-indigo-900 hover:underline"
                              style={{ textColor: "rgb(2, 0, 36)" }}
                              data-splitbee-event="receipts-get-invoice"
                            >
                              Solicitar Factura
                            </a>
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
