import React from "react";
import NumberFormat from "react-number-format";
import { Formik } from "formik";
import { updateProfile } from "@/_actions/userActions";

export default function PersonalInformation(props) {
  const { data, callBack } = props;
  const { contact, email } = data;
  const onSubmit = async (values) => {
    const { first_name, last_name, mobile, whatsapp } = values;
    const format = {
      first_name,
      last_name,
      contact: {
        mobile,
        whatsapp,
      },
    };
    try {
      await updateProfile(format);
      callBack && callBack(format);
    } catch (err) {
      $log.error(err);
    }
  };

  return (
    <div>
      <Formik
        initialValues={{
          first_name: data.first_name,
          last_name: data.last_name,
          mobile: data?.contact.mobile,
          whatsapp: data?.contact.whatsapp,
        }}
        onSubmit={onSubmit}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleSubmit,
          isSubmitting,
          setFieldValue,
        }) => (
          <form
            onSubmit={handleSubmit}
            data-splitbee-event="account-edit-contact-save"
          >
            {errors.email && touched.email && errors.email}

            <div className="m-2">
              <label
                htmlFor="first_name"
                className="block text-sm font-medium text-gray-700"
              >
                Nombre(s)
              </label>
              <input
                id="first_name"
                name="first_name"
                type="text"
                value={values.first_name}
                onChange={handleChange}
                placeholder="Abc"
                className="input "
              />
            </div>

            <div className="m-2 ">
              <label
                htmlFor="last_name"
                className="block text-sm font-medium text-gray-700"
              >
                Apellido
              </label>
              <input
                id="last_name"
                name="last_name"
                value={values.last_name}
                onChange={handleChange}
                type="text"
                className="input"
              />
            </div>

            <div className="m-2 ">
              <label
                htmlFor="mobile"
                className="block text-sm font-medium text-gray-700"
              >
                Teléfono
              </label>
              <NumberFormat
                id="mobile"
                name="mobile"
                value={values.mobile}
                format="## #### ####"
                onValueChange={({ value }) => {
                  setFieldValue("mobile", value);
                }}
                type="text"
                className="input "
              />
            </div>

            <div className="m-2 ">
              <label
                htmlFor="whatsapp"
                className="block text-sm font-medium text-gray-700"
              >
                Whatsapp
              </label>
              <NumberFormat
                id="whatsapp"
                name="whatsapp"
                value={values.whatsapp}
                format="## #### ####"
                onValueChange={({ value }) => {
                  setFieldValue("whatsapp", value);
                }}
                type="text"
                className="input "
              />
            </div>

            <div className="m-2 ">
              <label
                htmlFor="email"
                className="block text-sm font-medium text-gray-700"
              >
                Email (no se puede editar)
              </label>
              <input
                id="email"
                name="email"
                value={email}
                disabled
                type="text"
                className="input"
              />
            </div>

            <div className="m-2 mt-3">
              <button
                type="submit"
                disabled={isSubmitting}
                className="button-secondary"
              >
                Guardar
              </button>
            </div>

            <div className="mx-2">
              {errors.password && touched.password && errors.password}
            </div>
          </form>
        )}
      </Formik>
    </div>
  );
}

PersonalInformation.defaultProps = {
  callBack: null,
};
