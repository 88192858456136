import React, { useState, useEffect } from "react";
import { useUsers, toggleUser, useProfile } from "@/_actions/userActions";
import Modal from "@/components/overlays/Modal";
import AddUserForm from "./forms/AddUserForm";
import EditUserForm from "./forms/EditUserForm";
import Toggle from "@/components/forms/Toggle";
import Notification from "@/components/overlays/Notification";
import { usePlan } from "@/_actions/authActions";
import Pagination from "@/components/navigation/Pagination";
import { TrashIcon } from "@heroicons/react/outline";
import InfoBanner from "@/components/elements/InfoBanner";

export default function Users() {
  const [showModal, toggleModal] = useState(false);
  const [showNotification, toggleNotification] = useState(false);
  const [showEditNotification, toggleEditNotification] = useState(false);
  const [params, setParams] = useState({
    search: "",
    status: "1",
  });
  const { data: users, mutate: mutateUsers } = useUsers(params);
  const { data: activeUsers, mutate: mutateActiveUsers } = useUsers({
    status: 1,
  });

  const { data: plan } = usePlan();
  const { data: profile, mutate: mutateProfile } = useProfile();
  const [showEditModal, toggleEditModal] = useState(false);
  const [showDeleteModal, toggleDeleteModal] = useState(false);
  const [userDelete, setUserDelete] = useState();
  const [userData, setUserData] = useState({});
  const [canCreate, setCanCreate] = useState(false);

  useEffect(() => {
    activeUsers?.count < plan?.plan_addons?.client_promoters ||
    plan?.plan_addons?.client_promoters === -1
      ? setCanCreate(true)
      : setCanCreate(false);
  }, [activeUsers, plan?.plan_addons?.client_promoters]);
  return (
    <>
      <InfoBanner />
      <div className="p-4 sm:p-6 lg:p-8 ">
        <div className="sm:flex sm:items-end">
          <div className="space-y-2 sm:flex-auto">
            <h1 className="text-xl font-semibold text-gray-900">Usuarios</h1>
            <input
              placeholder="Buscar por nombre o correo"
              onChange={(e) => setParams({ ...params, search: e.target.value })}
              className="input"
            ></input>
          </div>
          <div className="ml-2 mt-2 flex-grow">
            <select
              name="status"
              className="input"
              as="select"
              label="Estatus"
              onChange={(e) => setParams({ ...params, status: e.target.value })}
            >
              <option value="1">Activo</option>
              <option value="2">Inactivo</option>
            </select>
          </div>
          {canCreate ? (
            <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
              <button
                type="button"
                onClick={() => toggleModal(true)}
                className="inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto"
              >
                Agregar usuario
              </button>
            </div>
          ) : null}
        </div>
        <div className="mt-8 flex flex-col">
          <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
              <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                <table className="min-w-full divide-y divide-gray-300">
                  <thead className="bg-gray-50">
                    <tr>
                      <th
                        scope="col"
                        className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                      >
                        ID
                      </th>
                      <th
                        scope="col"
                        className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900"
                      >
                        Nombre
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                      >
                        Teléfono
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                      >
                        WhatsApp
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                      >
                        Email
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                      >
                        Rol
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                      >
                        <span>Editar</span>
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                      >
                        <span>Eliminar</span>
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                      >
                        <span>Estatus</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody className="bg-white">
                    {users?.results?.map((user, personIdx) => (
                      <tr
                        key={user.email}
                        className={
                          personIdx % 2 === 0 ? undefined : "bg-gray-50"
                        }
                      >
                        <td className="whitespace-nowrap py-4 px-3 text-sm text-gray-500 sm:pl-6">
                          {user.id}
                        </td>
                        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900">
                          {user.first_name} {user.last_name}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                          {user.phone_number}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                          {user.whatsapp}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                          {user.email}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                          {user.role}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                          {["admin", "owner"].includes(profile?.role) ? (
                            <div className="has-tooltip px-3 ">
                              <button
                                onClick={() => {
                                  toggleEditModal(!showEditModal);
                                  setUserData(user);
                                }}
                                type="button"
                                data-splitbee-event="integrations-add"
                                className="text-blue-cyt"
                              >
                                <div className="relative">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="h-5 w-5"
                                    viewBox="0 0 20 20"
                                    fill="currentColor"
                                  >
                                    <path d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z" />
                                  </svg>
                                </div>
                              </button>
                            </div>
                          ) : null}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4">
                          <button
                            onClick={() => {
                              if (
                                user.role !== "owner" &&
                                user.email !== profile?.email
                              ) {
                                toggleDeleteModal(true);
                                setUserDelete(user.id);
                                setParams({ ...params, search: "" });
                              }
                            }}
                            className={
                              user.role === "owner" ||
                              user.email === profile?.email
                                ? "has-tooltip px-4 text-gray-500"
                                : "has-tooltip px-4 text-blue-cyt"
                            }
                          >
                            <div className="relative">
                              {user.role === "owner" ? (
                                <div className="tooltip -top-9 -left-20 rounded bg-gray-900 px-4 py-1 text-sm font-medium text-white">
                                  No se puede Eliminar al owner
                                </div>
                              ) : (
                                <div className="tooltip -top-9 -left-20 rounded bg-gray-900 px-4 py-1 text-sm font-medium text-white">
                                  Eliminar Usuario
                                </div>
                              )}
                              <svg
                                className="tooltip -top-3 fill-gray-900"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 18.2 9"
                              >
                                <path
                                  className="text-white"
                                  d="M9.1,9L0,0l18.2,0L9.1,9z"
                                />
                              </svg>
                              <TrashIcon className="bg-gr h-5 w-5" />
                            </div>
                          </button>
                        </td>
                        <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-left text-sm font-medium sm:pr-6">
                          <Toggle
                            onChange={(e) => {
                              if (user.status === 1) {
                                toggleUser(user.id, e ? 1 : 2)
                                  .then(() => mutateActiveUsers())
                                  .catch((err) => $log.error(err));
                              } else if (
                                plan?.plan_addons?.client_promoters <=
                                  activeUsers?.count &&
                                user.status === 2 &&
                                plan.plan_addons.client_promoters !== -1
                              ) {
                                window.alert(
                                  "Has alcanzado el limite de usuarios activos."
                                );
                                throw new Error("this user cannot change");
                              } else {
                                toggleUser(user.id, e ? 1 : 2)
                                  .then(() => mutateActiveUsers())
                                  .catch((err) => $log.error(err));
                              }
                            }}
                            status={user.status === 1}
                            role={user.role}
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal open={showModal} setOpen={toggleModal}>
        <div className="p-3">
          <AddUserForm
            callBack={() => {
              toggleModal(false);
              toggleNotification(true);
              mutateUsers();
              mutateActiveUsers();
            }}
          />
        </div>
      </Modal>
      <Modal open={showEditModal} setOpen={toggleEditModal}>
        <div className="p-3">
          <EditUserForm
            userData={userData}
            callBack={() => {
              toggleEditModal(false);
              toggleEditNotification(true);
              mutateUsers();
            }}
          />
        </div>
      </Modal>
      <Modal open={showDeleteModal} setOpen={toggleDeleteModal}>
        <div className="p-3">
          <div className="max-w-md p-3">
            <p>¿Estas seguro que quieres eliminar este usuario?</p>
            <div className="my-2 flex justify-between space-x-2 px-6 pt-3">
              <button
                className="button-dgr"
                onClick={() => {
                  toggleUser(userDelete, 3).catch((err) => $log.error(err));
                  mutateUsers().then(() => {
                    setParams({ ...params, search: null });
                    mutateActiveUsers();
                  });
                  toggleDeleteModal(false);
                }}
              >
                Eliminar
              </button>
              <button
                onClick={() => {
                  toggleDeleteModal(false);
                }}
                className="button-secondary"
              >
                Cancelar
              </button>
            </div>
          </div>
        </div>
      </Modal>
      <Notification
        open={showNotification}
        setOpen={toggleNotification}
        timeOut={5000}
        title="El usuario fue creado correctamente"
      />
      <Notification
        open={showEditNotification}
        setOpen={toggleEditNotification}
        timeOut={5000}
        title="El usuario fue editado correctamente"
      />
      <Pagination
        total={users?.count}
        type={"Usuario"}
        current={params.page}
        next={users?.next}
        previous={users?.previous}
        pageSize={10}
        onPageChange={(page) => setParams((prev) => ({ ...prev, page }))}
      />
    </>
  );
}
