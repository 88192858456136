import React, { useState } from "react";
import NumberFormat from "react-number-format";
import * as Yup from "yup";
import { Formik, Form, Field, ErrorMessage } from "formik";

const LoginSchema = Yup.object().shape({
  code: Yup.string().required("Ingresa el código de verificación"),
  password: Yup.string().required("Este campo es requerido"),
  newPassword: Yup.string().when("password", {
    is: (val) => (val && val.length > 0 ? true : false),
    then: Yup.string().oneOf(
      [Yup.ref("password")],
      "La nueva contraseña debe coincidir"
    ),
  }),
});

export default function ResetPasswordForm({ onSubmit }) {
  return (
    <Formik
      initialValues={{ password: "", newPassword: "", code: "" }}
      validationSchema={LoginSchema}
      onSubmit={(values) => onSubmit(values)}
    >
      {({ values, setFieldValue }) => (
        <Form className="space-y-3">
          <div>
            <label className="form-label" htmlFor="code">
              Código
            </label>
            <NumberFormat
              id="code"
              name="code"
              value={values.code}
              format="# # # # # #"
              onValueChange={({ value }) => {
                setFieldValue("code", value);
              }}
              type="text"
              className="input"
            />
            <ErrorMessage
              component="span"
              className="input-error"
              name="code"
            />
          </div>
          <div>
            <label className="form-label" htmlFor="password">
              Nueva contraseña
            </label>
            <Field
              id="password"
              name="password"
              value={values.password}
              onValueChange={({ value }) => {
                setFieldValue("password", value);
              }}
              type="password"
              className="input"
            />
            <ErrorMessage
              component="span"
              className="input-error"
              name="password"
            />
          </div>
          <div>
            <label className="form-label" htmlFor="newPassword">
              Confirmar nueva contraseña
            </label>
            <Field
              id="newPassword"
              name="newPassword"
              value={values.newPassword}
              onValueChange={({ value }) => {
                setFieldValue("newPassword", value);
              }}
              type="password"
              className="input"
            />
            <ErrorMessage
              component="span"
              className="input-error"
              name="newPassword"
            />
          </div>
          <div>
            <button type="submit" className="button-primary w-full">
              Reestablecer constraseña
            </button>
          </div>
        </Form>
      )}
    </Formik>
  );
}
