import React, { useMemo, useState, useEffect } from "react";
import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  TouchSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  useSortable,
  SortableContext,
  sortableKeyboardCoordinates,
  rectSortingStrategy,
  rectSwappingStrategy,
} from "@dnd-kit/sortable";

import { CSS } from "@dnd-kit/utilities";
import { Formik, Field, Form, FieldArray, ErrorMessage } from "formik";
import UploadImages from "@/components/forms/upload";
import { useProperty, updateProperty } from "@/_actions/propertiesActions";
import config from "./uppyConfigPro";
import * as Yup from "yup";

export default function UploadMediaForm({
  callBack,
  propertyId,
  goBack,
  action,
}) {
  const [removeId, setRemoveId] = useState(null);
  const { data: propertyData = {} } = useProperty(propertyId);

  async function onSubmit(values) {
    try {
      const newObject = {
        ...values,
        images: values.images.map((i) => {
          if (!i.url) {
            return { url: i };
          } else {
            return i;
          }
        }),
      };
      const response = await updateProperty(propertyId, newObject);
      callBack(response);
    } catch (err) {
      $log.error(err);
    }
  }

  const MediaSchema = Yup.object().shape({
    images: Yup.array().min(
      1,
      "Se debe agregar al menos una imagen para publicar la propiedad"
    ),
    youtube_url: Yup.string().matches(
      /^((?:https?:)?\/\/)?((?:www|m)\.)?((?:youtube\.com|youtu.be))(\/(?:[\w\-]+\?v=|embed\/|v\/)?)([\w\-]+)(\S+)?$/,
      "Ingresa una url de youtube válida"
    ),
    virtual_tour_url: Yup.string().url("Ingresa una url válida"),
  });

  return (
    <Formik
      onSubmit={onSubmit}
      enableReinitialize={true}
      initialValues={{
        images: propertyData?.images || [],
        youtube_url: propertyData?.youtube_url || "",
        virtual_tour_url: propertyData?.virtual_tour_url || "",
      }}
      validationSchema={MediaSchema}
    >
      {({ values, isSubmitting }) => (
        <Form data-splitbee-event={`properties-${action}-upload`}>
          <div>
            <label htmlFor="description" className="form-title">
              Fotos
            </label>
            <FieldArray name="images">
              {({ move, push, remove }) => (
                <div className="space-y-3 p-3">
                  <ImageWithFieldArray
                    removeId={removeId}
                    push={push}
                    propertyId={propertyId}
                  />
                  {values?.images?.length > 0 && (
                    <p className="form-title">
                      Arrastra y define el orden de las imágenes
                    </p>
                  )}
                  <SortableArea
                    axis="xy"
                    items={values.images}
                    deleteImage={(index, id) => {
                      remove(index);
                      if (id) {
                        setRemoveId(id);
                      }
                    }}
                    onSortEnd={move}
                    pressDelay={10}
                  />
                </div>
              )}
            </FieldArray>
            <ErrorMessage
              render={(msg) => (
                <div className="text-sm text-red-600">{msg}</div>
              )}
              name="images"
            />
          </div>
          <div>
            <label htmlFor="youtube_url" className="form-label">
              Video en youtube
            </label>
            <Field
              as="input"
              type="text"
              name="youtube_url"
              className="input"
            ></Field>
            <ErrorMessage
              name="youtube_url"
              render={(msg) => (
                <div className="text-sm text-red-600">{msg}</div>
              )}
            />
          </div>
          <div>
            <label htmlFor="virtual_tour_url" className="form-label">
              URL Recorrido virtual
            </label>
            <Field
              as="input"
              type="text"
              name="virtual_tour_url"
              className="input"
            ></Field>
            <ErrorMessage
              name="virtual_tour_url"
              render={(msg) => (
                <div className="text-sm text-red-600">{msg}</div>
              )}
            />
          </div>
          <div className="mt-5 grid grid-cols-2 gap-3 rounded bg-gray-50 p-3">
            <div>
              <button
                type="button"
                onClick={goBack}
                data-splitbee-event={`properties-${action}-upload-back`}
                className="group relative flex w-full cursor-pointer justify-center rounded-md border border-transparent bg-gray-200 py-2 px-4 text-lg  font-medium hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-gray-300 focus:ring-offset-2"
              >
                Regresar
              </button>
            </div>
            <div className="text-right">
              <button
                disabled={isSubmitting}
                type="submit"
                className="group relative flex w-full justify-center rounded-md border border-transparent bg-blue-800 py-2 px-4 text-lg font-medium text-white hover:bg-blue-900 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
              >
                Finalizar
              </button>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
}

const SortableArea = ({ items, onSortEnd, deleteImage }) => {
  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 50,
      },
    }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
      activationConstraint: {
        delay: 50,
        distance: 50,
      },
    }),
    useSensor(TouchSensor, {
      activationConstraint: {
        delay: 50,
        distance: 50,
      },
    })
  );

  function handleDragEnd(event) {
    const { active, over } = event;

    if (active?.id && over?.id && active?.id !== over?.id) {
      const oldIndex = items.findIndex((e) => e?.id === active.id);
      const newIndex = items.findIndex((e) => e?.id === over.id);

      onSortEnd(oldIndex, newIndex);
    }
  }

  return (
    <div className="grid grid-cols-2 gap-4 lg:grid-cols-4 xl:grid-cols-7">
      <DndContext
        sensors={sensors}
        collisionDetection={closestCenter}
        onDragEnd={handleDragEnd}
      >
        <SortableContext items={items} strategy={rectSwappingStrategy}>
          {items.map((item, index) => (
            <div key={item.id}>
              <SortableItem
                id={item.id}
                value={item}
                index={index}
                deleteImage={deleteImage}
              />
            </div>
          ))}
        </SortableContext>
      </DndContext>
    </div>
  );
};

const DeleteButton = ({ id, sortIndex, deleteImage }) => {
  return (
    <button
      type="button"
      onClick={() => {
        deleteImage(sortIndex, id);
      }}
      className=" w-full items-center rounded-b-md border px-4 py-2 text-center text-sm font-medium text-gray-700  shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2"
    >
      <div className="pointer-events-none">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="m-auto -ml-1 h-5 w-5 text-gray-400"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
          />
        </svg>
      </div>
    </button>
  );
};

const SortableItem = ({ id, value, index, deleteImage }) => {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id: id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <div
      ref={setNodeRef}
      style={style}
      {...attributes}
      {...listeners}
      className="relative w-full cursor-move touch-pinch-zoom select-none list-none bg-transparent focus:shadow-xl "
    >
      <div className="group w-full  overflow-hidden rounded-lg bg-transparent focus-within:ring-2  md:block">
        <div className="aspect-square bg-transparent ">
          <img
            src={value.url || value}
            className="pointer-events-none h-full w-full object-cover group-hover:opacity-75"
            onMouseDown={(e) => {
              e.preventDefault();
            }}
          />
        </div>
        <DeleteButton
          id={id}
          sortIndex={index}
          deleteImage={deleteImage}
        ></DeleteButton>
      </div>
    </div>
  );
};

const ImageWithFieldArray = ({ push, removeId, propertyId }) => {
  const uppyConfig = useMemo(() => {
    return config({ callBack: push, propertyId: propertyId });
  }, [propertyId, push]);

  return (
    <UploadImages
      removeId={removeId}
      uppyConfiguration={uppyConfig}
      height={200}
      showSelectedFiles={false}
    />
  );
};

ImageWithFieldArray.defaultProps = {
  removeId: null,
};
