import Uppy from "@uppy/core";
import AwsS3 from "@uppy/aws-s3";
import Spanish from "@uppy/locales/lib/es_ES";
import ms from "ms";
import { getPresignedUrl } from "@/_actions/awsActions";
import * as Sentry from "@sentry/react";

const uppyConfig = ({ callBack, propertyId }) => {
  const uppyInstance = new Uppy({
    meta: { type: "avatar" },
    locale: Spanish,
    restrictions: {
      maxNumberOfFiles: 50,
      allowedFileTypes: [".jpg", ".jpeg", ".png", ".JPG", ".JPEG", ".PNG"],
    },
    autoProceed: true,
  });

  uppyInstance.use(AwsS3, {
    limit: 2,
    timeout: ms("1 minute"),
    getUploadParameters: (file) =>
      getPresignedUrl({
        property: propertyId,
        urls: [
          {
            format: file.extension,
          },
        ],
      }),
  });

  uppyInstance.on("complete", ({ successful }) => {
    try {
      if (callBack) {
        successful.forEach((response) => {
          callBack({
            url: response.xhrUpload.endpoint + response.meta.key,
            id: response.id,
          });
        });
      }
    } catch (err) {
      Sentry.captureException(err);
    }
  });

  uppyInstance.remove = (fileID) => {
    uppyInstance.removeFile(fileID);
  };

  return uppyInstance;
};

export default uppyConfig;
