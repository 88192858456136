import React from "react";
import Select from "@/components/forms/Select.jsx";
import Checkbox from "@/components/forms/Checkbox";
import { RadioGroup } from "@headlessui/react";
import { XIcon, CheckIcon } from "@heroicons/react/outline";
import {
  useStates,
  useMunicipalities,
  useNeighborhoods,
} from "@/_actions/regionActions";
import {
  propertiesParamsAtom,
  propertiesParamsInventoryAtom,
} from "@/_actions/propertiesActions";

import { useAtom } from "jotai";

function clearFilters(setParams, type) {
  setParams(
    type === "inventory"
      ? {
          page: 1,
          full: true,
          status: "published",
          ordering: "-created",
        }
      : { page: 1, status: "published", ordering: "-created" }
  );
}

export default function Mobile({
  propertyTypes,
  operationTypes,
  origins,
  status,
  callBack,
  action,
  order,
  type,
}) {
  const [params, setParams] = useAtom(
    type === "inventory" ? propertiesParamsInventoryAtom : propertiesParamsAtom
  );

  return (
    <div className="h-screen max-h-screen-90 w-full overflow-auto">
      <section className="sticky top-0 z-10 flex w-full items-center justify-between bg-gray-200 py-5 px-6">
        <h1 className="text-xl font-semibold leading-7">
          {action === "filter" ? "Filtros" : "Ordenar por"}
        </h1>
        <div>
          {action === "filter" ? (
            <>
              <button
                onClick={() => clearFilters(setParams, type)}
                className="button-secondary mr-2 p-2"
              >
                Limpiar filtros
              </button>
              <button onClick={() => callBack()} className="button-primary p-2">
                Aplicar filtros
              </button>
            </>
          ) : (
            <XIcon
              className="h-5 w-5 rounded-md bg-white"
              onClick={() => callBack()}
            />
          )}
        </div>
      </section>
      {action === "filter" ? (
        <>
          <h3 className="py-2.5 px-6 text-lg font-normal leading-7">
            Tipo de propiedad
          </h3>
          <div className="bg-gray-50 py-4 px-6">
            <div
              role="group"
              aria-labelledby="checkbox-group"
              className="grid w-full grid-cols-2 gap-3"
            >
              {propertyTypes.map((pt, i) => (
                <div className="relative flex items-start" key={i}>
                  <div className="flex h-5 items-center">
                    <input
                      type="checkbox"
                      checked={params?.property_type?.includes(pt.id) || false}
                      onChange={(e) => {
                        if (e.target.checked) {
                          const property_type_array =
                            params?.property_type?.length > 0
                              ? [...params.property_type, pt.id]
                              : [pt.id];
                          setParams({
                            ...params,
                            property_type: property_type_array,
                          });
                        } else {
                          setParams({
                            ...params,
                            property_type: params?.property_type.filter(
                              (p) => p !== pt.id
                            ),
                          });
                        }
                      }}
                      id={pt.id}
                      className="text-primary-defult h-4 w-4 rounded border-gray-300"
                    />
                  </div>
                  <div className="ml-3 text-sm leading-5">
                    <label
                      htmlFor={pt.id}
                      className="font-medium text-gray-700"
                    >
                      {pt.name}
                    </label>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <h3 className="py-2.5 px-6 text-lg font-normal leading-7">
            Tipo de operación
          </h3>
          <div className="bg-gray-50 py-4 px-6">
            <div
              role="group"
              aria-labelledby="checkbox-group"
              className="grid w-full grid-cols-2 gap-3"
            >
              {operationTypes.map((ot, i) => (
                <div className="relative flex items-start" key={i}>
                  <div className="flex h-5 items-center">
                    <input
                      type="checkbox"
                      checked={params?.operation_type?.includes(ot.id) || false}
                      onChange={(e) => {
                        if (e.target.checked) {
                          const operation_type_array =
                            params?.operation_type?.length > 0
                              ? [...params.operation_type, ot.id]
                              : [ot.id];
                          setParams({
                            ...params,
                            operation_type: operation_type_array,
                          });
                        } else {
                          setParams({
                            ...params,
                            operation_type: params?.operation_type.filter(
                              (o) => o !== ot.id
                            ),
                          });
                        }
                      }}
                      id={ot.id}
                      className="text-primary-defult h-4 w-4 rounded border-gray-300"
                    />
                  </div>
                  <div className="ml-3 text-sm leading-5">
                    <label
                      htmlFor={ot.id}
                      className="font-medium text-gray-700"
                    >
                      {ot.name}
                    </label>
                  </div>
                </div>
              ))}
            </div>
          </div>
          {type === "inventory" ? null : (
            <>
              <h3 className="py-2.5 px-6 text-lg font-normal leading-7">
                Estatus
              </h3>
              <div className="flex w-full">
                <div className="w-full bg-gray-50 py-4 px-6">
                  <Select
                    id="status"
                    name="status"
                    className="input"
                    options={status}
                    onChange={(e) => {
                      setParams({ ...params, status: e.target.value });
                    }}
                    value={params.status}
                    label="Estatus"
                  />
                </div>
                <div className="flex w-full items-center bg-gray-50 py-4 px-6 align-middle">
                  <label className="">
                    <input
                      className="mr-2"
                      type="checkbox"
                      name="featured"
                      checked={params?.featured || false}
                      onChange={(e) => {
                        setParams({ ...params, featured: e.target.checked });
                      }}
                    />
                    Destacados
                  </label>
                </div>
              </div>
            </>
          )}

          <h3 className="py-2.5 px-6 text-lg font-normal leading-7">
            Rango de precio
          </h3>
          <div className="bg-gray-50 py-4 px-6">
            <div className="flex w-full items-center space-x-2">
              <label className="relative block">
                <span className="absolute inset-y-0 left-0 flex items-center pl-2">
                  $
                </span>
                <input
                  id="price_min"
                  className="input pl-6"
                  name="price_min"
                  placeholder="Minimo"
                  type="number"
                  onChange={(e) => {
                    setParams({ ...params, price_min: e.target.value });
                  }}
                />
              </label>
              <div>-</div>
              <label className="relative block">
                <span className="absolute inset-y-0 left-0 flex items-center pl-2">
                  $
                </span>
                <input
                  id="price_max"
                  className="input pl-6"
                  placeholder="Maximo"
                  name="price_max"
                  type="number"
                  onChange={(e) => {
                    setParams({ ...params, price_max: e.target.value });
                  }}
                />
              </label>
            </div>
          </div>
          <h3 className="py-2.5 px-6 text-lg font-normal leading-7">Estado</h3>
          <div className="bg-gray-50 py-4 px-6">
            <LocationSelect
              id="state"
              name="state"
              className="input"
              useOptions={useStates}
              label="Estado"
              onChange={(e) => {
                setParams({ ...params, state: e.target.value });
              }}
              value={params.state ? params.state : ""}
            />
          </div>
          <>
            <h3 className="py-2.5 px-6 text-lg font-normal leading-7">
              Municipio
            </h3>
            <div className="bg-gray-50 py-4 px-6">
              <LocationSelect
                id="municipality"
                name="municipality"
                className="input"
                selected={params?.state ? true : false}
                useOptions={() =>
                  useMunicipalities({ state_id: params?.state })
                }
                optionValue={params?.state}
                label="Municipio"
                onChange={(e) => {
                  setParams({ ...params, municipality: e.target.value });
                }}
                value={params.municipality ? params.municipality : ""}
              />
            </div>
          </>
          <>
            <h3 className="py-2.5 px-6 text-lg font-normal leading-7">
              Colonia
            </h3>
            <div className="bg-gray-50 py-4 px-6">
              <LocationSelect
                id="colony"
                name="colony"
                className="input"
                selected={params?.municipality ? true : false}
                useOptions={() =>
                  useNeighborhoods({ municipality: params?.municipality })
                }
                optionValue={params?.state}
                label="Colonia"
                onChange={(e) => {
                  setParams({ ...params, colony: e.target.value });
                }}
                value={params.colony ? params.colony : ""}
              />
            </div>
          </>
          <h3 className="py-2.5 px-6 text-lg font-normal leading-7">Origen</h3>
          <div className="bg-gray-50 py-4 px-6 pb-12">
            <div
              role="group"
              aria-labelledby="checkbox-group"
              className="grid w-full grid-cols-2 gap-3"
            >
              {origins.map((pt, i) => (
                <div className="relative flex items-start" key={i}>
                  <div className="flex h-5 items-center">
                    <input
                      type="checkbox"
                      checked={params?.origin?.includes(pt.id) || false}
                      onChange={(e) => {
                        if (e.target.checked) {
                          const origin_array =
                            params?.origin?.length > 0
                              ? [...params.origin, pt.id]
                              : [pt.id];
                          setParams({
                            ...params,
                            origin: origin_array,
                          });
                        } else {
                          setParams({
                            ...params,
                            origin: params?.origin.filter((p) => p !== pt.id),
                          });
                        }
                      }}
                      id={pt.id}
                      className="text-primary-defult h-4 w-4 rounded border-gray-300"
                    />
                  </div>
                  <div className="ml-3 text-sm leading-5">
                    <label
                      htmlFor={pt.id}
                      className="font-medium text-gray-700"
                    >
                      {pt.name}
                    </label>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </>
      ) : (
        <div className="py-5 px-6">
          <div className="space-y-8">
            {order.map((option) => (
              <button
                onClick={() => {
                  setParams({ ...params, ordering: option.id });
                  callBack();
                }}
                className="button-cta w-full"
              >
                {option.name}
              </button>
            ))}
          </div>
        </div>
      )}
    </div>
  );
}

const LocationSelect = ({
  selected,
  useOptions,
  optionValue = "",
  ...props
}) => {
  const { data = [] } = useOptions(optionValue);
  return <Select {...props} options={data} selected={selected} />;
};
