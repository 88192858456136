import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

export default function CustomButton({
  active,
  text,
  activeClasses,
  inactiveClasses,
  buttonActiveClasses,
  buttonInactiveClasses,
  ...rest
}) {
  const baseClasses =
    "flex w-full items-center justify-center rounded-full border-2 text-sm text-white lg:text-base";
  const buttonBaseClasses =
    "flex h-full w-full items-center justify-center rounded-full border-2 py-2";
  return (
    <div
      className={classNames(
        baseClasses,
        active ? activeClasses : inactiveClasses
      )}
    >
      <button
        {...rest}
        disabled={!active}
        className={classNames(
          buttonBaseClasses,
          active ? buttonActiveClasses : buttonInactiveClasses
        )}
      >
        <span className="mx-4">{text}</span>
      </button>
    </div>
  );
}

CustomButton.propTypes = {
  active: PropTypes.bool,
  text: PropTypes.string,
  activeClasses: PropTypes.string.isRequired,
  inactiveClasses: PropTypes.string.isRequired,
  buttonActiveClasses: PropTypes.string.isRequired,
  buttonInactiveClasses: PropTypes.string.isRequired,
};
