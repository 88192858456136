export default [
  {
    id: "601",
    name: "General de Ley Personas Morales",
  },
  {
    id: "603",
    name: "Personas Morales con Fines no Lucrativos",
  },
  {
    id: "605",
    name: "Sueldos y Salarios e Ingresos Asimilados a Salarios",
  },
  {
    id: "606",
    name: "Arrendamiento",
  },
  {
    id: "608",
    name: "Demás ingresos",
  },
  {
    id: "609",
    name: "Consolidación",
  },
  {
    id: "610",
    name: "Residentes en el Extranjero sin Establecimiento Permanente en México",
  },
  {
    id: "611",
    name: "Ingresos por Dividendos (socios y accionistas)",
  },
  {
    id: "612",
    name: "Personas Físicas con Actividades Empresariales y Profesionales",
  },
  {
    id: "614",
    name: "Ingresos por intereses",
  },
  {
    id: "616",
    name: "Sin obligaciones fiscales",
  },
  {
    id: "620",
    name: "Sociedades Cooperativas de Producción que optan por diferir sus ingresos",
  },
  {
    id: "621",
    name: "Incorporación Fiscal",
  },
  {
    id: "622",
    name: "Actividades Agrícolas, Ganaderas, Silvícolas y Pesqueras",
  },
  {
    id: "623",
    name: "Opcional para Grupos de Sociedades",
  },
  {
    id: "624",
    name: "Coordinados",
  },
  {
    id: "628",
    name: "Hidrocarburos",
  },
  {
    id: "607",
    name: "Régimen de Enajenación o Adquisición de Bienes",
  },
  {
    id: "629",
    name: "De los Regímenes Fiscales Preferentes y de las Empresas Multinacionales",
  },
  {
    id: "630",
    name: "Enajenación de acciones en bolsa de valores",
  },
  {
    id: "615",
    name: "Régimen de los ingresos por obtención de premios",
  },
  {
    id: "625",
    name: "Régimen de las Actividades Empresariales con ingresos a través de Plataformas Tecnológicas",
  },
  {
    id: "626",
    name: "Régimen Simplificado de Confianza",
  },
];
