import axios from "axios";

const axiosInstance = axios.create({
  baseURL: import.meta.env.VITE_API_USER_URL,
});

axiosInstance.interceptors.request.use(
  async (config) => {
    if (typeof window !== "undefined") {
      const account = JSON.parse(localStorage.getItem("account"));
      if (account?.user?.token)
        config.headers.Authorization = `Bearer ${account?.user?.token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default axiosInstance;
