import React, { useState, useEffect } from "react";
import LogoCYT from "./img/logo-cyt-pro-white.png";
import getAcquisitionToken from "@/utils/getAcquisitionToken";
import useAcquisitionUser from "@/hooks/useAcquisitionUser";

const WEBSITE_URL = import.meta.env.VITE_PORTAL_URL;

export default function Index() {
  const [name, setName] = useState("Alejandra");
  const [url, setUrl] = useState("");
  const token = getAcquisitionToken();
  const { data: user, error } = useAcquisitionUser(token?.uuid);

  useEffect(() => {
    if (user?.account_type === "direct") {
      setUrl(localStorage.getItem("cytProperyUrl"));
    }
  }, [user]);

  return (
    <div className="relative">
      <div className="space-y-1 bg-blue-800 p-3 pb-10 text-white md:hidden">
        <img
          src={LogoCYT}
          alt="Logo Casas y Terrenos"
          className="m-auto mb-10 max-w-xs"
        />
        <p className="text-4xl font-extrabold uppercase leading-10">
          Te damos la bienvenida,{" "}
          <span className="capitalize">
            {user?.name && `${user.name} ${user.last_name}`}
          </span>
        </p>
      </div>
      <div className="grid-cols-3 md:grid  ">
        <div className="col-span-1 hidden min-h-screen bg-blue-800 pt-16 text-white md:block  ">
          <div className="m-auto block w-3/4 space-y-3">
            <img
              src={LogoCYT}
              alt="Logo Casas y Terrenos"
              className="mb-10  max-w-xs"
            />
            <p className="text-5xl  font-extrabold leading-none ">
              Te damos la bienvenida,{" "}
              <span className="capitalize">
                {user?.name && `${user.name} ${user.last_name}`}
              </span>
            </p>
          </div>
        </div>
        <div className="col-span-2 block ">
          <div className=" m-auto w-full px-5 pt-16 md:min-h-screen md:w-3/4 lg:w-2/3 ">
            {user?.account_type === "direct" ? (
              <WelcomeIndividual url={WEBSITE_URL + url} />
            ) : (
              <WelcomePro />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

const WelcomeIndividual = ({ url }) => (
  <div className="space-y-5">
    <p className="text-xl leading-7">¡Tu propiedad ha sido publicada!</p>

    <div>
      <p className="mb-2">Puedes verla aquí</p>
      <a href={url || ""}>
        <button className="button-primary">Ver publicación</button>
      </a>
    </div>
    <div>
      <p className="mb-2">O ver tus contactos aquí</p>
      <a href="/login">
        <button className="button-primary">Ver contactos</button>
      </a>
    </div>
  </div>
);
const WelcomePro = () => (
  <div className="space-y-5">
    <p className="text-xl leading-7">¡Ahora eres PRO!</p>

    <div>
      <p className="mb-2">Empieza a publicar</p>
      <a href="/login">
        <button className="button-primary">Ingresa a tu cuenta</button>
      </a>
    </div>
  </div>
);
