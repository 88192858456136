import axios from "axios";
import { Auth } from "aws-amplify";

const axiosInstance = axios.create({
  baseURL: import.meta.env.VITE_API_URL,
});

axiosInstance.CancelToken = axios.CancelToken;
axiosInstance.isCancel = axios.isCancel;

axiosInstance.interceptors.request.use(async (config) => {
  try {
    const session = await Auth.currentSession();
    config.headers.Authorization = `Bearer ${session.idToken.jwtToken}`;
    return config;
  } catch (err) {
    Auth.signOut();
    $log.error(err);
    return Promise.reject(err);
  }
});

export default axiosInstance;
