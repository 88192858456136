import React, { useState } from "react";
import { ArrowLeftIcon, CheckIcon, XIcon } from "@heroicons/react/solid";
import { ShieldExclamationIcon } from "@heroicons/react/outline";
import DottedLine from "../../signup/img/div.svg";
import RoundButton from "@/components/forms/RoundButton";
import NumberInputs from "./number_inputs";
import acquisitionClient from "../../../services/acquisitionApiClient";
import { useNavigate } from "react-router-dom";
import Modal from "@/components/overlays/Modal";
import LogoPro from "/src/pages/login/img/logo-blue.svg";

export default function VerifySubmit() {
  const navigate = useNavigate();
  const account = JSON.parse(localStorage.getItem("account"));
  const [code, setCode] = useState("");
  const [showError, setShowError] = useState(false);
  function handleCodeChange(newCode) {
    setCode(newCode);
  }
  const goBack = () => {
    window.history.back();
  };
  const submit = async () => {
    try {
      const response = await acquisitionClient.post(
        "/customers/verify-account/",
        { code: code, user_id: account?.user?.user_id }
      );
      if (response.status === 200) {
        navigate("/confirmation");
      }
    } catch (err) {
      if (err.response.status === 401) {
        setShowError(true);
      }
    }
  };

  return (
    <div className="flex w-full items-center justify-center">
      <div className="flex w-full max-w-[1440px] flex-col items-center justify-center">
        <div className="flex w-full p-6">
          <img className="w-[180px]" src={LogoPro} />
        </div>
        <a
          id="Publica-VerificarCuenta-Volver"
          className="flex w-full items-center p-6"
          href="#"
          onClick={goBack}
        >
          <ArrowLeftIcon className="mr-2 w-4 md:h-5 md:w-5" />
          <span className="text-lg">Volver</span>
        </a>
        <div>
          <h1 className="mb-6 text-center text-2xl md:text-4xl lg:text-5xl">
            Comienza a recibir clientes
          </h1>
          <div className="flex space-x-4 ">
            <div className="flex items-center">
              <CheckIcon className="mr-1.5 w-4 rounded-full bg-yellow-cyt-alt p-0.5 text-white md:h-5 md:w-5" />
              <h2 className="text-[10px] md:text-sm lg:text-xl">
                Elige tu paquete
              </h2>
            </div>
            <img className=" hidden w-[50px] lg:flex" src={DottedLine}></img>
            <div className="flex items-center">
              <CheckIcon className="mr-1.5 w-4 rounded-full bg-yellow-cyt-alt p-0.5 text-white md:h-5 md:w-5" />
              <h2 className="text-[10px] md:text-sm lg:text-xl">
                Crea tu cuenta
              </h2>
            </div>
            <img className="hidden w-[50px] lg:flex" src={DottedLine}></img>
            <div className="flex items-center">
              <span className="mr-1.5 flex h-5 w-5 items-center justify-center rounded-full bg-primary-default text-xs text-white md:h-6 md:w-6 md:text-base">
                3
              </span>
              <h2 className="text-xs font-bold text-primary-default	md:text-lg lg:text-2xl">
                Verifica tu cuenta
              </h2>
            </div>
            <img className="hidden w-[50px] lg:flex" src={DottedLine}></img>
            <div className="flex items-center">
              <span className="mr-1.5 flex h-4 w-4 items-center justify-center rounded-full bg-[#A5A5A5] text-[10px] text-white md:h-5 md:w-5 md:text-base">
                4
              </span>
              <h2 className="text-[10px] text-[#A5A5A5] md:text-sm lg:text-xl">
                Finaliza con tu pago
              </h2>
            </div>
          </div>
          <div className="my-6 flex w-full flex-col items-center justify-center space-y-6 text-2xl">
            <h3>
              Enviamos el código a{" "}
              <span className="font-bold">{account?.values?.email}</span>
            </h3>
            <div className="flex w-full items-center justify-center space-x-3">
              <NumberInputs onCodeChange={handleCodeChange} />
            </div>

            <div className="w-full max-w-[260px]" onClick={() => submit()}>
              <RoundButton
                id="Publica-VerificarCuenta"
                active={code?.length === 5 ? true : false}
                text="Verificar cuenta"
              />
            </div>
          </div>
        </div>
        <Modal open={showError} setOpen={setShowError}>
          <XIcon
            className="ml-3 mt-3 w-6 cursor-pointer rounded-full p-0.5 hover:bg-gray-300"
            onClick={() => setShowError(false)}
          />
          <div className="text- flex w-full flex-col items-center justify-center p-6 pt-0">
            <ShieldExclamationIcon className="w-14 text-amber-600 " />
            <span className="mb-4 text-lg font-bold">Código incorrecto</span>
            <div
              className="w-full px-4"
              onClick={() => {
                setShowError(false);
                window.location.reload();
              }}
            >
              <RoundButton active={true} text="Intentar de nuevo" />
            </div>
          </div>
        </Modal>
      </div>
    </div>
  );
}
