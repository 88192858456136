import React, { useState, useEffect } from "react";

const SelectBox = (props) => {
  const { option, callBack, fieldValue } = props;
  const [checked, setChecked] = useState(null);

  useEffect(() => {
    if (fieldValue && fieldValue.includes(option.value)) {
      setChecked(true);
    }
  }, [fieldValue]);

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  function toggle() {
    let x = !checked;
    setChecked(!checked);
    callBack(x, option.value);
  }

  return (
    <div
      value={option.value}
      onClick={toggle}
      className="relative block  w-full cursor-pointer  rounded-lg border border-gray-300 bg-white px-6 py-4 shadow-sm hover:border-gray-400 focus:outline-none sm:flex sm:justify-between md:h-20"
    >
      <>
        <div className="flex flex-1 items-center">
          <div className="flex-1  text-sm">
            <label
              as="p"
              className="text-center text-base font-medium leading-6 text-gray-900"
            >
              {option.name}
            </label>
          </div>
        </div>

        <div
          className={classNames(
            checked ? "border-yellow-500" : "border-transparent",
            "pointer-events-none absolute -inset-px rounded-lg border-2"
          )}
          aria-hidden="true"
        />
      </>
    </div>
  );
};

SelectBox.defaultProps = {
  fieldValue: [],
};

export default SelectBox;
