import apiClient from "../utils/apiClient";
/** @function
 * @name getReports
 * @description - Obtains the reports according to the dates that are sent to it.
 * @param {Object} params - Values for the report date
 * @returns {object} - data reports according to the selected dates
 */
export const getReports = async (params) => {
  if (params.operation_type) {
    params.operation_type = params.operation_type.toString();
  } else if (params.status_property) {
    params.status_property = params.status_property.toString();
  }
  try {
    const response = await apiClient.get(`/reports/${params.typeReport}`, {
      params,
    });
    return response;
  } catch (err) {
    $log.error(err);
  }
};
