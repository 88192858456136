import React, { useRef, useEffect } from "react";
import Trix from "trix";
import "trix/dist/trix.css";
import PropTypes from "prop-types";

addEventListener("trix-before-initialize", () => {
  Trix.config.blockAttributes.heading1 = {
    tagName: "h3",
    terminal: true,
    breakOnReturn: true,
  };
});

export default function TextEditor({ value, setFieldValue }) {
  const trixInput = useRef(null);
  useEffect(() => {
    const unusedClasses = [
      ".trix-button--icon-increase-nesting-level",
      ".trix-button--icon-decrease-nesting-level",
      ".trix-button--icon-quote",
      ".trix-button--icon-code",
      ".trix-button--icon-attach",
      ".trix-button--icon-link",
      ".trix-button-group--file-tools",
    ];
    const handleInitialize = () => {
      unusedClasses.forEach((cls) => {
        const element = document.querySelector(cls);
        if (element) element.remove();
      });
    };
    if (trixInput.current) {
      const handleBlur = () => {
        setFieldValue("description", trixInput.current.innerHTML);
      };

      trixInput.current.addEventListener("trix-initialize", handleInitialize);
      trixInput.current.addEventListener("blur", handleBlur);

      return () => {
        if (trixInput.current) {
          trixInput.current.removeEventListener(
            "trix-initialize",
            handleInitialize
          );
          trixInput.current.removeEventListener("blur", handleBlur);
        }
      };
    }
  }, [setFieldValue]);

  useEffect(() => {
    if (trixInput?.current?.editor) {
      trixInput?.current?.editor.loadHTML(value || "");
    }
  }, [value]);

  return (
    <div>
      <input type="hidden" id="trix" value={value} name="description" />
      <trix-editor
        input="trix"
        ref={trixInput}
        class="trix-editor"
        data-testid="trix-editor"
        placeholder="Texto sobre ubicación, amenidades, cosas que hay alrededor, etc."
      ></trix-editor>
    </div>
  );
}

TextEditor.propTypes = {
  value: PropTypes.string,
  setFieldValue: PropTypes.func.isRequired,
};
