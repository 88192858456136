import React from "react";
import ProListingsAnimation from "@/video/PRO-Listing-Animation.webm";

export default function Hero() {
  return (
    <div className="mb-16 flex w-full flex-col justify-between md:mt-8 lg:mt-10 lg:flex-row">
      <div className="lg:max-w-[419px]">
        <h2 className="font-bold md:text-3xl lg:text-4xl">
          Con PRO Listings <br className="lg:hidden" /> las{" "}
          <span className="text-blue-cyt">Pre-Ventas exclusivas </span>llegan a
          ti
        </h2>
        <div className="mt-7 md:text-lg lg:text-xl ">
          <span className="text-yellow-cyt">Gana comisiones especiales </span>
          con tu nuevo beneficio de Casas y Terrenos PRO.
        </div>
      </div>
      <div className="flex h-fit justify-end pt-8 md:w-full lg:w-[500px] lg:pt-0">
        <video
          autoPlay
          muted
          loop
          id="myVideo"
          className="top-0 h-full w-full max-w-[500px] object-cover lg:min-w-[500px]"
        >
          <source src={ProListingsAnimation} type="video/webm" />
        </video>
      </div>
    </div>
  );
}
