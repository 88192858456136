import React, { useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import Dropdown from "@/components/elements/Dropdown";
import Checkbox from "@/components/forms/Checkbox";
import Notification from "@/components/overlays/Notification";

import "react-datepicker/dist/react-datepicker.css";

import { getReports } from "@/_actions/reportActions";

const PropertiesSchema = Yup.object().shape({
  status_property: Yup.array()
    .min(1, "Debes seleccionar al menos un estatus.")
    .required("Debes seleccionar un estatus de la propiedad."),
  operation_type: Yup.array()
    .min(1, "Debes seleccionar al menos un tipo de operación.")
    .required("Debes seleccionar un tipo de operación."),
});

function PropertiesReport({ callBack }) {
  const statusProperty = [
    { value: "published", status: "Publicado" },
    { value: "pending", status: "Pendiente" },
    { value: "inactive", status: "Inactivo" },
  ];
  const operationType = [
    { value: "1", operation: "Venta" },
    { value: "2", operation: "Renta" },
    { value: "3", operation: "Traspaso" },
  ];
  const [loading, setLoading] = useState(false);
  const [showNotification, toggleNotification] = useState(false);
  const [textNotification, setTextNotification] = useState("");
  const [timeNotification, setTimeNotification] = useState(0);
  const handleToggleNotification = (text) => {
    setTextNotification(text);
    toggleNotification(true);
  };
  const submit = async (values, { resetForm }) => {
    const statusProperty = values.status_property.toString();
    setLoading(true);
    const v = { ...values };
    v.typeReport = "properties_report";
    const response = await getReports(v);
    callBack && callBack(v);
    const url = URL.createObjectURL(new Blob([response.data]));
    const fakeLink = document.createElement("a");
    fakeLink.href = url;
    var date = new Date().toLocaleDateString("en-GB");
    let status = "";
    if (statusProperty.includes("published")) {
      status = "Publicadas_";
    }
    if (statusProperty.includes("pending")) {
      status = status + "Pendientes_";
    }
    if (statusProperty.includes("inactive")) {
      status = status + "Inactivas_";
    }
    status = status.slice(0, -1);
    fakeLink.setAttribute(
      "download",
      `Reporte_de_propiedades_${status}_${date}.csv`
    );
    document.body.appendChild(fakeLink);
    fakeLink.click();
    resetForm();
    setLoading(false);
    toggleNotification(false);
    setTimeNotification(5000);
    handleToggleNotification("Archivo descargado con éxito.");
  };

  return (
    <div>
      <Formik
        initialValues={{
          status_property: ["published"],
          operation_type: ["1"],
        }}
        validationSchema={PropertiesSchema}
        onSubmit={submit}
      >
        {({ values, setFieldValue }) => (
          <Form className=" max-w-fit">
            <div className="my-8 md:flex md:flex-wrap md:gap-3">
              <div className="mr-4">
                <label className="form-label" htmlFor="status">
                  Estatus de propiedad
                </label>
                <Dropdown
                  name="status_property"
                  label={`Estatus ${
                    values.status_property.length
                      ? `(${values.status_property.length})`
                      : ""
                  }`}
                >
                  <div
                    role="group"
                    aria-labelledby="checkbox-group"
                    className="w-full space-y-3"
                  >
                    {statusProperty.map((ep) => (
                      <Field
                        component={Checkbox}
                        name="status_property"
                        value={ep.value}
                        label={ep.status}
                        key={ep.value}
                        id={ep.value}
                        checked={
                          values.status_property.includes(ep.value) || false
                        }
                      />
                    ))}
                  </div>
                </Dropdown>
                <ErrorMessage
                  component="span"
                  className="input-error"
                  name="status_property"
                />
              </div>
              <div>
                <label className="form-label" htmlFor="status">
                  Tipo de operación
                </label>
                <Dropdown
                  name="operation_type"
                  data-testid="operation_type"
                  label={`Operación ${
                    values.operation_type.length
                      ? `(${values.operation_type.length})`
                      : ""
                  }`}
                >
                  <div
                    role="group"
                    aria-labelledby="checkbox-group"
                    className="w-full space-y-3"
                  >
                    {operationType.map((ep) => (
                      <Field
                        component={Checkbox}
                        name="operation_type"
                        value={ep.value}
                        label={ep.operation}
                        key={ep.value}
                        id={ep.value}
                        checked={
                          values.operation_type.includes(ep.value) || false
                        }
                      />
                    ))}
                  </div>
                </Dropdown>
                <ErrorMessage
                  component="span"
                  className="input-error"
                  name="operation_type"
                />
              </div>
            </div>
            <div className="flex justify-end">
              <div>
                <div className="flex justify-end">
                  <button
                    type="submit"
                    className={`button-primary ${
                      loading ? "cursor-not-allowed" : "cursor-pointer"
                    }`}
                    disabled={loading}
                    onClick={() => {
                      setTimeNotification(100000);
                      handleToggleNotification(
                        "Descargando archivo. Puede tardar varios segundos."
                      );
                    }}
                  >
                    Exportar
                  </button>
                </div>
              </div>
            </div>
          </Form>
        )}
      </Formik>
      <Notification
        open={showNotification}
        setOpen={toggleNotification}
        title={textNotification}
        timeOut={timeNotification}
      />
    </div>
  );
}

PropertiesReport.defaultProps = { callBack: null };

export default PropertiesReport;
