import React from "react";
import { useNavigate } from "react-router-dom";
import Billing from "./form";
import Loading from "@/components/Loading";
import getAcquisitionToken from "@/utils/getAcquisitionToken";
import useAcquisitionUser from "@/hooks/useAcquisitionUser";

import logoCYT from "./img/logo-cyt-pro-white.png";

export default function Index() {
  const navigate = useNavigate();

  const token = getAcquisitionToken();
  const { data: user, error } = useAcquisitionUser(token?.uuid);

  if (!token) {
    navigate("/unirse");
  }

  return (
    <div className="relative">
      <div className="space-y-1 bg-blue-800 p-3 pb-10 text-white md:hidden">
        <img
          src={logoCYT}
          alt="Logos Casas y Terrenos"
          className="m-auto mb-10 max-w-xs"
        />
        <p className="text-4xl font-extrabold uppercase leading-10">
          Te damos la bienvenida,{" "}
          {user?.name && `${user.name} ${user.last_name}`}
        </p>
        <p>
          Ingresa la siguiente información fiscal para la generación de facturas
        </p>
      </div>
      <div className="grid-cols-3 md:grid  ">
        <div className="col-span-1 hidden min-h-screen bg-blue-800 pt-16 text-white md:block  ">
          <div className="m-auto block w-3/4 space-y-3">
            <img
              src={logoCYT}
              alt="Logos Casas y Terrenos"
              className="mb-10  max-w-xs"
            />
            <p className="text-5xl  font-extrabold leading-none">
              Te damos la bienvenida,{" "}
              {user?.name && `${user.name} ${user.last_name}`}
            </p>
            <p className="text-xl">
              Ingresa la siguiente información fiscal para la generación de
              facturas
            </p>
          </div>
        </div>
        <div className="col-span-2 block ">
          <div className="m-auto flex w-full px-5 pt-16 md:min-h-screen md:w-3/4 lg:w-2/3">
            <Billing />
          </div>
        </div>
      </div>
    </div>
  );
}
