import React from "react";
import Avatar from "@/components/elements/Avatar";
import fileToBase64 from "@/utils/fileToBase64";
import { updateBusiness } from "@/_actions/businessActions";

export default function UploadLogo({ clientId, logoClient, callBack }) {
  const getFiles = async (e) => {
    const file = e.target.files[0];
    const fileCoverted = await fileToBase64(file);
    const [, ...rest] = fileCoverted.split(",");

    await updateBusiness(clientId, {
      img_real_estate: {
        name: file.name,
        content_type: file.type,
        format: file.name.split(".")[name.split(".").length - 1],
        base64: rest.join(),
        originFile: file,
      },
    });
    callBack && callBack(clientId);
  };

  return (
    <div>
      <div className="flex items-center justify-center">
        <Avatar img={logoClient} />
      </div>
      <div className="flex items-center justify-center">
        <label
          htmlFor="file-upload"
          className="hover:text-primary-default-hover relative cursor-pointer font-medium text-primary-default"
        >
          <svg
            className=" -mr-0.5 inline h-4 w-4"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"
            />
          </svg>
          &nbsp;Subir logo
          <input
            id="file-upload"
            name="file-upload"
            type="file"
            className="sr-only"
            onChange={getFiles}
            accept="image/jpeg,image/png"
          />
        </label>
      </div>
    </div>
  );
}

UploadLogo.defaultProps = {
  callBack: null,
};
