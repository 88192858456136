import React, { useState } from "react";
import * as Yup from "yup";
import { Formik, Form, Field, ErrorMessage } from "formik";

const LoginSchema = Yup.object().shape({
  username: Yup.string()
    .email("Ingresa un correo electrónico válido")
    .required("Este campo es requerido"),
});

export default function ForgotPasswordForm({ onSubmit }) {
  return (
    <Formik
      initialValues={{ username: "" }}
      validationSchema={LoginSchema}
      onSubmit={(values) => onSubmit(values)}
    >
      <Form className="space-y-3">
        <div>
          <label className="form-label" htmlFor="username">
            Correo electrónico
          </label>
          <Field
            id="username"
            type="email"
            name="username"
            className="input"
            placeholder="correo electrónico"
          />
          <ErrorMessage
            component="span"
            className="input-error"
            name="username"
          />
        </div>
        <div>
          <button type="submit" className="button-primary w-full">
            Reestablecer constraseña
          </button>
        </div>
      </Form>
    </Formik>
  );
}
