import React from "react";
import WhatsAppIcon from "./img/whatsapp.svg";
import { useProfile } from "@/_actions/userActions";
import InfoBanner from "@/components/elements/InfoBanner";

export default function Contact() {
  const { data: profile } = useProfile();
  return (
    <div className="sm:p-2 lg:p-4">
      <InfoBanner />
      <h1>Contáctanos</h1>
      <p className="m-auto max-w-screen-md text-center">
        Contáctanos ¿Tienes dudas, comentarios o sugerencias?¡Escríbenos! Para
        nosotros es un placer atenderte y conocer tus opiniones. Horario de
        atención:
        <br></br>
        Lunes a Viernes de 8:30am a 6:00pm
      </p>
      <div className="col-span-3  my-5 grid-cols-2 space-y-3 md:grid">
        <a
          id="whatsappContactProfiled"
          href={`https://api.whatsapp.com/send?phone=+5213317150016&text=Hola, necesito ayuda con mi Panel PRO. ClienteID:${profile?.client}`}
          target="_blank"
          data-action="share/whatsapp/share"
          rel="noreferrer"
          className="m-auto"
        >
          <button className="btn w-full bg-green-whatsapp py-2 text-white hover:bg-green-whatsapp-darker md:h-10 md:w-auto">
            <div className="m-auto flex px-2 text-center">
              <img
                alt="whatsapp ícono"
                className=" w-7 md:mx-2"
                referrerPolicy="no-referrer-when-downgrade"
                src={WhatsAppIcon}
              />
              <span className="mx-2 ">Asistencia por WhatsApp</span>
            </div>
          </button>
        </a>

        <button
          className="btn m-auto w-full bg-blue-cyt px-10 py-2 text-white hover:bg-blue-cyt-alt md:h-10 md:w-auto"
          onClick={() => Userback.open("general")}
        >
          <span>Reporta un error</span>
        </button>
      </div>
      <p className="text-center">
        Correo electrónico:<br></br>
        pelizalde@casasyterrenos.com<br></br>
        Celular:<br></br>
        33 1715 0016<br></br>
        <br></br>
        Dirección: <br></br>Av. de las Américas 1586, Country Club,<br></br>
        CP 44610 Guadalajara, Jal. México.
      </p>
    </div>
  );
}
