import React, { useState, useRef, useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";
import PropTypes from "prop-types";
import CustomButton from "@/components/forms/CustomButton";

export default function FAQ() {
  const faqs = [
    {
      question: "¿PRO Listings tiene algún costo?",
      answer:
        "El programa PRO Listings no tiene ningún costo para los miembros PRO 50 y PRO Ilimitado de Casas y Terrenos. Únicamente necesitan ser una inmobiliaria constituida para expedir facturas o recibos de honorarios.",
    },
    {
      question: "¿Quién me pagará las comisiones?",
      answer: "Las comisiones serán pagadas por ...",
    },
    {
      question: "¿Cuándo me pagarán las comisiones?",
      answer: "Las comisiones serán pagadas en ...",
    },
    {
      question: "¿Pregunta 4?",
      answer: "Respuesta 4 ...",
    },
    {
      question: "¿Pregunta 5?",
      answer: "Respuesta 5 ...",
    },
  ];

  const AccordionItem = ({ question, answer }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [contentHeight, setContentHeight] = useState(0);
    const contentRef = useRef(null);

    useEffect(() => {
      if (contentRef.current) {
        setContentHeight(contentRef.current.clientHeight);
      }
    }, [isOpen]);

    return (
      <div className="w-full border-b border-black pt-8">
        <div className="mb-6 flex items-center justify-between">
          <span>{question}</span>
          <div
            onClick={() => setIsOpen(!isOpen)}
            className="flex w-[88px] cursor-pointer items-center justify-center rounded-full border-2 border-primary-default text-primary-default"
          >
            <span className="-my-0.5 -mt-2 text-4xl font-light">
              {isOpen ? "-" : "+"}
            </span>
          </div>
        </div>
        <AnimatePresence>
          {isOpen && (
            <motion.div
              className="w-full overflow-hidden"
              initial={{ height: 0, opacity: 0 }}
              animate={{ height: contentHeight, opacity: 1 }}
              exit={{ height: 0, opacity: 0 }}
              transition={{
                type: "spring",
                stiffness: 140,
                damping: 15,
                duration: 0.4,
              }}
            >
              <div className="pb-4" ref={contentRef}>
                {answer}
              </div>
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    );
  };
  AccordionItem.propTypes = {
    question: PropTypes.string.isRequired,
    answer: PropTypes.string.isRequired,
  };

  return (
    <div className="mb-10 flex w-full flex-col justify-center px-4 md:px-6 lg:flex-row lg:justify-between lg:space-x-16 lg:px-20 ">
      <div className="lg:max-w-[413px]">
        <h3 className="mb-4 text-3xl font-bold">Preguntas frecuentes</h3>
        <p className="text-lg">
          Comprendemos la importancia de generar acuerdos de venta transparentes
          y claros, por lo cual responderemos a todas tus dudas para brindarte
          toda la información que necesites y tomes una decisión informada.{" "}
          <br /> Si tienes alguna otra pregunta no dudes en contactarnos.
        </p>
        <div className="mt-8 max-w-[200px]">
          <CustomButton
            id="Prolistings-Contacto"
            active={true}
            text="Contacto"
            activeClasses="flex w-full items-center justify-center rounded-full border-2 text-sm lg:text-base cursor-pointer border-primary-default bg-white hover:border-[#C7DAFF] active:border-primary-default"
            inactiveClasses="hover:bg-[#C7DAFF]"
            buttonActiveClasses="border-white active:border-white hover:bg-[#C7DAFF] hover:border-[#C7DAFF] px-4 text-primary-default"
            buttonInactiveClasses="border-[#ffcf86]"
          />
        </div>
      </div>
      <div className="w-full">
        {faqs.map((faq, index) => (
          <AccordionItem
            key={index}
            question={faq.question}
            answer={faq.answer}
          />
        ))}
      </div>
    </div>
  );
}
