import React from "react";
import * as Yup from "yup";
import { Formik, Field, Form, ErrorMessage } from "formik";

import { IntegrationsAPI } from "@/_actions/integrationsActions";

const apiFeeds = ["538", "532", "536"];
const API_TOKKO = "4e26b62edf17d77fada1716c18f8209e";

const schema = Yup.object({
  link_email_crm: Yup.string()
    .email("Correo electrónico inválido")
    .nullable(true),
  feed_id: Yup.string().required("CRM es un campo requerido"),
  external_token: Yup.string()
    .nullable(true)
    .when("feed_id", {
      is: (x) => x == 536 || x == 538 || x == 532,
      then: (schema) => schema.required("Este campo es requerido"),
    }),
  external_email: Yup.string()
    .nullable(true)
    .when("feed_id", {
      is: (x) => x == 536 || x == 538 || x == 532,
      then: (schema) => schema.required("Este campo es requerido"),
    }),
});

export default function Create({ callBack, initialValues }) {
  const { data: feeds } = IntegrationsAPI.useFeeds();

  const submit = async (values) => {
    if (values.external_token === "") delete values.external_token;
    if (values.feed_id === 209) {
      try {
        const response = await IntegrationsAPI.updateEB({
          external_email: values.link_email_crm,
        });
        callBack && callBack(values);
      } catch (err) {
        window.alert(
          "El email no esta vinculado a una agencia en Easy Broker."
        );
        $log.error(err);
      }
    } else {
      try {
        await IntegrationsAPI.updateCRM(values);
        callBack && callBack(values);
      } catch (err) {
        $log.error(err);
      }
    }
  };

  return (
    <div>
      <Formik
        initialValues={{
          ...(initialValues?.link_email_crm && {
            link_email_crm: initialValues?.link_email_crm,
          }),
          ...(initialValues?.feed
            ? { feed_id: initialValues?.feed }
            : { feed_id: 209 }),
          ...(initialValues?.external_token && {
            external_token: initialValues?.external_token,
          }),
          ...(initialValues?.external_email && {
            external_email: initialValues?.external_email,
          }),
        }}
        onSubmit={submit}
        validationSchema={schema}
      >
        {({ isSubmitting, values }) => (
          <Form
            className="space-y-5 p-5 md:space-y-2"
            data-splitbee-event={`integrations-${
              initialValues?.feed ? "add" : "edit-integration"
            }-save`}
          >
            <div>
              <label className="w-full" htmlFor="feed_id">
                CRM
              </label>
              <Field
                id="feed_id"
                as="select"
                name="feed_id"
                className="block w-full rounded-md border border-gray-300 p-2 uppercase"
                onBlur={() => {
                  values.external_token =
                    values.feed_id === "532" ? API_TOKKO : "";
                }}
              >
                {feeds?.map((x) => (
                  <option className="uppercase" value={x.id} key={x.id}>
                    {x.portal}
                  </option>
                ))}
              </Field>
              <ErrorMessage
                render={(msg) => (
                  <div className="text-sm text-red-600">{msg}</div>
                )}
                name="feed_id"
              />
            </div>

            {values?.feed_id ? (
              <>
                {apiFeeds.some((x) => x == values.feed_id) ? (
                  <>
                    {(values.feed_id == 536 || values.feed_id == 538) && (
                      <div>
                        <label
                          className="w-full capitalize  "
                          htmlFor="external_token"
                        >
                          API Token
                        </label>
                        <Field
                          type="string"
                          name="external_token"
                          className="block w-full rounded-md border border-gray-300 p-2"
                        />
                        <ErrorMessage
                          render={(msg) => (
                            <div className="text-sm text-red-600">{msg}</div>
                          )}
                          name="external_token"
                        />
                      </div>
                    )}
                    {(values.feed_id == 538 || values.feed_id == 532) && (
                      <div>
                        <label
                          className="w-full capitalize"
                          htmlFor="external_email"
                        >
                          ID de la Compañía
                        </label>
                        <Field
                          type="string"
                          name="external_email"
                          className="block w-full rounded-md border border-gray-300 p-2"
                        />
                        <ErrorMessage
                          render={(msg) => (
                            <div className="text-sm text-red-600">{msg}</div>
                          )}
                          name="external_email"
                        />
                      </div>
                    )}
                    {(values.feed_id == 536 || values.feed_id == 538) && (
                      <p alt="Cómo obtener API key" className=" ">
                        <a
                          className="hover:underline"
                          href=" https://coda.io/d/_d0J2rLykqao/Como-obtener-claves-API-para-integrarse-a-casasyterrenos-com_suKuC"
                          target="_blank"
                        >
                          ¿Tienes dudas sobre como obtener el API key?
                        </a>
                      </p>
                    )}
                  </>
                ) : (
                  <div>
                    <label
                      className="w-full capitalize"
                      htmlFor="link_email_crm"
                    >
                      email
                    </label>
                    <Field
                      id="link_email_crm"
                      data-testid="email"
                      type="string"
                      name="link_email_crm"
                      className="mb-2 block w-full rounded-md border border-gray-300 p-2"
                      placeholder="example@example.com"
                    />
                    <ErrorMessage
                      render={(msg) => (
                        <div className="text-sm text-red-600">{msg}</div>
                      )}
                      name="link_email_crm"
                    />
                  </div>
                )}
              </>
            ) : null}

            <button
              disabled={isSubmitting}
              className="button-primary-mid"
              type="submit"
            >
              <span className="font-bold">
                {initialValues?.feed ? "Editar" : "Agregar integración"}
              </span>
            </button>
          </Form>
        )}
      </Formik>
    </div>
  );
}

Create.defaultProps = {
  callBack: null,
};
