import jwt_decode from "jwt-decode";

export default function getAcquisitionToken() {
  const urlToken = new URLSearchParams(window.location.search).get("token");

  const localToken = localStorage.getItem("acquisitionToken");

  if (!urlToken && !localToken) return null;

  if (urlToken) {
    localStorage.setItem("acquisitionToken", urlToken);
    try {
      const decoded = jwt_decode(urlToken);
      return decoded;
    } catch (err) {
      return null;
    }
  }
  if (localToken) {
    try {
      const decoded = jwt_decode(localToken);
      return decoded;
    } catch (err) {
      return null;
    }
  }
}
