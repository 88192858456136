import React, { useEffect, useRef, useState, forwardRef } from "react";
import GoogleMapReact from "google-map-react";
const MAPSKEY = import.meta.env.VITE_GOOGLE_MAPS;

//const defaultCoordenates = { lat: 24.1719969, lng: -103.5970966 };
const zoomMap = 5;

function googleMapsBoundsToSearchBounds(bounds) {
  let boundingBox;
  if (Object.prototype.hasOwnProperty.call(bounds, "south")) {
    boundingBox = [bounds.north, bounds.east, bounds.south, bounds.west];
  } else if (Object.prototype.hasOwnProperty.call(bounds, "northeast")) {
    boundingBox = [
      bounds.northeast.lat,
      bounds.northeast.lng,
      bounds.southwest.lat,
      bounds.southwest.lng,
    ];
  } else {
    var ne = bounds.getNorthEast();
    var sw = bounds.getSouthWest();

    boundingBox = [ne.lat(), ne.lng(), sw.lat(), sw.lng()];
  }

  const boxWidth = getBoundingBoxWidth(boundingBox);
  const boxCenter = getBoundingBoxCenter(boundingBox);

  return { boundingBox, boxWidth, boxCenter };
}

function getBoundingBoxCenter(bounds) {
  const center = {
    latitude: (bounds[0] + bounds[2]) / 2,
    longitude: (bounds[1] + bounds[3]) / 2,
  };
  return center;
}

function getBoundingBoxWidth(bounds) {
  const width = getDistance(
    { latitude: bounds[0], longitude: bounds[1] },
    { latitude: bounds[2], longitude: bounds[1] }
  );

  return width;
}

function getDistance(p1, p2) {
  const R = 6378137; // Earth’s mean radius in meter
  const dLat = rad(p2.latitude - p1.latitude);
  const dLong = rad(p2.longitude - p1.longitude);
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(rad(p1.latitude)) *
      Math.cos(rad(p2.latitude)) *
      Math.sin(dLong / 2) *
      Math.sin(dLong / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const d = R * c;
  return d; // returns the distance in meter
}

function rad(x) {
  return (x * Math.PI) / 180;
}

const MapLocation = ({ ...props }, ref) => {
  const mapWrapper = useRef();
  const {
    state,
    municipality,
    colony,
    street,
    exteriorNumber,
    onChange,
    value,
  } = props;

  let [map, setMap] = useState(null);
  let [mapsAPI, setMapsAPI] = useState(null);
  const [defaultCoordenates, setDefaultCoordenates] = useState({
    lat: 24.1719969,
    lng: -103.5970966,
  });

  const [mapsCircleMaker, setMapsCircleMaker] = useState(null);
  const [mapMarker, setMapMarker] = useState(null);
  const [radius, setRadius] = useState(1000);

  const triggerChange = () => {
    const latLng = mapMarker.getPosition();
    if (onChange) {
      onChange([latLng.lat(), latLng.lng()]);
    }
  };

  useEffect(() => {
    if (value) {
      const [lat, lng] = value;
      if (lat && lng) setDefaultCoordenates({ lat, lng });
    }
  }, [value]);

  const checkMarkerPosition = (mapsAPI) =>
    mapsAPI.geometry.spherical.computeDistanceBetween(
      mapMarker.getPosition(),
      mapsCircleMaker.getCenter()
    ) <= mapsCircleMaker.getRadius();

  const callMaps = async (map, mapsAPI) => {
    const searchTerm = `https://maps.googleapis.com/maps/api/geocode/json?address=México+${state}+${municipality}+${colony}+${street}+${exteriorNumber}&key=${MAPSKEY}`;
    let place = await fetch(searchTerm);
    place = await place.json();
    if (place.status === "OK") {
      try {
        let { boxWidth } = googleMapsBoundsToSearchBounds(
          place.results[0].geometry.bounds || place.results[0].geometry.viewport
        );

        if (boxWidth <= 400) {
          setRadius(500);
        } else if (boxWidth < 800) {
          setRadius(800);
        } else if (boxWidth <= 1300) {
          setRadius(boxWidth * 1.5);
        }
        if (boxWidth > 1040403) {
          setRadius(800);
        }
      } catch (err) {
        $log.error(err);
      }

      const { lat, lng } = place.results[0].geometry.location;

      const currentCoordenates = new mapsAPI.LatLng(lat, lng);

      map.setCenter(currentCoordenates);
      map.setOptions({
        zoom: 15,
      });

      mapMarker.setPosition(currentCoordenates);
      mapMarker.setVisible(true);
      mapsCircleMaker.setCenter(currentCoordenates);
      triggerChange();
      mapsAPI.event.addListener(mapMarker, "dragend", () => {
        const inArea = checkMarkerPosition(mapsAPI);
        if (inArea === false) {
          mapMarker.setPosition(currentCoordenates);
        }
        triggerChange();
      });
    } else if (place.status === "ZERO_RESULTS") {
      //message.warning('No se encontró la dirección')
    } else {
      //message.error('Hubo un error en la búsqueda')
    }
  };

  useEffect(() => {
    if (
      state &&
      municipality &&
      colony &&
      street &&
      exteriorNumber &&
      mapsAPI &&
      mapMarker &&
      mapsCircleMaker
    ) {
      callMaps(map, mapsAPI);
    }
  }, [
    state,
    municipality,
    colony,
    street,
    exteriorNumber,
    mapsAPI,
    mapMarker,
    mapsCircleMaker,
  ]);

  const handleApiLoaded = (map, maps) => {
    // use map and maps objects
    let gmapMarker, gmapCircle;
    setMap(map);
    setMapsAPI(maps);

    gmapMarker = new maps.Marker({
      map,
    });
    gmapMarker.setDraggable(true);
    gmapCircle = new maps.Circle({
      map,
      radius: radius,
      fillColor: "#0d47a1",
      strokeColor: "#0d47a1",
    });
    setMapsCircleMaker(gmapCircle);
    setMapMarker(gmapMarker);
  };

  return (
    // Important! Always set the container height explicitly
    <div style={{ height: "500px", width: "100%" }} ref={mapWrapper}>
      {defaultCoordenates ? (
        <GoogleMapReact
          bootstrapURLKeys={{ key: MAPSKEY }}
          defaultCenter={defaultCoordenates}
          defaultZoom={zoomMap}
          yesIWantToUseGoogleMapApiInternals
          onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}
        />
      ) : null}
    </div>
  );
};

export default forwardRef(MapLocation);
