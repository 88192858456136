import React, { useState } from "react";
import { useProperties } from "@/_actions/propertiesActions";
import NumberFormat from "react-number-format";
import Pagination from "@/components/navigation/Pagination";
import Filters from "@/pages/properties/filters/index";
import downloadPropertyPdf from "../../utils/downloadPdf";
import { usePlan } from "@/_actions/authActions";
import Notification from "@/components/overlays/Notification";
import { propertiesParamsInventoryAtom } from "@/_actions/propertiesActions";
import { useAtom } from "jotai";
import InfoBanner from "@/components/elements/InfoBanner";

export default function Index() {
  const [params, setParams] = useAtom(propertiesParamsInventoryAtom);

  const { data: plan } = usePlan();
  const { data: properties } = useProperties(params);
  const [showNotification, toggleNotification] = useState(false);
  const [textNotification, setTextNotification] = useState("");
  const [showNotificationError, toggleNotificationError] = useState(false);
  const [textNotificationError, setTextNotificationError] = useState("");
  const [timeNotification, setTimeNotification] = useState(0);

  const handleToggleNotification = (text) => {
    setTextNotification(text);
    toggleNotification(true);
  };

  const handleToggleNotificationError = (text) => {
    setTextNotificationError(text);
    toggleNotificationError(true);
  };

  return (
    <>
      <InfoBanner />
      <div className="sm:p-2 lg:p-4">
        <div className="sm:flex sm:items-center">
          <div className="sm:flex-auto">
            <p className="title">Inventario</p>
            <p className="my-4 mt-2 text-sm text-gray-700">
              Busca en el inventario de casas y terrenos. Conecta con otros
              promotores y comparte comisiones.
            </p>
          </div>
        </div>
        <Filters type="inventory" basic={false} />

        <div className="mt-3 flex flex-col">
          <div className="-my-2 overflow-x-auto">
            <div className="inline-block min-w-full py-2 align-middle md:px-1 lg:px-2">
              <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                <table className="min-w-full divide-y divide-gray-300">
                  <thead className="hidden bg-gray-50 sm:table-header-group">
                    <tr>
                      <th
                        scope="col"
                        className="py-3.5 px-2 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                      ></th>
                      <th
                        scope="col"
                        className=" py-3.5 px-2 text-left text-sm font-semibold text-gray-900"
                      >
                        Tipo de propedad
                      </th>
                      <th
                        scope="col"
                        className="py-3.5 px-2 text-left text-sm font-semibold text-gray-900"
                      >
                        Inmobiliaria
                      </th>
                      <th
                        scope="col"
                        className="py-3.5 px-2 text-left text-sm font-semibold text-gray-900"
                      >
                        Teléfono
                      </th>
                      <th
                        scope="col"
                        className="py-3.5 px-2 text-left text-sm font-semibold text-gray-900"
                      >
                        Ubicación
                      </th>
                      <th
                        scope="col"
                        className="py-3.5 px-2 text-left text-sm font-semibold text-gray-900"
                      >
                        Promotor
                      </th>
                      <th
                        scope="col"
                        className="py-3.5 px-2 text-left text-sm font-semibold text-gray-900"
                      >
                        Precio
                      </th>
                      <th
                        scope="col"
                        className="py-3.5 px-2 text-left text-sm font-semibold text-gray-900"
                      >
                        Descargar PDF
                      </th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200 bg-white">
                    {properties?.results.map((property) => (
                      <tr key={property.email}>
                        <td className="whitespace-nowrap px-2 py-2 text-sm font-medium text-gray-900 sm:pl-6 lg:py-4">
                          <div className="group relative aspect-square h-[100px] w-[100px] md:h-[120px] md:w-[120px]">
                            <img
                              className="h-full w-full rounded-lg object-cover"
                              src={property.image_cover}
                            />
                            <a
                              target="_blank"
                              href={`${import.meta.env.VITE_PORTAL_URL}${
                                property.slug
                                  ? property.slug
                                  : `/propiedad/${property.id}`
                              }`}
                              data-splitbee-event="contacts-link-property-lead"
                              rel="noreferrer"
                            >
                              <div className="absolute top-0 hidden h-full w-full items-center justify-center rounded-lg bg-black/50 text-white group-hover:flex">
                                Ver anuncio
                              </div>
                            </a>
                          </div>
                        </td>
                        <td className="whitespace-nowrap px-1 py-4 text-sm text-gray-500 sm:px-3">
                          <div className="flex flex-col">
                            <p className="text-sm text-gray-500 sm:text-gray-900">
                              {property.property_type.name}
                            </p>
                            <div className="flex">
                              ID:
                              <a
                                target="_blank"
                                href={`${import.meta.env.VITE_PORTAL_URL}${
                                  property.slug
                                    ? property.slug
                                    : `/propiedad/${property.id}`
                                }`}
                                data-splitbee-event="contacts-link-property-lead"
                              >
                                <p
                                  className="text-link pr-2 pl-1 sm:block"
                                  id={property.id}
                                >
                                  {property.id}
                                </p>
                              </a>
                              <button
                                onClick={() => {
                                  const content = document.getElementById(
                                    property.id
                                  ).innerHTML;
                                  navigator.clipboard
                                    .writeText(content)
                                    .catch((err) => {
                                      $log.error("Something went wrong", err);
                                    });
                                }}
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  className="h-5 w-5"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  stroke="rgb(13 71 161)"
                                  strokeWidth="1.5"
                                >
                                  <rect
                                    x="9"
                                    y="9"
                                    width="13"
                                    height="13"
                                    rx="2"
                                    ry="2"
                                  ></rect>
                                  <path d="M5 15H4a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h9a2 2 0 0 1 2 2v1"></path>
                                </svg>
                              </button>
                            </div>
                          </div>
                        </td>
                        <td className=" px-2 py-4 text-sm text-gray-500">
                          {property.name_client}
                        </td>
                        <td className=" px-2 py-4 text-sm text-gray-500">
                          {property?.related_phones?.promotor.whatsapp
                            ? property?.related_phones?.promotor.whatsapp
                            : property?.related_phones?.promotor.phone_number
                            ? property?.related_phones?.promotor.phone_number
                            : property?.related_phones?.client.whatsapp
                            ? property?.related_phones?.client.whatsapp
                            : null}
                        </td>
                        <td className="px-2 py-4 text-sm capitalize text-gray-500">
                          <div className="w-35">
                            {property?.colony?.name.toLowerCase()},{" "}
                            {property?.municipality?.name.toLowerCase()}{" "}
                            {property?.state?.name.toLowerCase()}
                          </div>
                        </td>
                        <td className="relative whitespace-nowrap py-4 px-2 text-left text-sm font-medium sm:pr-6">
                          {property.related_phones?.whatsapp ? (
                            <div>{property.related_phones?.whatsapp}</div>
                          ) : null}
                          {property.related_phones?.mobile ? (
                            <div>{property.related_phones?.mobile}</div>
                          ) : null}
                          {property.membership_email ? (
                            <div>{property.membership_email}</div>
                          ) : null}
                        </td>
                        <td className="relative py-4 px-2 text-left text-sm font-medium sm:pr-6">
                          {property.price_sale ? (
                            <div>
                              Venta: &nbsp;
                              <NumberFormat
                                displayType="text"
                                thousandSeparator={true}
                                prefix={"$"}
                                value={property.price_sale}
                              ></NumberFormat>
                            </div>
                          ) : null}

                          {property.price_rent ? (
                            <div>
                              Renta: &nbsp;
                              <NumberFormat
                                displayType="text"
                                thousandSeparator={true}
                                prefix={"$"}
                                value={property.price_rent}
                              ></NumberFormat>
                            </div>
                          ) : null}

                          {property.price_transfer ? (
                            <div>
                              Traspaso:&nbsp;
                              <NumberFormat
                                displayType="text"
                                thousandSeparator={true}
                                prefix={"$"}
                                value={property.price_transfer}
                              ></NumberFormat>
                            </div>
                          ) : null}
                        </td>
                        <td className="text-center">
                          {plan?.plan_addons?.properties_pdfs ? (
                            <div className="has-tooltip">
                              <button
                                type="button"
                                onClick={() => {
                                  setTimeNotification(100000);
                                  handleToggleNotification(
                                    "Descargando archivo. Puede tardar varios segundos."
                                  );
                                  downloadPropertyPdf(property.id)
                                    .then(() => {
                                      toggleNotification(false);
                                      setTimeNotification(5000);
                                      handleToggleNotification(
                                        "Archivo descargado con éxito."
                                      );
                                    })
                                    .catch(() => {
                                      toggleNotification(false);
                                      handleToggleNotificationError(
                                        "Ha ocurrido un error."
                                      );
                                    });
                                }}
                                className="text-blue-cyt"
                              >
                                <div className="relative">
                                  <div className="tooltip -top-9 -left-7 rounded bg-gray-900 px-4 py-1 text-sm font-medium text-white">
                                    Descargar PDF
                                  </div>
                                  <svg
                                    className="tooltip -top-3 fill-gray-900"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 18.2 9"
                                  >
                                    <path
                                      className="text-white"
                                      d="M9.1,9L0,0l18.2,0L9.1,9z"
                                    />
                                  </svg>
                                </div>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  className="h-5 w-5"
                                  viewBox="0 0 15 15"
                                  fill="currentColor"
                                >
                                  <path
                                    fillRule="evenodd"
                                    d="M3 17a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zm3.293-7.707a1 1 0 011.414 0L9 10.586V3a1 1 0 112 0v7.586l1.293-1.293a1 1 0 111.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z"
                                    clipRule="evenodd"
                                  />
                                </svg>
                              </button>
                            </div>
                          ) : null}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <Notification
          open={showNotification}
          setOpen={toggleNotification}
          title={textNotification}
          timeOut={timeNotification}
        />
        <Notification
          open={showNotificationError}
          setOpen={toggleNotificationError}
          title={textNotificationError}
          type="error"
        />
      </div>
      <Pagination
        total={properties?.count}
        type={"Propiedad"}
        current={params.page}
        next={properties?.next}
        previous={properties?.previous}
        pageSize={properties?.results?.length}
        onPageChange={(page) => setParams((prev) => ({ ...prev, page }))}
      />
    </>
  );
}
