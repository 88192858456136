import axios from "axios";
import apiClient from "../utils/apiClient";
import download from "downloadjs";
import { atom } from "jotai";
import { format } from "date-fns";

import add from "date-fns/add";

import useSWR from "swr";
/**
 * @typedef {Object} SWRObject
 * @property {object} data - The data returned by the swr call
 * @property {boolean} isLoading - State of the swr call
 */
/** @function
 * @name useOrigin
 * @description - Returns the data from the feeds
 * @returns {SWRObject} swr Object
 */
export const useOrigin = () => {
  const fetcher = (url) => apiClient.get(url).then((res) => res.data);
  const { data, error } = useSWR("/property/feeds?status=True", fetcher);
  return { data, isLoading: !error && !data, error };
};

/**
 * @typedef {Object} SWRObject
 * @property {object} data - The data returned by the swr call
 * @property {boolean} isLoading - State of the swr call
 */
/** @function
 * @name usePropertyType
 * @description - Returns the differents types of the properties
 * @returns {SWRObject} swr Object
 */
export const usePropertyType = () => {
  const fetcher = (url) => apiClient.get(url).then((res) => res.data);
  const { data, error } = useSWR("/property_type", fetcher);
  return { data, isLoading: !error && !data, error };
};

function parsePriceFilter(params) {
  if (params.operation_type?.includes(1) && params.ordering) {
    if (params.ordering.startsWith("-price")) params.ordering = "-price_sale";
    if (params.ordering.startsWith("price")) params.ordering = "price_sale";
  }
  if (params.operation_type?.includes(2) && params.ordering) {
    if (params.ordering.startsWith("-price")) params.ordering = "-price_rent";
    if (params.ordering.startsWith("price")) params.ordering = "price_rent";
  }
  if (params.operation_type?.includes(3) && params.ordering) {
    if (params.ordering.startsWith("-price"))
      params.ordering = "-price_transfer";
    if (params.ordering.startsWith("price")) params.ordering = "price_transfer";
  }
  if (
    (!params.operation_type ||
      params.operation_type?.length == 0 ||
      params.operation_type.length > 1) &&
    params.ordering
  ) {
    if (params.ordering.startsWith("-price")) params.ordering = "-price_sale";
    if (params.ordering.startsWith("price")) params.ordering = "price_sale";
  }
  return params;
}
/**
 * @typedef {Object} SWRObject
 * @property {object} data - The data returned by the swr call
 * @property {boolean} isLoading - State of the swr call
 */
/** @function
 * @name useMyProperties
 * @param {Object} params - The params for the properties (page, status, ordering)
 * @description - Returns the properties published from the client
 * @returns {SWRObject} swr Object
 */
export const useMyProperties = (params) => {
  params = parsePriceFilter(params);
  const fetcher = ({ url, params }) =>
    apiClient
      .get(url, {
        params,
        paramsSerializer: (params) => {
          const searchParams = new URLSearchParams();
          for (const key of Object.keys(params)) {
            const param = params[key];
            if (Array.isArray(param)) {
              searchParams.append(key, param.join(","));
            } else {
              searchParams.append(key, param);
            }
          }
          return searchParams.toString();
        },
      })
      .then((res) => res.data);
  const { data, error, mutate } = useSWR(
    { url: "/my_property", params },
    fetcher
  );
  return { data, isLoading: !error && !data, error, mutate };
};

export const useMyPropertiesCount = () => {
  const fetcher = ({ url }) => apiClient.get(url).then((res) => res.data);
  const { data, error, mutate } = useSWR(
    { url: "/my_properties_count" },
    fetcher
  );
  return { data, isLoading: !error && !data, error, mutate };
};

export const propertiesFilterInitialValues = {
  page: 1,
  status: "published",
  ordering: "-created",
};

export const propertiesFilterInventoryInitialValues = {
  page: 1,
  full: true,
  status: "published",
  ordering: "-created",
};

export const propertiesParamsAtom = atom(propertiesFilterInitialValues);
export const propertiesParamsInventoryAtom = atom(
  propertiesFilterInventoryInitialValues
);

/** @function
 * @name createProperty
 * @description - Create a new property
 * @param {Object} data - values for the new property
 * @returns - returns the response if the property was created successfully
 */
export const createProperty = async (data) => {
  try {
    return await apiClient.post(`/property`, data);
  } catch (err) {
    $log.error(err);
  }
};

/** @function
 * @name getPdf
 * @description - obtain the pdf from the property
 * @param {object} property - Data for the property
 * @param {Object} contactData - Info for contact to user
 */
export const getPdf = async (property, contactData) => {
  try {
    const data = await axios({
      method: "POST",
      url: import.meta.env.VITE_PDF_API,
      headers: {
        Accept: "application/octet-stream",
        token: import.meta.env.VITE_PDF_TOKEN,
      },
      responseType: "blob",
      data: {
        property,
        contactData,
      },
    });
    download(data.data, `${property.id}.pdf`, "application/pdf");
  } catch (err) {
    $log.error(err);
  }
};

/**
 * @typedef {Object} SWRObject
 * @property {object} data - The data returned by the swr call
 * @property {boolean} isLoading - State of the swr call
 */
/** @function
 * @name useProperty
 * @description - Returns the data for the property
 * @param {Number} propertyId - id for the property
 * @returns {SWRObject} swr Object
 */
export const useProperty = (propertyId) => {
  const fetcher = (url) => apiClient.get(url).then((res) => res.data);
  const { data, error } = useSWR(
    propertyId ? `property/${propertyId}` : null,
    fetcher
  );
  return { data, isLoading: !error && !data, error };
};

/** @function
 * @name updateProperty
 * @description - Update the property data
 * @param {Number} propertyId - Id for the property
 * @param {object} data - values for the property
 * @returns {object} - Returns the response Axios Achema
 */
export const updateProperty = async (propertyId, data) => {
  try {
    return await apiClient.patch(`/property/${propertyId}`, data);
  } catch (err) {
    $log.error(err);
  }
};

/**
 * @typedef {Object} SWRObject
 * @property {object} data - The data returned by the swr call
 * @property {boolean} isLoading - State of the swr call
 */
/** @function
 * @name useProperties
 * @description - Returns the properties
 * @param {Object} params - values for search properties
 * @returns {SWRObject} swr Object
 */
export const useProperties = (params) => {
  const fetcher = ({ url, params }) =>
    apiClient
      .get(url, {
        params,
        paramsSerializer: (params) => {
          const searchParams = new URLSearchParams();
          for (const key of Object.keys(params)) {
            const param = params[key];
            if (Array.isArray(param)) {
              searchParams.append(key, param.join(","));
            } else {
              searchParams.append(key, param);
            }
          }
          return searchParams.toString();
        },
      })
      .then((res) => res.data);
  const { data, error, mutate } = useSWR({ url: "/property", params }, fetcher);
  return { data, isLoading: !error && !data, error, mutate };
};

/**
 * @param {{status: string, id: string}} actionObject
 */

export const toggleProperty = async (
  { status, id, confirm = false },
  callBack
) => {
  if (confirm && status === "inactive") {
    if (
      window.confirm("¿Estás seguro que quieres desactivar esta propiedad?")
    ) {
      await apiClient.patch(`/property/${id}`, {
        status,
        id,
      });
      callBack && callBack();
    }
  } else if (confirm && status === "published") {
    if (window.confirm("¿Estás seguro que quieres activar esta propiedad?")) {
      await apiClient.patch(`/property/${id}`, {
        status,
        id,
      });
      callBack && callBack();
    }
  } else {
    await apiClient.patch(`/property/${id}`, {
      status,
      id,
    });
    callBack && callBack();
  }
};

export const toggleFeatured = async ({
  featured,
  id,
  confirmDialog = true,
}) => {
  if (!confirmDialog) {
    await apiClient.patch(`/update_featured_property/`, {
      properties: [{ property_id: id, status: featured ? 1 : 2 }],
    });
  } else {
    if (featured) {
      if (
        window.confirm("¿Estás seguro que quieres destacar esta propiedad?")
      ) {
        await apiClient.post(`/featured_property/`, {
          properties: [
            {
              property: id,
              start_date: format(new Date(), "yyyy-MM-dd"),
              end_date: format(
                add(new Date(), {
                  days: 90,
                }),
                "yyyy-MM-dd"
              ),
              status: featured ? 1 : 2,
            },
          ],
        });
      }
    } else if (!featured) {
      if (
        window.confirm(
          "¿Estás seguro que quieres remover el estatus destacado de esta propiedad?"
        )
      ) {
        await apiClient.patch(`/update_featured_property/`, {
          properties: [{ property_id: id, status: featured ? 1 : 2 }],
        });
      }
    } else {
      await apiClient.post(`/featured_property/`, {
        properties: [
          {
            property: id,
            start_date: format(new Date(), "yyyy-MM-dd"),
            end_date: format(
              add(new Date(), {
                days: 30,
              }),
              "yyyy-MM-dd"
            ),
            status: featured ? 1 : 2,
          },
        ],
      });
    }
  }
};

/** @function
 * @name usePropertyStats
 * @description - Get ctr, visits and leads for property
 * @param {Number} propertyId - The property id to get stats for
 */

export const usePropertyStats = (propertyId) => {
  const fetcher = (url) => apiClient.get(url).then((res) => res.data);
  const { data, error } = useSWR(
    propertyId ? `stats_my_property/${propertyId}` : null,
    fetcher
  );
  return { data, isLoading: !error && !data, error };
};
