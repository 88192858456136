import apiClient from "../utils/apiClient";

/** @function
 * @name getReceipts
 * @description - Obtains the all of the receipts available.
 * @returns {object} - data receipts.
 */
export const getReceipts = async () => {
  try {
    const response = await apiClient.get("/invoices");
    return response;
  } catch (err) {
    $log.error(err);
  }
};
