import apiClient from "../utils/apiClient";
import useSWR from "swr";

/**
 * @typedef {Object} SWRObject
 * @property {object} data - The data returned by the swr call
 * @property {boolean} isLoading - State of the swr call
 */
/** @function
 * @name usePromoters
 * @description - Returns the values for the fromoters
 * @param {Object} params - status and size for the promoters
 * @returns {SWRObject} swr Object
 */

export const usePromoters = (params) => {
  const fetcher = ({ url, params }) =>
    apiClient.get(url, { params }).then((res) => res?.data?.results);

  const { data, error } = useSWR({ url: "/membership", params }, fetcher);

  return { data, isLoading: !error && !data, error };
};

/**
 * @typedef {Object} SWRObject
 * @property {object} data - The data returned by the swr call
 * @property {boolean} isLoading - State of the swr call
 */
/** @function
 * @name usePromoters
 * @description - Returns the values for one promoter
 * @param {Object} id - id for the promoter
 * @returns {SWRObject} swr Object
 */

export const usePromoter = (id) => {
  const fetcher = ({ url, params }) =>
    apiClient.get(url, { params }).then((res) => res?.data);
  const { data, error, mutate } = useSWR({ url: `/membership/${id}` }, fetcher);

  return { data: data, isLoading: !error && !data, error, mutate };
};
