import apiClient from "../utils/apiClient";

import { normalizePropertyDataResponse } from "./publicApiResponseNormalizer";
import { getPdf } from "../_actions/propertiesActions";

const downloadPropertyPdf = (propertyId) => {
  const propertyRequest = apiClient.get(`/public/property/${propertyId}`);
  const userDataRequest = apiClient.get("/accounts/me/");
  return Promise.all([propertyRequest, userDataRequest])
    .then(([propertyResponse, userData]) => {
      const normalizedPropertyData = normalizePropertyDataResponse(
        propertyResponse.data
      );
      console.log("normalized property data");
      return { normalizedPropertyData, userData };
    })
    .then(async ({ normalizedPropertyData, userData }) => {
      await getPdf(normalizedPropertyData, userData.data[0]);
    });
};

export default downloadPropertyPdf;
