import React from "react";

/* Implemented to used in Formik as Field
 *
 * Example:
 * <Field name="some" component={Select} />
 * */
export default function Select({
  field,
  options = [],
  label = "",
  selected = true,
  ...props
}) {
  return (
    <select
      {...field}
      {...props}
      className={selected ? "input" : "input w-40 bg-zinc-200 text-gray-500"}
      disabled={!selected}
    >
      {label && (
        <option value="" disabled selected>
          {label}
        </option>
      )}
      {options.map((option) => (
        <option key={option.id} value={option.id}>
          {option.name}
        </option>
      ))}
    </select>
  );
}
