import React from "react";

export default function Mica() {
  return (
    <div className="flex w-full items-center justify-center">
      <iframe
        src="https://mica.rent/casas-y-terrenos-investigacion"
        className="h-screen w-full"
        allow="clipboard-read; clipboard-write;"
      />
    </div>
  );
}
