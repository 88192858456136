/* This example requires Tailwind CSS v2.0+ */
import React from "react";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/solid";
import plurales from "plurales";

export default function Pagination({
  current = 1,
  type,
  total,
  pageSize,
  next,
  previous,
  onPageChange,
}) {
  const pages = Math.ceil(total / pageSize);

  return (
    <div className="flex items-center justify-between border-t border-gray-200 bg-white px-4 py-3 sm:px-6">
      <div className="flex flex-1 justify-between sm:hidden">
        {previous ? (
          <span
            onClick={() => onPageChange(current - 1)}
            className="relative inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
          >
            Previo
          </span>
        ) : null}
        {next ? (
          <span
            onClick={() => onPageChange(current + 1)}
            className="relative inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
          >
            Siguiente
          </span>
        ) : null}
      </div>
      <div className="hidden sm:flex sm:flex-1 sm:items-center sm:justify-between">
        <div>
          <p className="text-sm text-gray-700">
            <span className="font-medium">{total}</span>
            &nbsp;{total == 1 ? type : plurales(type)}
          </p>
        </div>
        <div>
          <nav
            className="relative z-0 inline-flex -space-x-px rounded-md shadow-sm"
            aria-label="Pagination"
          >
            {current !== 1 && previous ? (
              <span
                onClick={() => onPageChange(current - 1)}
                className="relative inline-flex items-center rounded-l-md border border-gray-300 bg-white px-2 py-2 text-sm font-medium text-gray-500 hover:bg-gray-50"
              >
                <span className="sr-only">Previo</span>
                <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
              </span>
            ) : null}
            {pages ? (
              pages < 15 ? (
                [...Array(pages)].map((e, i) => (
                  <span
                    key={i}
                    onClick={() => onPageChange(i + 1)}
                    aria-current="page"
                    className={
                      i + 1 != current
                        ? "relative inline-flex cursor-pointer items-center border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-500 hover:bg-gray-50"
                        : "relative z-10 inline-flex items-center border border-indigo-500 bg-indigo-50 px-4 py-2 text-sm font-medium text-indigo-600"
                    }
                  >
                    {i + 1}
                  </span>
                ))
              ) : (
                <>
                  <span
                    onClick={() => onPageChange(1)}
                    aria-current="page"
                    className={
                      "relative z-10 inline-flex items-center border border-indigo-500 bg-indigo-50 px-4 py-2 text-sm font-medium text-indigo-600"
                    }
                  >
                    {current}
                  </span>
                </>
              )
            ) : null}
            {next ? (
              <span
                onClick={() => onPageChange(current + 1)}
                className="relative inline-flex items-center rounded-r-md border border-gray-300 bg-white px-2 py-2 text-sm font-medium text-gray-500 hover:bg-gray-50"
              >
                <span className="sr-only">Siguiente</span>
                <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
              </span>
            ) : null}
          </nav>
        </div>
      </div>
    </div>
  );
}

Pagination.defaultProps = {
  onPageChange: () => true,
  total: null,
  pageSize: null,
  next: null,
  previous: null,
};
