import React, { useState, useEffect } from "react";
import { useProfile } from "@/_actions/userActions";
import {
  ExclamationCircleIcon,
  ChevronRightIcon,
} from "@heroicons/react/outline";
import PropTypes from "prop-types";

export default function InfoBanner() {
  const [showBanner, setShowBanner] = useState(false);
  const { data: profile } = useProfile();
  useEffect(() => {
    if (profile?.business_name == "" || profile?.logo_client == "") {
      setShowBanner(true);
    } else {
      setShowBanner(false);
    }
  }, [profile]);
  return (
    <>
      {showBanner && (
        <div className="mb-5 flex w-full rounded-lg border border-[#FFC702] bg-[#FDEDB2] p-2 font-bold text-[#713601]">
          <ExclamationCircleIcon className="mr-1 w-5" />
          Completa los detalles de tu cuenta para publicar propiedades 🏘
          <a
            href="/profile"
            alt="profile redirect"
            className=" ml-2 flex underline"
          >
            Ir a cuenta
            <ChevronRightIcon className="mt-1 w-5" />
          </a>
        </div>
      )}
    </>
  );
}

InfoBanner.PropTypes = {
  pathProfile: PropTypes.bool,
};
