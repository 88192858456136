import React, { useState } from "react";
import { SearchIcon, SortDescendingIcon } from "@heroicons/react/outline";
import { FilterIcon } from "@heroicons/react/solid";
import Modal from "@/components/overlays/Modal";
import {
  useOrigin,
  usePropertyType,
  propertiesParamsInventoryAtom,
  propertiesParamsAtom,
} from "@/_actions/propertiesActions";
import { operationTypes, status, order } from "@/constants/properties/filters";
import Desktop from "./Desktop";
import Mobile from "./Mobile";
import { useAtom } from "jotai";

export default function Filters({ onSubmit, initialValues, basic, type }) {
  const [params, setParams] = useAtom(
    type === "inventory" ? propertiesParamsInventoryAtom : propertiesParamsAtom
  );
  const [action, setAction] = useState("");
  const { data: originsData = [] } = useOrigin();
  const { data: propertyTypes = [] } = usePropertyType();

  const origins = originsData
    .map((x) => {
      if (!isFinite(x.portal) && x.portal !== "undefined") {
        return { ...x, name: x.portal };
      }
      return null;
    })
    .filter((x) => x);

  origins.push({
    "id": "-1",
    "portal": "Casas y Terrenos",
    "status": true,
    "name": "Casas y Terrenos",
  });


  return (
    <div className="space-y-6 divide-y">
      <Modal open={action !== ""} setOpen={() => setAction("")}>
        <Mobile
          propertyTypes={propertyTypes}
          operationTypes={operationTypes}
          origins={origins}
          status={status}
          action={action}
          callBack={() => setAction("")}
          order={order}
          type={type}
        />
      </Modal>
      <div className="flex w-full space-x-1">
        {basic ? null : (
          <div className="flex-grow">
            <label className="relative block">
              <span className="sr-only">Search</span>
              <span className="absolute inset-y-0 left-0 flex items-center pl-2">
                <SearchIcon className="h-5 w-5 text-gray-400" />
              </span>
              <input
                id="search"
                type="text"
                className="input pl-9 pr-3"
                placeholder="Busca por ID o promotor"
                name="search"
                onChange={(e) => {
                  const regex = /^[0-9]*$/;
                  if (regex.test(e.target.value)) {
                    delete params.email_membership;
                    setParams({ ...params, search: e.target.value });
                  } else {
                    delete params.search;
                    setParams({ ...params, email_membership: e.target.value });
                  }
                }}
              />
            </label>
          </div>
        )}
        <button
          className="button-secondary block sm:hidden"
          type="button"
          onClick={() => {
            setAction("filter");
          }}
        >
          <FilterIcon className="h-5 w-5" />
        </button>
        <button
          className="button-secondary block sm:hidden"
          type="button"
          onClick={() => {
            setAction("order");
          }}
        >
          <SortDescendingIcon className="h-5 w-5" />
        </button>
      </div>
      <div className="hidden pt-6 sm:flex">
        <Desktop
          propertyTypes={propertyTypes}
          operationTypes={operationTypes}
          origins={origins}
          status={status}
          basic={basic}
          type={type}
        />
      </div>
    </div>
  );
}

Filters.initialProps = {
  basic: false,
};
