import { Auth } from "aws-amplify";
import apiClient from "../utils/apiClient";
import useSWR from "swr";
import axios from "axios";

/**
 * @typedef {Object} SWRObject
 * @property {object} data - The data returned by the swr call
 * @property {boolean} isLoading - State of the swr call
 */

/** @function
 * @name usePlan
 * @desc Returns the plan for the curren user client
 * @return {SWRObject} swr Object
 */

export const usePlan = () => {
  const fetcher = (url) => apiClient.get(url).then((res) => res.data.plan);
  const { data, error } = useSWR("/clients/plan/", fetcher);

  return { data, isLoading: !error && !data, error };
};

/** @function
 * @name authUser
 * @desc Auths a user with username and password
 * @param {string} username - Email for the user.
 * @param {string} password - Password for the user.
 */
export const authUser = async (username, password) =>
  Auth.signIn(username, password);

export const getSession = () => Auth.currentSession();

/** @function
 * @name useSession
 * @desc Returns the session for the current user
 * @return {SWRObject} swr Object
 * @example
 * const { data, isLoading, error } = useSession();
 * if (isLoading) return <div>Loading...</div>;
 * if (error) return <div>Failed to load</div>;
 * if (data) return <div>Session: {data}</div>;
 */

export const useSession = () => {
  const fetcher = () => Auth.currentSession();
  const { data, error } = useSWR("/session/", fetcher);

  return { data, isLoading: !error && !data, error };
};

/** @function
 * @name sendResetPasswordCode
 * @desc Sends a recovery code to reset password
 * @param {string} data - Object containing username (email)
 */
export async function sendResetPasswordCode(data) {
  return axios.post(
    `${import.meta.env.VITE_API_URL}auth/send_code_password/`,
    data
  );
}

/** @function
 * @name sendResetPassword
 * @desc Sends a the recovery code and the required data to reset password
 * @param {string} data - Object containing username, code and password
 */
export async function sendResetPassword(data) {
  return axios.post(
    `${import.meta.env.VITE_API_URL}auth/reset_password/`,
    data
  );
}
