import React, { useEffect, useState } from "react";

import apiClient from "../../utils/apiClient";
import Modal from "../../components/overlays/Modal";

import { IntegrationsAPI } from "@/_actions/integrationsActions";
import FeedForm from "./forms/feed";
import { sub, formatISO } from "date-fns";
import { fields } from "../../../src/constants/properties/errors";
import { useProfile } from "@/_actions/userActions";
import Pagination from "@/components/navigation/Pagination";
import InfoBanner from "@/components/elements/InfoBanner";

export default function Index() {
  const { data: dataCRM, mutate } = IntegrationsAPI.useGetCRM();
  const [viewForm, toggleForm] = useState(false);
  const { data: user } = useProfile();

  return (
    <div className="m-auto max-w-screen-xl bg-white px-5 sm:mt-10">
      <InfoBanner />
      <div className="md:flex md:items-center md:justify-between ">
        <div className="min-w-0 flex-1">
          <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl">
            Integraciones
          </h2>
          <p>
            En esta sección podrás agregar y editar la integración a tu CRM
            inmobiliario para sincronizar inventario.
          </p>
        </div>
        <div className="mt-4 flex md:mt-0 md:ml-4">
          {dataCRM?.feed_name ? null : (
            <button
              onClick={() => {
                toggleForm(!viewForm);
              }}
              type="button"
              className="button-primary-mid"
              data-splitbee-event="integrations-add"
            >
              Agregar
            </button>
          )}
        </div>
      </div>
      {dataCRM?.feed_name && user ? (
        <Integration
          name={dataCRM?.feed_name}
          feedId={dataCRM?.feed}
          setOpenModal={toggleForm}
          user={user}
        />
      ) : null}
      <Modal
        open={viewForm}
        setOpen={toggleForm}
        callBack={() => {
          toggleForm(false);
        }}
      >
        <FeedForm
          initialValues={dataCRM || {}}
          callBack={() => {
            toggleForm(false);
            mutate();
          }}
        />
      </Modal>
    </div>
  );
}

function Integration({ name, lastSync, feedId, setOpenModal, user }) {
  const { mutate: mutateCRM } = IntegrationsAPI.useGetCRM();
  const [count, setCount] = useState("");
  const [seeErrors, setSeeErrors] = useState(true);
  let date = 0;
  const yesterday = sub(new Date(), {
    days: 1,
  });
  const year = sub(new Date(), {
    months: 12,
  });
  if (user?.feed?.id === 209) {
    date = formatISO(year);
  } else {
    date = formatISO(yesterday);
  }
  const [params, setParams] = useState({
    client: user?.client,
    date: date,
    page: 1,
    feedId: user?.feed?.id,
  });
  const { data: errors } = IntegrationsAPI.useGetPropertyErrors(params);
  let list = [];
  if (errors) {
    errors.results.map((e) => {
      if (user?.feed?.id === 209) {
        list.push(e);
      } else if (
        e.job_id === errors.results[errors.results.length - 1].job_id
      ) {
        list.push(e);
      }
    });
  }

  async function getFeedProperties() {
    try {
      const { data: properties } = await apiClient.get(
        `/property?feed=${feedId}&status=published`
      );
      setCount(properties.count);
    } catch (err) {
      log.error(err);
    }
  }

  useEffect(() => {
    getFeedProperties();
  }, []);

  return (
    <div>
      <ul role="list" className="space-y-2 sm:space-y-4 sm:pt-4 ">
        <li className="space-y-3 rounded-lg border border-gray-800 bg-white px-4 py-4 hover:shadow sm:py-6 sm:px-6">
          <>
            <div className="flex justify-between sm:items-baseline">
              <h3 className="text-2xl font-medium uppercase">
                <span className="text-gray-900">{name}</span>
              </h3>
              <p className="mt-1 whitespace-nowrap text-sm text-gray-600 sm:mt-0 sm:ml-3">
                <button
                  type="button"
                  data-splitbee-event="integrations-edit-integration"
                  className="inline-flex items-center rounded-md border border-transparent bg-blue-cyt p-3 text-white shadow-sm hover:bg-blue-cyt-alt focus:outline-none focus:ring-2 focus:ring-blue-cyt-alt focus:ring-offset-2"
                  onClick={() => setOpenModal(true)}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="mr-3 h-5 w-5"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z"
                    />
                  </svg>
                  Editar
                </button>
              </p>
            </div>
            {/* TBD */}
            {/* <p>Ultima actualización hoy a las 9:00hrs</p> */}
            <p>{count} propiedades sincronizadas activas</p>
            <button
              onClick={() => {
                IntegrationsAPI.removeIntegration();
                mutateCRM();
              }}
              className="button-error-mid"
              data-splitbee-event="integrations-delete-integration"
            >
              Remover Integración
            </button>
          </>
        </li>
      </ul>
      <ul
        role="list"
        className={list.length !== 0 ? "space-y-2 pt-2 sm:space-y-4" : "hidden"}
      >
        <li className="space-y-3 rounded-lg border border-gray-800 bg-white px-1 py-2 hover:shadow sm:py-6 sm:px-6">
          <p className="pl-3">{errors?.count} Errores en propiedades</p>
          <div
            className={
              seeErrors
                ? "hidden"
                : "overflow-x-auto rounded-lg shadow ring-1 ring-black ring-opacity-5"
            }
          >
            <div className="inline-block min-w-full rounded-lg shadow ring-1 ring-black ring-opacity-5">
              <table className="min-w-full divide-y divide-gray-300">
                <thead className="bg-gray-50 sm:table-header-group">
                  <tr>
                    <th
                      scope="col"
                      className="pl-4 text-left text-sm font-semibold text-gray-900 sm:py-3.5 sm:pl-10"
                    >
                      Fecha
                    </th>
                    <th
                      scope="col"
                      className="sm-px-2 pr-2 text-left text-sm font-semibold text-gray-900 sm:py-3.5"
                    >
                      Id propedad
                    </th>
                    <th
                      scope="col"
                      className="pr-2 text-left text-sm font-semibold text-gray-900 sm:py-3.5 sm:px-2"
                    >
                      Error(es)
                    </th>
                    <th
                      scope="col"
                      className="pr-4 text-left text-sm font-semibold text-gray-900 sm:py-3.5 sm:px-2"
                    >
                      Descripción
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 bg-white">
                  {list.map((description) => (
                    <tr key={description?.feed_property_id}>
                      <td className="py-1 pl-4 text-sm text-gray-500 sm:pl-10">
                        {new Date(description.created).toLocaleDateString(
                          "es-MX"
                        )}
                      </td>
                      <td className="py-1 pr-2 text-sm text-gray-500 sm:px-2">
                        {description.feed_property_id}
                      </td>
                      <td className="whitespace-nowrap py-1 pr-2 text-sm text-gray-500 sm:px-2">
                        {Object.values(description.meta).map((e, k) => (
                          <p key={k} className="py-0.5">
                            {fields[e]}
                          </p>
                        ))}
                      </td>
                      <td className="py-1 pr-2 text-sm text-gray-500 sm:px-2">
                        {Object.keys(description.meta).map((e, k) => (
                          <p key={k} className="py-0.5">
                            {fields[e]}
                          </p>
                        ))}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <Pagination
              total={errors?.count}
              type={"Error"}
              current={params.page}
              next={errors?.next}
              previous={errors?.previous}
              pageSize={10}
              onPageChange={(page) => setParams((prev) => ({ ...prev, page }))}
            />
          </div>
          <button
            className={list.length !== 0 ? "button-primary ml-3" : "hidden"}
            onClick={() =>
              seeErrors ? setSeeErrors(false) : setSeeErrors(true)
            }
          >
            {seeErrors ? "Ver errores" : "Ocultar errores"}
          </button>
        </li>
      </ul>
    </div>
  );
}
