import React, { useState } from "react";
import { useAtom } from "jotai";
import {
  useContacts,
  contactsParamsAtom,
  initialParams,
} from "@/_actions/contactActions";
import ContactDetail from "./ContactDetail";

export default function ContactInfo() {
  const url = window.location.pathname;
  const contactId = url.substring(10, url.length);
  const { data: contact, error } = useContacts({
    page: 1,
    status: 1,
    id: contactId,
  });

  return (
    <>
      <h1 className="title">Contacto</h1>
      {contact?.results ? (
        <div className="col-span-4 mt-7 md:flex">
          <ContactDetail contact={contact?.results[0]} />
        </div>
      ) : null}
    </>
  );
}
