import React, { useState } from "react";
import { parse, formatRelative } from "date-fns";
import { es } from "date-fns/locale";
import Modal from "@/components/overlays/Modal";
import ContactDetail from "./ContactDetail";
import WhatsAppIcon from "../contact/img/solid-whatsapp.svg";
import SolidPhoneIcon from "../contact/img/solid-phone.svg";
import ContactMeIcon from "../contact/img/solid_contact_me.svg";
import FireIcon from "../contact/img/solid-fire.svg";
import StarIcon from "../contact/img/solid-star.svg";
import BriefcaseIcon from "../contact/img/solid-briefcase.svg";
import EyeIcon from "../contact/img/solid-eye.svg";
import DirectoryIcon from "../contact/img/directory.svg";

const date = (contact) => {
  const parsedDate = parse(contact?.created, "dd-MM-yyyy HH:m:s", new Date());
  return formatRelative(parsedDate, new Date(), {
    locale: es,
  });
};

export default function ContactList({ contacts, selected, onSelect, contact }) {
  const [showModal, toggleModal] = useState(false);
  const mobile = window.innerWidth < 770;
  return (
    <>
      <div className="space-y-1">
        {contacts?.map((contact) => (
          <div
            className={`button-secondary ${
              contact?.id === selected ? "bg-secondary-hover" : ""
            } space-y-1 border-gray-300`}
            key={contact?.id}
            onClick={() => {
              onSelect(contact);
              toggleModal(mobile);
            }}
          >
            <div className="flex justify-between">
              <p className="text-xs font-medium">
                {contact.name.length > 30
                  ? `${contact.name.substring(0, 30)}...`
                  : contact.name}
              </p>
              <div className="flex">
                {contact?.score_label >= 1 && (
                  <div className="flex items-center">
                    <div
                      className={`ml-1 flex items-center ${
                        contact?.score_label === 1
                          ? "bg-[#FCA5A5]"
                          : contact?.score_label === 2
                          ? "bg-[#FCD34D]"
                          : contact?.score_label === 3
                          ? "bg-blue-300"
                          : "bg-gray-300"
                      }`}
                    >
                      <div className="h-0 w-0 border-t-[8px] border-l-[4px] border-b-[8px] border-l-white border-t-transparent border-b-transparent"></div>
                      <div
                        className={`flex items-center justify-items-center rounded-full ${
                          contact?.score_label === 1
                            ? "bg-[#FCA5A5]"
                            : contact?.score_label === 2
                            ? "bg-[#FCD34D]"
                            : contact?.score_label === 3
                            ? "bg-blue-300"
                            : "bg-gray-300"
                        }`}
                      >
                        {contact?.score_label === 1 ? (
                          <img
                            alt="fire icon"
                            className="mx-3 w-3.5"
                            referrerPolicy="no-referrer-when-downgrade"
                            src={FireIcon}
                          />
                        ) : contact?.score_label === 2 ? (
                          <img
                            alt="star icon"
                            className="mx-3 w-3.5"
                            referrerPolicy="no-referrer-when-downgrade"
                            src={StarIcon}
                          />
                        ) : contact?.score_label === 3 ? (
                          <img
                            alt="eye icon"
                            className="mx-3 w-3.5"
                            referrerPolicy="no-referrer-when-downgrade"
                            src={EyeIcon}
                          />
                        ) : (
                          <img
                            alt="briefcase icon"
                            className="mx-3 w-3.5"
                            referrerPolicy="no-referrer-when-downgrade"
                            src={BriefcaseIcon}
                          />
                        )}
                      </div>
                    </div>
                    <div
                      className={`h-0 w-0 border-t-[8px] border-l-[4px] border-b-[8px] border-t-transparent border-b-transparent ${
                        contact?.score_label === 1
                          ? "border-l-[#FCA5A5]"
                          : contact?.score_label === 2
                          ? "border-l-[#FCD34D]"
                          : contact?.score_label === 3
                          ? "border-l-blue-300"
                          : "border-l-gray-300"
                      }`}
                    ></div>
                  </div>
                )}
                {contact?.utm_campaign === "Whatsapp_Button" && (
                  <div className="flex items-center">
                    <div className="flex w-full items-center bg-[#25D366]">
                      <div className="h-0 w-0 border-t-[8px] border-l-[4px] border-b-[8px] border-l-white border-t-transparent border-b-transparent"></div>
                      <div className="flex">
                        <img
                          alt="ícono whatsapp"
                          className="mx-3 w-3.5"
                          referrerPolicy="no-referrer-when-downgrade"
                          src={WhatsAppIcon}
                        />
                      </div>
                    </div>
                    <div className="h-0 w-0 border-t-[8px] border-l-[4px] border-b-[8px] border-l-[#25D366] border-t-transparent border-b-transparent"></div>
                  </div>
                )}
                {contact?.utm_campaign === "Call_Button" && (
                  <div className="flex items-center">
                    <div className="flex w-full items-center bg-[#8839E2]">
                      <div className=" h-0 w-0 border-t-[8px] border-l-[4px] border-b-[8px] border-l-white border-t-transparent border-b-transparent"></div>
                      <div className="flex">
                        <img
                          alt="ícono llamada"
                          className="mx-3 w-3.5"
                          referrerPolicy="no-referrer-when-downgrade"
                          src={SolidPhoneIcon}
                        />
                      </div>
                    </div>
                    <div className="h-0 w-0 border-t-[8px] border-l-[4px] border-b-[8px] border-l-[#8839E2] border-t-transparent border-b-transparent"></div>
                  </div>
                )}
                {contact?.utm_campaign === "Directory" && (
                  <div className="flex items-center">
                    <div className="flex w-full items-center bg-orange-900">
                      <div className=" h-0 w-0 border-t-[8px] border-l-[4px] border-b-[8px] border-l-white border-t-transparent border-b-transparent"></div>
                      <div className="flex">
                        <img
                          alt="ícono llamada"
                          className="mx-3 w-3.5"
                          referrerPolicy="no-referrer-when-downgrade"
                          src={DirectoryIcon}
                        />
                      </div>
                    </div>
                    <div className="h-0 w-0 border-t-[8px] border-l-[4px] border-b-[8px] border-l-orange-900 border-t-transparent border-b-transparent"></div>
                  </div>
                )}
                {contact?.utm_campaign === null && (
                  <div className="flex items-center">
                    <div className="flex w-full items-center bg-yellow-cyt">
                      <div className=" h-0 w-0 border-t-[8px] border-l-[4px] border-b-[8px] border-l-white border-t-transparent border-b-transparent"></div>
                      <div className="flex">
                        <img
                          alt="ícono contáctenme"
                          className="mx-3 w-3.5"
                          referrerPolicy="no-referrer-when-downgrade"
                          src={ContactMeIcon}
                        />
                      </div>
                    </div>
                    <div className="h-0 w-0 border-t-[8px] border-l-[4px] border-b-[8px] border-l-yellow-cyt border-t-transparent border-b-transparent"></div>
                  </div>
                )}
              </div>
            </div>
            <p className="text-sm text-gray-600">{date(contact)}</p>
            <p className="truncate text-xs text-gray-500">
              {contact?.property_title}
            </p>
          </div>
        ))}
      </div>
      <Modal open={showModal} setOpen={toggleModal} className="flex md:hidden">
        <ContactDetail contact={contact} />
      </Modal>
    </>
  );
}

ContactList.defaultProps = {
  setSelected: () => true,
};
