import apiClient from "../utils/apiClient";
import useSWR from "swr";
import queryString from "query-string";
import { atom } from "jotai";
/**
 * @typedef {Object} SWRObject
 * @property {object} data - The data returned by the swr call
 * @property {boolean} isLoading - State of the swr call
 */
/** @function
 * @name useContacts
 * @description - Obtain list of the contacts
 * @param {Object} params - initial params for each contact
 * @returns {SWRObject} swr Object
 */
export const useContacts = (params) => {
  const fetcher = (url) => apiClient.get(url).then((res) => res.data);
  const { data, error, mutate } = useSWR(
    `/list_contact/?${queryString.stringify(params)}`,
    fetcher
  );

  return { data, isLoading: !error && !data, error, mutate };
};
/** @object
 *  @name initialParams
 *  @description - default params
 */

export const initialParams = {
  page: 1,
  status: 1,
};

export const contactsParamsAtom = atom(initialParams);
/**
 * @typedef {Object} SWRObject
 * @property {object} data - The data returned by the swr call
 */

/** @function
 * @name UseAnalytics
 * @description - Returns the analytics for the params sent
 * @param {Object} params -  Events for the whatsap data
 * @returns {SWRObject} swr Object
 */
export function UseAnalytics(params) {
  const fetcher = (url) => apiClient.get(url).then((res) => res.data);

  const { data, error } = useSWR(
    `/analytics/events/?${queryString.stringify(params)}`,
    fetcher
  );

  return { data, error };
}

/** @function
 * @name updateContact
 * @description - Update the status of the contact
 * @param {Number} contactID -  id for values of the contact
 * @param {Object} data - Values of the contact status
 */

export async function updateContact(contactID, data) {
  try {
    await apiClient.patch(`contact/${contactID}`, data);
  } catch (err) {
    $log.error(err);
  }
}
