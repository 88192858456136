import React from "react";
import { Route, Routes } from "react-router-dom";
import Properties from "./properties";
import Edit from "./forms/edit";
import Create from "./forms/create";
import Expiring from "./expiring";

export default function Inventory() {
  return (
    <Routes>
      <Route path="/" element={<Properties />} />
      <Route path="edit/:propertyId" element={<Edit />} />
      <Route path="create" element={<Create />} />
      <Route path="expiring" element={<Expiring />} />
    </Routes>
  );
}
