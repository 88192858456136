import React, { useState, useEffect, useMemo } from "react";
import { useParams, useNavigate } from "react-router-dom";
import SimpleSteps from "@/components/navigation/Steps";
import GeneralDescription from "./general_description";
import LocationProperty from "./location";
import UploadMedia from "./upload_media";

function handleReload(e) {
  e.preventDefault();
  e.returnValue =
    "La información de la propiedad se perderá si sales ahora, ¿deseas abandonar la página?";
}

export default function Edit() {
  const { propertyId } = useParams();
  const [step, setStep] = useState(1);
  const navigate = useNavigate();
  useEffect(() => {
    window.addEventListener("beforeunload", handleReload);
    return () => {
      window.removeEventListener("beforeunload", handleReload);
    };
  }, []);

  const gotoLocationStep = (response) => {
    setStep(2);
  };

  const gotoMediaStep = (response) => {
    setStep(3);
  };

  const finish = (e) => {
    navigate("/properties");
  };

  function setStatus(index, currentIndex) {
    if (index <= currentIndex) {
      return "current";
    } else {
      return "upcoming";
    }
  }

  const propertyCreationSteps = useMemo(() => {
    return [
      {
        name: "Información General",
        description: "Inmobiliaria o agente independiente",
        href: "#",
        status: setStatus(1, step),
      },
      {
        name: "Ubicación",
        description: "Define para preparar tu plan",
        href: "#",
        status: setStatus(2, step),
      },
      {
        name: "Fotografías",
        description: "Contrata tu plan y accede a PRO",
        href: "#",
        status: setStatus(3, step),
      },
    ];
  }, [step]);

  const goBack = () => {
    setStep(step - 1);
  };

  return (
    <div className=" rounded bg-white ">
      <SimpleSteps steps={propertyCreationSteps} />
      {(step === 1 || step === "1") && (
        <GeneralDescription
          callBack={gotoLocationStep}
          propertyId={propertyId}
          action="edit"
        />
      )}
      {(step === 2 || step === "2") && (
        <LocationProperty
          callBack={gotoMediaStep}
          propertyId={propertyId}
          goBack={goBack}
          action="edit"
        />
      )}
      {(step === 3 || step === "3") && (
        <UploadMedia
          callBack={finish}
          propertyId={propertyId}
          goBack={goBack}
          action="edit"
        />
      )}
    </div>
  );
}
