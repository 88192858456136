import React, { useEffect, useState } from "react";
import { Formik, Field, Form, ErrorMessage, FieldArray } from "formik";
import { usePromoters } from "@/_actions/membershipActions";
import NumberFormat from "react-number-format";
import { propertyTypes, fields } from "@/constants/properties/type";
import {
  useProperty,
  updateProperty,
  createProperty,
  useMyPropertiesCount,
} from "@/_actions/propertiesActions";
import { useProfile } from "@/_actions/userActions";
import SelectBox from "@/components/forms/SelectBox";
import * as Yup from "yup";
import PropTypes from "prop-types";
import TextEditor from "@/components/forms/TextEditor";

const internals = {
  propertyTypes: propertyTypes,
  setTransactionTypes: (checked, value, values, arrayHelpers) => {
    if (checked) {
      arrayHelpers.push(value);
    } else {
      const i = values.operation_type.indexOf(value);
      if (i === 0) {
        delete values.price_sale;
      } else if (i === 1) {
        delete values.price_rent;
      } else if (i === 2) {
        delete values.price_transfer;
      }
      arrayHelpers.remove(i);
    }
  },
};
const GeneralDescriptionSchema = Yup.object().shape({
  name: Yup.string(),
  description: Yup.string().required("Agrega una descripción"),
  membership: Yup.number().integer().required("Selecciona un promotor"),
  property_type: Yup.number().required("Selecciona un tipo de propiedad"),
  operation_type: Yup.array().min(
    1,
    "Selecciona al menos un tipo de transacción"
  ),
  parking: Yup.number("Debe ser un número"),
  age: Yup.number("Debe ser un número"),
  sqr_mt_lot: Yup.string().when("property_type", (property_type) => {
    if ([34, 15].indexOf(property_type) > -1)
      return Yup.number().required(
        "Es necesario ingresar los metros cuadrados del terreno"
      );
  }),
  sqr_mt_construction: Yup.string().when("property_type", (property_type) => {
    if (property_type === 15)
      return Yup.number().required(
        "Es necesario ingresar los metros cuadrados de construcción"
      );
  }),
  bathrooms: Yup.string().when("property_type", (property_type) => {
    if ([18, 19, 20, 33].indexOf(property_type) > -1)
      return Yup.number().required("Es necesario ingresar el número de baños");
  }),
  rooms: Yup.string().when("property_type", (property_type) => {
    if ([18, 19, 20, 26, 28, 33].indexOf(property_type) > -1)
      return Yup.number().required(
        "Es necesario ingresar el número de habitaciones"
      );
  }),
  half_bathrooms: Yup.number("Debe ser un número"),
  levels: Yup.number("Debe ser un número"),
  floors: Yup.number("Debe ser un número"),
  floor_number: Yup.string().when("property_type", (property_type) => {
    if (property_type == 19)
      return Yup.number().required(
        "Es necesario ingresar el número de piso en que se encuentra"
      );
  }),
  sqr_mt_office: Yup.number("Debe ser un número"),
  sqr_mt_cellar: Yup.number("Debe ser un número"),
  sqr_mt_front: Yup.number("Debe ser un número"),
  sqr_mt_long: Yup.number("Debe ser un número"),
  price_sale: Yup.string().when("operation_type", (operation_type, schema) => {
    return operation_type.includes(1)
      ? Yup.string()
          .required("Es necesario ingresar un precio")
          .test("len", "El precio debe tener al menos 3 dígitos", (val) =>
            validateLength(val)
          )
      : schema;
  }),
  price_rent: Yup.string().when("operation_type", (operation_type, schema) => {
    return operation_type.includes(2)
      ? Yup.string()
          .required("Es necesario ingresar un precio")
          .test("len", "El precio debe tener al menos 3 dígitos", (val) =>
            validateLength(val)
          )
      : schema;
  }),
  price_transfer: Yup.string().when(
    "operation_type",
    (operation_type, schema) => {
      return operation_type.includes(3)
        ? Yup.string()
            .required("Es necesario ingresar un precio")
            .test("len", "El precio debe tener al menos 3 dígitos", (val) =>
              validateLength(val)
            )
        : schema;
    }
  ),
  currency_sale: Yup.string(),
  currency_rent: Yup.string(),
  currency_transfer: Yup.string(),
});

function validateLength(val) {
  return val != undefined ? val.length >= 3 : false;
}

function removeEmpty(obj) {
  return Object.entries(obj)
    .filter(([, v]) => v !== "" && v !== null)
    .reduce((acc, [k, v]) => ({ ...acc, [k]: v }), {});
}

export default function GeneralDescription({ callBack, propertyId, action }) {
  const { data } = useProperty(propertyId);
  const { data: promoters } = usePromoters({ status: 1, size: 100 });
  const { data: user } = useProfile();

  const activeMembership = user?.memberships[0]?.id;
  const { data: allPropertiesCount } = useMyPropertiesCount();
  const [operationTypes, setOperationTypes] = useState([
    { name: "Venta", value: 1 },
    { name: "Renta", value: 2 },
    { name: "Traspaso", value: 3 },
  ]);

  useEffect(() => {
    const defaultOperationTypes = [
      { name: "Venta", value: 1 },
      { name: "Renta", value: 2 },
      { name: "Traspaso", value: 3 },
    ];
    const onlyRent = [{ name: "Renta", value: 2 }];
    if (
      allPropertiesCount?.properties_plan ===
        allPropertiesCount?.rest_of_properties &&
      allPropertiesCount?.rent_properties < allPropertiesCount?.plan_free_rents
    ) {
      setOperationTypes(onlyRent);
    } else {
      if (
        JSON.stringify(operationTypes) != JSON.stringify(defaultOperationTypes)
      ) {
        setOperationTypes(defaultOperationTypes);
      }
    }
    if (action === "edit") {
      const { operation_type } = data || {};
      if (
        (operation_type?.length === 1 &&
          ["Venta", "Traspaso"].includes(operation_type[0]?.name)) ||
        operation_type?.length >= 2
      ) {
        setOperationTypes(defaultOperationTypes);
      } else {
        if (
          allPropertiesCount?.properties_plan >
            allPropertiesCount?.rest_of_properties ||
          allPropertiesCount?.properties_plan == -1
        ) {
          setOperationTypes(defaultOperationTypes);
        } else {
          setOperationTypes(onlyRent);
        }
      }
    }
  }, [allPropertiesCount, data, action]);

  function onValueChange(e, obj, setFieldValue) {
    if (obj) {
      obj.parking = 0;
      obj.age = 0;
      obj.sqr_mt_lot = 0.0;
      obj.sqr_mt_construction = 0.0;
      obj.bathrooms = 0;
      obj.rooms = 0;
      obj.half_bathrooms = 0;
      obj.levels = 0;
      obj.floors = 0;
      obj.floor_number = 0;
      obj.sqr_mt_office = 0.0;
      obj.sqr_mt_cellar = 0.0;
      obj.sqr_mt_front = 0.0;
      obj.sqr_mt_long = 0.0;
      obj.sqr_mt_terrace = 0.0;
      obj.sqr_mt_vault = 0.0;
    }
    setFieldValue("property_type", e.target.value);
  }

  async function onSubmit(values) {
    const cleanValues = removeEmpty(values);
    try {
      let response;
      if (propertyId) {
        response = await updateProperty(propertyId, { ...cleanValues });
      } else {
        response = await createProperty({ ...cleanValues });
      }
      callBack(response);
    } catch (err) {
      $log.error(err);
    }
  }

  const cleanProperty = (propertyData) => {
    const newObject = {
      ...propertyData,
      operation_type: propertyData?.operation_type?.map((o) => o.id),
      property_type: propertyData?.property_type?.id,
      colony: propertyData?.colony?.id,
      municipality: propertyData?.municipality?.id,
      state: propertyData?.state?.id,
    };
    return newObject;
  };

  let propertyData = data ? cleanProperty(data) : null;

  return (
    <Formik
      onSubmit={onSubmit}
      validationSchema={GeneralDescriptionSchema}
      enableReinitialize={true}
      initialValues={
        propertyData
          ? { ...propertyData }
          : {
              bathrooms: "",
              rooms: "",
              floor_number: "",
              sqr_mt_lot: "",
              sqr_mt_construction: "",
              name: "",
              description: "",
              price_sale: "",
              price_rent: "",
              price_transfer: "",
              property_type: 19,
              operation_type: [],
              membership: activeMembership,
              currency_sale: "mxn",
              currency_rent: "mxn",
              currency_transfer: "mxn",
            }
      }
    >
      {({ values, setFieldValue, isSubmitting }) => (
        <Form
          className="mt-10 w-full space-y-6"
          data-splitbee-event={`properties-${action}-general`}
        >
          <div>
            <label htmlFor="name" className="form-label">
              Título de la propiedad<span className="text-red-600">*</span>
            </label>
            <Field
              className="input"
              as="input"
              name="name"
              placeholder="Departamento en venta en Chapalita con alberca ó Departamento en renta amueblado en la Estancia"
            ></Field>
            <ErrorMessage
              name="name"
              render={(msg) => <div className="input-error">{msg}</div>}
            />
          </div>

          <div>
            <label htmlFor="description" className="form-label">
              Descripción <span className="text-red-600">*</span>
            </label>
            <Field
              className="input"
              as={TextEditor}
              name="description"
              value={values?.description}
              setFieldValue={setFieldValue}
            ></Field>
            <ErrorMessage
              name="description"
              render={(msg) => <div className="input-error">{msg}</div>}
            />
          </div>

          <div className="grid-cols-2 gap-4 md:grid">
            <div>
              <label htmlFor="membership" className="form-label">
                Promotor <span className="text-red-600">*</span>
              </label>
              {promoters && promoters.length && (
                <Field
                  name="membership"
                  as="select"
                  className="select"
                  data-testid="comboboxPromoter"
                >
                  {promoters.map((promoter) => (
                    <option key={promoter.id} value={promoter.id}>
                      {promoter.first_name} {promoter.last_name}
                    </option>
                  ))}
                </Field>
              )}
              <ErrorMessage
                name="membership"
                render={(msg) => <div className="input-error">{msg}</div>}
              />
            </div>
            <div>
              <label htmlFor="promoter" className="form-label">
                Tipo de propiedad <span className="text-red-600">*</span>
              </label>
              <Field
                as="select"
                name="property_type"
                className="input"
                onChange={(e) => {
                  onValueChange(e, values, setFieldValue);
                  propertyData = values;
                }}
                data-testid="comboboxPropertyType"
              >
                {propertyTypes.map((property) => (
                  <option
                    className="capitalize"
                    key={property.id}
                    value={parseInt(property.id, 10)}
                  >
                    {property.name}
                  </option>
                ))}
              </Field>
              <ErrorMessage
                name="property_type"
                render={(msg) => <div className="input-error">{msg}</div>}
              />
            </div>
          </div>

          <div>
            <label htmlFor="operation_type" className="form-label">
              Tipo(s) de transacción <span className="text-red-600">*</span>
            </label>
            <FieldArray
              name="operation_type"
              render={(arrayHelpers) => (
                <div className="w-full gap-2 space-y-3 md:grid md:grid-cols-3 md:space-y-0">
                  {operationTypes.map((transaction, index) => (
                    <SelectBox
                      key={index}
                      option={transaction}
                      fieldValue={values.operation_type}
                      callBack={(checked, value) =>
                        internals.setTransactionTypes(
                          checked,
                          value,
                          values,
                          arrayHelpers
                        )
                      }
                    />
                  ))}
                </div>
              )}
            />
            <ErrorMessage
              name="operation_type"
              render={(msg) => <div className="input-error">{msg}</div>}
            />
            <div className="space-y-3">
              {values?.operation_type?.includes(1) && (
                <div className="mt-3">
                  <label
                    htmlFor="price_sale"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Precio Venta <span className="text-red-600">*</span>
                  </label>
                  <div className="relative mt-1 rounded-md shadow-sm">
                    <div className="absolute inset-y-0 left-0 z-10 flex items-center">
                      <label htmlFor="currency_sale" className="sr-only">
                        Currency
                      </label>
                      <Field
                        as="select"
                        name="currency_sale"
                        className="h-full rounded-md border-transparent bg-transparent py-0 pl-2 pr-7 text-gray-500 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                      >
                        <option value="mxn">MXN</option>
                        <option value="usd">USD</option>
                      </Field>
                    </div>
                    <NumberFormat
                      prefix={"$"}
                      placeholder="0.00"
                      isNumericString={true}
                      thousandSeparator={true}
                      value={values.price_sale}
                      data-testid="priceSell"
                      onValueChange={(val) =>
                        setFieldValue("price_sale", val.floatValue)
                      }
                      className="relative block w-full rounded-md border-gray-300 pl-16 pr-12  focus:border-indigo-500 focus:ring-indigo-500  sm:text-sm md:text-lg"
                    />
                  </div>
                  <ErrorMessage
                    name="price_sale"
                    render={(msg) => <div className="input-error">{msg}</div>}
                  />
                </div>
              )}
              {values?.operation_type?.includes(2) && (
                <div className="mt-3">
                  <label
                    htmlFor="price_rent"
                    className="block text-sm font-medium text-gray-700 "
                  >
                    Precio Renta <span className="text-red-600">*</span>
                  </label>
                  <div className="relative mt-1 rounded-md shadow-sm">
                    <div className="absolute inset-y-0 left-0 z-10 flex items-center">
                      <label htmlFor="currency_rent" className="sr-only">
                        Currency
                      </label>
                      <Field
                        as="select"
                        name="currency_rent"
                        className="h-full rounded-md border-transparent bg-transparent py-0 pl-2 pr-7 text-gray-500 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                      >
                        <option value="mxn">MXN</option>
                        <option value="usd">USD</option>
                      </Field>
                    </div>
                    <NumberFormat
                      prefix={"$"}
                      placeholder="0.00"
                      isNumericString={true}
                      thousandSeparator={true}
                      value={values.price_rent}
                      data-testid="priceRent"
                      onValueChange={(val) =>
                        setFieldValue("price_rent", val.floatValue)
                      }
                      className="relative block w-full rounded-md border-gray-300 pl-16 pr-12  focus:border-indigo-500 focus:ring-indigo-500  sm:text-sm md:text-lg"
                    />
                  </div>
                  <ErrorMessage
                    name="price_rent"
                    render={(msg) => <div className="input-error">{msg}</div>}
                  />
                </div>
              )}
              {values?.operation_type?.includes(3) && (
                <div className="mt-3">
                  <label
                    htmlFor="price_transfer"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Precio Traspaso <span className="text-red-600">*</span>
                  </label>
                  <div className="relative mt-1 rounded-md shadow-sm">
                    <div className="absolute inset-y-0 left-0 z-10 flex items-center">
                      <label htmlFor="currency_transfer" className="sr-only">
                        Currency
                      </label>
                      <Field
                        as="select"
                        name="currency_transfer"
                        className="h-full rounded-md border-transparent bg-transparent py-0 pl-2 pr-7 text-gray-500 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                      >
                        <option value="mxn">MXN</option>
                        <option value="usd">USD</option>
                      </Field>
                    </div>
                    <NumberFormat
                      prefix={"$"}
                      placeholder="0.00"
                      isNumericString={true}
                      thousandSeparator={true}
                      value={values.price_transfer}
                      onValueChange={(val) =>
                        setFieldValue("price_transfer", val.floatValue)
                      }
                      className="relative block w-full rounded-md border-gray-300 pl-16 pr-12  focus:border-indigo-500 focus:ring-indigo-500  sm:text-sm md:text-lg"
                    />
                  </div>
                  <ErrorMessage
                    name="price_transfer"
                    render={(msg) => <div className="input-error">{msg}</div>}
                  />
                </div>
              )}
            </div>
          </div>

          <div className="grid-cols-5 gap-4 md:grid">
            {values.property_type &&
              propertyTypes
                .find((property) => property.id == values.property_type)
                .fields.map((field, id) => (
                  <div key={id}>
                    <label htmlFor={field} className="form-label">
                      {fields[field]}
                      {propertyTypes
                        .find((property) => property.id == values.property_type)
                        .requiredFields.includes(field) ? (
                        <span className="text-red-600">*</span>
                      ) : null}
                    </label>
                    <Field
                      as="input"
                      type="number"
                      min="0"
                      className="input"
                      name={field}
                      data-testid={field}
                    ></Field>
                    <ErrorMessage
                      name={field}
                      render={(msg) => <div className="input-error">{msg}</div>}
                    />
                  </div>
                ))}
          </div>

          <button
            disabled={isSubmitting}
            type="submit"
            data-testid="continue"
            className="group relative flex w-full justify-center rounded-md border border-transparent bg-blue-800 py-2 px-4 text-lg font-medium text-white hover:bg-blue-900 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
          >
            Continuar
          </button>
        </Form>
      )}
    </Formik>
  );
}

GeneralDescription.propTypes = {
  callBack: PropTypes.func,
  propertyId: PropTypes.string,
  action: PropTypes.string,
};
