import React, { useEffect, useState } from "react";
import Modal from "../../../components/overlays/Modal";
import RoundButton from "../../../components/forms/RoundButton";
import acquisitionClient from "@/services/acquisitionApiClient";
import { CheckIcon, XIcon } from "@heroicons/react/solid";
import WhatsAppIcon from "../../contact/img/whatsapp.svg";
import "./Loading.css";

export default function Return() {
  const urlParams = new URLSearchParams(window.location.search);
  const sessionId = urlParams.get("session_id");
  const [userEmail, setUserEmail] = useState("");
  const [loading, setLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");
  const [error, setError] = useState(false);
  const [showContact, setShowContact] = useState(false);

  useEffect(() => {
    const completeSignUp = async () => {
      try {
        const response = await acquisitionClient.post(
          "/customers/complete-sign-up/",
          {
            session_id: sessionId,
          }
        );
        if (response.status === 201) {
          setUserEmail(response.data.user_email);
          setError(false);
          setLoading(false);
          localStorage.removeItem("account");
        }
      } catch (err) {
        if (err.response.status === 409) {
          setError(true);
          setErrorMessage("La sesión ya termino.");
          setLoading(false);
        } else if (err.response.status === 403) {
          setError(true);
          setErrorMessage("Cuenta no verificada.");
          setLoading(false);
        } else if (err.response.status === 404) {
          setError(true);
          setErrorMessage("Sesión no encontrada.");
          setLoading(false);
        } else if (err.response.status === 402) {
          setError(true);
          setErrorMessage("La sesión no ha sido pagada.");
          setLoading(false);
        } else {
          setError(true);
          setErrorMessage("Ocurrió un error.");
          setLoading(false);
          setShowContact(true);
        }
      }
    };
    completeSignUp();
  }, [sessionId]);

  return (
    <div className="flex w-full items-center justify-center">
      <div className="flex w-full max-w-[1440px] flex-col items-center justify-center">
        {loading ? (
          <Modal open={true}>
            <div className="flex flex-col items-center p-6">
              <div className="flex h-fit items-center justify-center">
                <div className="loading">
                  <div className="dot"></div>
                  <div className="dot"></div>
                  <div className="dot"></div>
                </div>
              </div>
            </div>
          </Modal>
        ) : !error ? (
          <Modal open={true}>
            <div className="flex flex-col items-center p-6">
              <div className=" mb-4 w-fit rounded-full bg-green-500 p-3">
                <CheckIcon className="w-14 text-white" />
              </div>
              <div className="mb-5 flex flex-col items-center  justify-center text-center text-2xl">
                <h1 className="mb-2 font-bold">¡Listo!</h1>
                <span className="text-xl">Ya puedes iniciar sesión</span>
                <span className="text-xl">con tu correo: </span>
                <span className="mt-2 text-xl font-bold">{userEmail}</span>
              </div>
              <div className="w-full px-10">
                <a href="/login">
                  <RoundButton
                    id="Publica-IniciarSesion"
                    text="Iniciar sessión"
                    active={true}
                  ></RoundButton>
                </a>
              </div>
            </div>
          </Modal>
        ) : (
          <Modal open={true}>
            <div className="flex flex-col items-center p-6">
              <div className=" mb-4 w-fit rounded-full bg-red-500 p-3">
                <XIcon className="w-14 text-white" />
              </div>
              <div className="mb-5 flex flex-col items-center  justify-center text-center text-xl">
                <h1 className="mb-2 font-bold">{errorMessage}</h1>
                {showContact && (
                  <span className="text-lg">
                    Contacta a uno de nuestros asesores
                  </span>
                )}
              </div>
              {showContact && (
                <div className="w-full px-10">
                  <div className="flex w-full cursor-pointer items-center justify-center rounded-full border-2 border-green-whatsapp bg-green-whatsapp text-sm text-white hover:border-white hover:bg-green-whatsapp-darker active:border-green-whatsapp lg:text-base">
                    <a
                      href={`https://api.whatsapp.com/send?phone=+523319100955&text=Hola! ocurrió un error al terminar de crear mi cuenta.`}
                      className="flex h-full w-full items-center justify-center rounded-full border-2 border-green-whatsapp py-2 active:border-white"
                    >
                      <img src={WhatsAppIcon} className="mr-2 w-5" />
                      <span>Contactar con un asesor</span>
                    </a>
                  </div>
                </div>
              )}
            </div>
          </Modal>
        )}
      </div>
    </div>
  );
}
