import React, { useCallback, useState } from "react";
import {
  EmbeddedCheckoutProvider,
  EmbeddedCheckout,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import acquisitionClient from "@/services/acquisitionApiClient";
import Modal from "@/components/overlays/Modal";

export default function CheckoutForm() {
  const account = JSON.parse(localStorage.getItem("account"));
  const stripePromise = loadStripe(
    import.meta.env.VITE_API_STRIPE_TOKEN ||
      "pk_test_8hM4I5gZ0ocpgnvtOrgROi1J00kqrCPrP6"
  );
  const [showError, setShowError] = useState(false);

  const fetchClientSecret = useCallback(() => {
    return acquisitionClient
      .post("/api/v1/products/checkout/acquisition/", {
        plan: account.plan,
        user_id: account.user.user_id,
        user_email: account.values.email,
      })
      .then((res) => {
        return res.data.clientSecret;
      })
      .catch((error) => {
        if (error?.response?.status === 403) {
          setShowError(true);
        }
      });
  }, [account]);

  const options = { fetchClientSecret };

  return (
    <>
      <div id="Publica-FinalizarPago">
        <EmbeddedCheckoutProvider stripe={stripePromise} options={options}>
          <EmbeddedCheckout />
        </EmbeddedCheckoutProvider>
      </div>
      <Modal open={showError}>
        <div className="p-6 text-center">
          <span>Cuenta no verificada</span>
        </div>
      </Modal>
    </>
  );
}
