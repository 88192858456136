import React, { useEffect, useState } from "react";
import { ArrowLeftIcon, CheckIcon } from "@heroicons/react/solid";
import DottedLine from "../../signup/img/div.svg";
import Plans from "./Plans";
import CheckoutForm from "./checkout";
import LogoPro from "/src/pages/login/img/logo-blue.svg";

export default function Confirmation() {
  const goBack = () => {
    window.history.back();
  };

  return (
    <div className="flex w-full items-center justify-center">
      <div className="flex w-full max-w-[1440px] flex-col items-center justify-center bg-white">
        <div className="flex w-full p-6">
          <img className="w-[180px]" src={LogoPro} />
        </div>
        <a
          id="Publica-FinalizarPago-Volver"
          className="flex w-full items-center p-6"
          href="#"
          onClick={goBack}
        >
          <ArrowLeftIcon className="mr-2 w-4 md:h-5 md:w-5" />
          <span className="text-lg">Volver</span>
        </a>
        <div>
          <h1 className="mb-6 text-center text-2xl md:text-4xl lg:text-5xl">
            Comienza a recibir clientes
          </h1>
          <div className="mx-4 flex space-x-4">
            <div className="flex items-center">
              <CheckIcon className="mr-1.5 w-4 rounded-full bg-yellow-cyt-alt p-0.5 text-white md:h-5 md:w-5" />
              <h2 className="text-[10px] md:text-sm lg:text-xl">
                Elige tu paquete
              </h2>
            </div>
            <img className=" hidden w-[50px] lg:flex" src={DottedLine}></img>
            <div className="flex items-center">
              <CheckIcon className="mr-1.5 w-4 rounded-full bg-yellow-cyt-alt p-0.5 text-white md:h-5 md:w-5" />
              <h2 className="text-[10px] md:text-sm lg:text-xl">
                Crea tu cuenta
              </h2>
            </div>
            <img className="hidden w-[50px] lg:flex" src={DottedLine}></img>
            <div className="flex items-center">
              <CheckIcon className="mr-1.5 w-4 rounded-full bg-yellow-cyt-alt p-0.5 text-white md:h-5 md:w-5" />
              <h2 className="text-[10px] md:text-sm lg:text-xl">
                Verifica tu cuenta
              </h2>
            </div>
            <img className="hidden w-[50px] lg:flex" src={DottedLine}></img>
            <div className="flex items-center">
              <span className="mr-1.5 flex h-5 w-5 items-center justify-center rounded-full bg-primary-default text-xs text-white md:h-6 md:w-6 md:text-base">
                4
              </span>
              <h2 className="text-xs font-bold text-primary-default	md:text-lg lg:text-2xl">
                Finaliza tu pago
              </h2>
            </div>
          </div>
        </div>
        <div className="mb-10 flex w-full items-center justify-center px-4 md:px-0">
          <div className="relative mt-6 mb-2 w-full max-w-[1250px] rounded-3xl border border-neutral-300 p-2 md:w-fit md:p-6">
            <div className="flex flex-col justify-center md:flex-row md:space-x-10">
              <div className=" flex items-center justify-center md:w-[390px]">
                <div className=" flex w-full items-center justify-center">
                  <div className="mx-6 mb-10 rounded-3xl bg-white shadow-lg">
                    <h1 className="text-xl font-bold text-primary-default">
                      Tu membresía
                    </h1>
                    <Plans />
                  </div>
                </div>
              </div>
              <div className="w-full">
                <CheckoutForm />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
