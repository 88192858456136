import React, { useEffect } from "react";

import { Dashboard } from "@uppy/react";

import "@uppy/core/dist/style.css";
import "@uppy/drag-drop/dist/style.css";
import "@uppy/dashboard/dist/style.css";

function Upload({ uppyConfiguration, removeId, ...rest }) {
  useEffect(() => {
    if (removeId && uppyConfiguration) {
      uppyConfiguration.remove(removeId);
    }
  }, [removeId, uppyConfiguration]);

  return (
    <Dashboard
      {...rest}
      inline={true}
      width="100%"
      uppy={uppyConfiguration}
      proudlyDisplayPoweredByUppy={false}
      locale={{
        strings: {
          dropHereOr: "Arrastra aquí o %{browse}",
          browse: "explora tus archivos",
        },
      }}
    />
  );
}

export default React.memo(Upload, (x, y) => false);
