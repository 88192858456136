import * as Sentry from "@sentry/react";
const env = import.meta.env.MODE;

const log = {
  error: (error) => {
    try {
      if (env !== "production") {
        console.error(error);
      } else {
        Sentry.captureException(error);
      }
    } catch (err) {
      Sentry.captureException(err);
    }
  },
  message: (text) => {
    try {
      if (env !== "production") {
        console.log(text);
      } else {
        Sentry.captureMessage(text);
      }
    } catch (err) {
      Sentry.captureException(err);
    }
  },
};

window.$log = log;
