import React, { useEffect, useState } from "react";
import NumberFormat from "react-number-format";
import { Formik } from "formik";
import * as Yup from "yup";
import "yup-phone";
import { updateBusiness } from "@/_actions/businessActions";
import {
  useStates,
  useMunicipalities,
  useNeighborhoods,
} from "@/_actions/regionActions";

export default function BusinessInformation(props) {
  const realEstateSchema = Yup.object().shape({
    business_name: Yup.string().min(3).required("El nombre es requerido"),
    phone_number: Yup.string()
      .phone("MX", true, "Debe ser un número de teléfono válido")
      .required("El teléfono es requerido"),
    num_exterior: Yup.string(),
    state: Yup.string().required("El Estado es requerido"),
    municipality: Yup.string().required("El Municipio es requerido"),
    neighborhood: Yup.string(),
  });
  const { data, callBack, firstLogin = true, cancel } = props;
  const [address] = data?.address.filter((x) => x.type_address);
  const onSubmit = async (values) => {
    try {
      const newVal = {
        business_name: values.business_name,
        phone_number: values.phone_number,
        address: [
          {
            state: values.state,
            municipality: values.municipality,
            ...(values.neighborhood && { neighborhood: values.neighborhood }),
            street: values.street,
            num_exterior: values.num_exterior,
            num_interior: values.num_interior,
            ...(address?.id && { id: address?.id }),
          },
        ],
        type_address: 2,
      };
      await updateBusiness(data?.id, newVal);
      callBack && callBack(newVal);
    } catch (err) {
      $log.error(err);
    }
  };

  return (
    <div>
      <Formik
        initialValues={{
          business_name: data?.business_name || "",
          phone_number: data?.phone_number || "",
          street: address?.street || "",
          num_exterior: address?.num_exterior || "",
          num_interior: address?.num_interior || "",
          state: address?.state?.id || "",
          municipality: address?.municipality?.id || "",
          neighborhood: address?.neighborhood?.id || 0,
        }}
        validationSchema={realEstateSchema}
        onSubmit={onSubmit}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleSubmit,
          isSubmitting,
          setFieldValue,
        }) => (
          <form
            onSubmit={handleSubmit}
            data-splitbee-event="account-edit-contact-save"
          >
            {errors.email && touched.email && errors.email}

            <div className="m-2">
              <label
                htmlFor="business_name"
                className={"block text-sm font-medium text-gray-700"}
              >
                Nombre inmobiliaria
                {firstLogin && <span className=" text-red-500">*</span>}
              </label>
              <input
                id="business_name"
                name="business_name"
                type="text"
                value={values.business_name}
                onChange={handleChange}
                className="input"
              />
            </div>
            {errors.business_name && (
              <div className="ml-2 text-sm text-red-600">
                {errors.business_name}
              </div>
            )}

            <div className="m-2 ">
              <label
                htmlFor="phone_number"
                className="block text-sm font-medium text-gray-700"
              >
                Teléfono
              </label>
              <NumberFormat
                id="phone_number"
                name="phone_number"
                value={values.phone_number}
                format="## #### ####"
                onValueChange={({ value }) => {
                  setFieldValue("phone_number", value);
                }}
                type="text"
                className="input"
              />
            </div>
            {errors.phone_number && (
              <div className="ml-2 text-sm text-red-600">
                {errors.phone_number}
              </div>
            )}
            <div className="m-2">
              <label
                htmlFor="street"
                className="block text-sm font-medium text-gray-700"
              >
                Calle
              </label>
              <input
                id="street"
                name="street"
                type="text"
                value={values.street}
                onChange={handleChange}
                className="input"
              />
            </div>

            <div className="m-2 grid grid-cols-2 space-x-2">
              <div>
                <label
                  htmlFor="num_exterior"
                  className="block text-sm font-medium text-gray-700"
                >
                  Número Exterior
                </label>
                <NumberFormat
                  id="num_exterior"
                  name="num_exterior"
                  value={values.num_exterior}
                  onChange={handleChange}
                  placeholder="123"
                  type="text"
                  className="input"
                />
              </div>
              <div>
                <label
                  htmlFor="num_interior"
                  className="block text-sm font-medium text-gray-700"
                >
                  Número Interior
                </label>
                <NumberFormat
                  id="num_interior"
                  name="num_interior"
                  value={values.num_interior}
                  onChange={handleChange}
                  placeholder="123"
                  type="text"
                  className="input"
                />
              </div>
            </div>
            <div className="m-2">
              <div className="flex">
                <span>Estado</span>
                {firstLogin && <span className="text-red-500">*</span>}
              </div>
              <Select
                id="state"
                useOptions={useStates}
                callback={(e) => setFieldValue("state", e)}
                defaultValue={values.state}
                name="state"
              />
            </div>
            {errors.state && (
              <div className="ml-2 text-sm text-red-600">{errors.state}</div>
            )}

            <div className="m-2">
              <span>Municipio</span>
              {firstLogin && <span className="text-red-500">*</span>}
              <Select
                id="municipality"
                useOptions={() => useMunicipalities({ state_id: values.state })}
                callback={(e) => setFieldValue("municipality", e)}
                useOptionsValue={values.state}
                defaultValue={values.municipality}
                name="municipality"
              />
            </div>
            {errors.municipality && (
              <div className="ml-2 text-sm text-red-600">
                {errors.municipality}
              </div>
            )}

            <div className="m-2">
              <Select
                id="neighborhood"
                useOptions={() =>
                  useNeighborhoods({ municipality: values.municipality })
                }
                callback={(e) => setFieldValue("neighborhood", e)}
                useOptionsValue={values.municipality}
                defaultValue={values.neighborhood}
                name="neighborhood"
                label="Colonia"
              />
            </div>

            <div className="m-2 mt-3 space-x-3">
              <button
                type="button"
                className="button-secondary"
                onClick={cancel}
              >
                Cancelar
              </button>
              <button
                type="submit"
                disabled={isSubmitting}
                className="button-primary"
              >
                Guardar
              </button>
            </div>

            <div className="mx-2">
              {errors.password && touched.password && errors.password}
            </div>
          </form>
        )}
      </Formik>
    </div>
  );
}

function Select(props) {
  const {
    useOptions,
    callback,
    label,
    name,
    defaultValue,
    useOptionsValue = undefined,
    ...rest
  } = props;

  const { data = [] } = useOptions(useOptionsValue);

  return (
    <>
      <label htmlFor={name} className="block text-sm font-medium text-gray-700">
        {label}
      </label>
      {data.length ? (
        <select
          name={name}
          onChange={({ target }) => callback(target.value)}
          className="input"
          {...rest}
        >
          {data.map((x) => (
            <option key={x.id} value={x.id} selected={x.id === defaultValue}>
              {x.name}
            </option>
          ))}
        </select>
      ) : (
        <input
          name={name}
          type="text"
          onChange={({ target }) => callback(target.value)}
          className="input"
        />
      )}
    </>
  );
}

BusinessInformation.defaultProps = {
  callBack: null,
};
