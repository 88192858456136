import React from "react";
import CustomButton from "@/components/forms/CustomButton";
import Hero from "@/components/elements/Hero";
import Hands from "@/img/hands.png";
import Catalogo from "@/img/catalogo.png";
import Training from "@/img/training.png";
import Materials from "@/img/materials.png";
import Assist from "@/img/assist.png";
import Advisory from "@/img/advisory.png";
import RoundButton from "@/components/forms/RoundButton.jsx";
import ButtonClick from "@/img/button-click.svg";
import TrainingLaptop from "@/img/training-laptop.svg";
import DevelopmentIcon from "@/img/development-icon.svg";
import ShoppingHouse from "@/img/shopping-house.svg";
import HandsWithMoney from "@/img/hand-with-money.svg";
import FAQ from "@/components/elements/FAQ";
import PropTypes from "prop-types";

export default function ProListings() {
  const benefits = [
    {
      text: "Lista de Pre-Ventas y Comisiones especiales",
      bgColor: "bg-orange-100",
      image: Catalogo,
    },
    {
      text: "Capacitaciones para ti y tu equipo",
      bgColor: "bg-blue-100",
      image: Training,
    },
    {
      text: "Material para tus promocionales",
      bgColor: "bg-orange-100",
      image: Materials,
    },
    {
      text: "Acompañamiento en el proceso de venta",
      bgColor: "bg-blue-100",
      image: Hands,
    },
    {
      text: "Asesoría en el perfilamiento de clientes",
      bgColor: "bg-orange-100",
      image: Advisory,
    },
    {
      text: "Asistencia en el Crédito ideal para tu Cliente",
      bgColor: "bg-blue-100",
      image: Assist,
    },
  ];
  const steps = [
    {
      text: "Accede y revisa las reglas del juego",
      imgSrc: ButtonClick,
      imgAlt: "Button-click",
    },
    {
      text: "Te agendamos para conocer PRO Listings",
      imgSrc: TrainingLaptop,
      imgAlt: "training-laptop",
    },
    {
      text: "Elige tus Pre-ventas y capacítate en ellas",
      imgSrc: DevelopmentIcon,
      imgAlt: "development-icon",
    },
    {
      text: "Promociona y agenda tus visitas",
      imgSrc: ShoppingHouse,
      imgAlt: "shopping-house",
    },
    {
      text: "Cierra la venta y recibe tus comisiones",
      imgSrc: HandsWithMoney,
      imgAlt: "Hand-with-money",
    },
  ];

  const BenefitItem = ({ text, bgColor, image }) => (
    <div className="flex flex-col items-center justify-center md:max-w-[161px] lg:max-w-[238px]">
      <span className="mb-2 text-center text-sm lg:text-lg">{text}</span>
      <div
        className={`flex h-[160px] w-[160px] items-center rounded-3xl ${bgColor} mb-4 lg:h-[189px] lg:w-[189px]`}
      >
        <img className="h-full w-full rounded-3xl" src={image} alt={text} />
      </div>
    </div>
  );
  BenefitItem.propTypes = {
    text: PropTypes.string.isRequired,
    bgColor: PropTypes.string.isRequired,
    image: PropTypes.string.isRequired,
  };

  const StepItem = ({ number, text, imgSrc, imgAlt }) => (
    <div className="my-8 flex max-h-[265px] w-fit min-w-[159px] max-w-[200px] flex-col items-center justify-between">
      <div className="mb-3 flex h-[44px] w-[44px] items-center justify-center rounded-full bg-yellow-cyt text-2xl font-bold text-white">
        {number}
      </div>
      <span className="mb-4 text-center">{text}</span>
      <img className="h-[108px]" src={imgSrc} alt={imgAlt} />
    </div>
  );
  StepItem.propTypes = {
    number: PropTypes.number.isRequired,
    text: PropTypes.string.isRequired,
    imgSrc: PropTypes.string.isRequired,
    imgAlt: PropTypes.string.isRequired,
  };

  return (
    <div className="flex w-full flex-col items-center justify-center bg-white">
      <div className="sticky top-0 flex w-full justify-between bg-white py-5 px-4 md:mb-6 md:px-6 lg:mb-11 lg:px-20">
        <div className="flex text-[28px] text-yellow-cyt ">
          <span className="mr-1.5 font-bold text-blue-cyt">PRO</span> Listings
        </div>
        <div>
          <CustomButton
            id="Prolistings-Navbar-CTA"
            active={true}
            text="Quiero Probarlo"
            activeClasses="flex w-full items-center justify-center rounded-full border-2 text-sm text-primary-default lg:text-base cursor-pointer border-yellow-cyt bg-yellow-cyt hover:border-white active:border-yellow-cyt"
            inactiveClasses="hover:bg-[#ffcf86]"
            buttonActiveClasses="border-yellow-cyt active:border-white hover:bg-[#ffcf86] hover:border-yellow-cyt px-4"
            buttonInactiveClasses="border-[#ffcf86]"
          />
        </div>
      </div>
      <div className="w-full px-4 md:px-6 lg:px-20">
        <Hero />
      </div>
      <div className="mb-28 flex w-full justify-center bg-blue-cyt px-4 text-center text-white md:text-sm lg:text-lg">
        <div className="my-8 max-w-[700px] md:mx-6 lg:mx-0">
          Lo único que necesitas es tener tu
          <span className="font-bold">
            {" "}
            membresía activa (PRO 50 y/o PRO Ilimitado){" "}
          </span>
          y<span className="font-bold"> ser una inmobiliaria constituida</span>
          para expedir facturas o recibos de honorarios
        </div>
      </div>
      <div className="mb-32 flex w-full flex-col items-center px-3 md:px-6 lg:px-20">
        <h3 className="text-center font-bold md:text-2xl lg:text-3xl">
          Tendrás acceso exclusivo:
        </h3>
        <div className="my-8 grid w-fit grid-cols-2 place-items-center gap-8 md:grid-cols-3">
          {benefits.map((benefit, index) => (
            <BenefitItem
              key={index}
              text={benefit.text}
              bgColor={benefit.bgColor}
              image={benefit.image}
            />
          ))}
        </div>
        <div className="w-[270px]">
          <a
            id="Prolistings-AccesoExclusivo-CTA"
            rel="noreferrer"
            target="_blank"
            href="https://docs.google.com/presentation/d/1ZSLsHJmA7nxNVI5k6jO3-ypEwJb1QFbdjUvpAUofBm4/edit?usp=sharing"
          >
            <RoundButton text="Saber más de PRO Listings" active={true} />
          </a>
        </div>
      </div>
      <div className="my-8 flex w-full flex-col items-center justify-center">
        <span className="text-center text-3xl font-bold">¿Cómo funciona?</span>
        <div className="grid w-full grid-cols-1 place-items-center md:grid-cols-2 lg:grid-cols-5 lg:space-x-4">
          {steps.map((step, index) => (
            <StepItem
              key={index}
              number={index + 1}
              text={step.text}
              imgSrc={step.imgSrc}
              imgAlt={step.imgAlt}
            />
          ))}
        </div>
        <div className="mt-8 w-[314px]">
          <RoundButton
            id="Prolistings-ComoFunciona"
            text="Saber más sobre cómo funciona"
            active={true}
          />
        </div>
      </div>
      <div className="my-24 flex w-full items-center justify-center bg-orange-100 py-8 md:py-10 lg:py-16">
        <div className="flex max-w-[400px] flex-col items-center justify-center text-center  lg:max-w-[556px]">
          <h3 className="mb-8 max-w-[380px] text-center text-3xl font-semibold md:max-w-[556px] lg:text-4xl lg:font-bold">
            Aprovecha y accede anticipadamente al Catálogo
          </h3>
          <div className="mb-8 md:max-w-[472px]">
            <span className="max-w-[472px] text-lg lg:text-2xl">
              Siempre encontrarás el catálogo actualizado sobre el inventario
              disponible
            </span>
          </div>
          <a
            id="Prolistings-Catalogo"
            rel="noreferrer"
            target="_blank"
            href="https://docs.google.com/presentation/d/1d_JUNUayZosunrDT07LcUY-xkieZlf6HSC-X33kAVcU/edit?usp=sharing"
          >
            <CustomButton
              active={true}
              text="Ir al Catálogo de Desarrollos"
              activeClasses="flex w-fit items-center justify-center rounded-full border-2 text-sm text-primary-default lg:text-base cursor-pointer border-yellow-cyt bg-yellow-cyt hover:border-white active:border-yellow-cyt"
              inactiveClasses="hover:bg-[#ffcf86]"
              buttonActiveClasses="border-yellow-cyt active:border-white hover:bg-[#ffcf86] hover:border-yellow-cyt px-4"
              buttonInactiveClasses="border-[#ffcf86]"
            />
          </a>
        </div>
      </div>
      <FAQ />
    </div>
  );
}
