import React, { useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import PropTypes from "prop-types";
import NumberFormat from "react-number-format";
import Select from "@/components/forms/Select.jsx";
import { useAtom } from "jotai";
import { order } from "@/constants/properties/filters";
import { propertiesParamsAtom } from "@/_actions/propertiesActions";
import {
  useMyProperties,
  toggleProperty,
  toggleFeatured,
} from "@/_actions/propertiesActions";
import {
  TrashIcon,
  StarIcon as StarIconOutline,
  InformationCircleIcon,
} from "@heroicons/react/outline";
import { StarIcon as StarIconSolid } from "@heroicons/react/solid";
import Modal from "@/components/overlays/Modal";
import downloadPropertyPdf from "../../utils/downloadPdf";
import PropertyDetail from "./PropertyDetail";
import { usePlan } from "@/_actions/authActions";
import { sub, getISODay, formatISO, format } from "date-fns";
import es from "date-fns/locale/es";
import Notification from "@/components/overlays/Notification";
import { useProfile } from "@/_actions/userActions";

function PropertyTable({
  properties,
  mutateData,
  publishedProperties,
  rentProperties,
}) {
  const navigate = useNavigate();
  const [showModal, toggleModal] = useState(false);
  const [propertyAction, setPropertyAction] = useState({});
  const [selected, setSelected] = useState(false);
  const [showNotification, toggleNotification] = useState(false);
  const [textNotification, setTextNotification] = useState("");
  const [showNotificationError, toggleNotificationError] = useState(false);
  const [textNotificationError, setTextNotificationError] = useState("");
  const [timeNotification, setTimeNotification] = useState(0);
  const { data: profile } = useProfile();
  const role = profile?.role;
  const ADMIN_ROLES = ["admin", "owner"];
  const [easyBrokerProperties, setEasyBrokerProperties] = useState(false);
  const [infoHovered, setInfoHovered] = useState(false);
  const [showRentInformation, setShowRentInformation] = useState(false);
  const { data: ebProperties } = useMyProperties({
    page: 1,
    status: "published",
    ordering: "-created",
    origin: "209",
  });
  const { data: ebPropertiesPending } = useMyProperties({
    page: 1,
    status: "pending",
    ordering: "-created",
    origin: "209",
  });
  const [params, setParams] = useAtom(propertiesParamsAtom);

  const ninetyDaysAgoFromNextMonday = sub(new Date(), {
    days: 90 - (7 - (getISODay(new Date()) % 7) + 1),
  });
  const values = {
    status: "published",
    feed__isnull: true,
    modified__lte: formatISO(ninetyDaysAgoFromNextMonday, {
      representation: "date",
    }),
  };
  const { data: willExpire } = useMyProperties(values);

  const { data: outstandingProperties, mutate: outstandingMutate } =
    useMyProperties({
      featured: true,
    });

  const { data: plan } = usePlan();

  const editProperty = (id) => {
    navigate(`edit/${id}`);
  };

  const handleToggleNotification = (text) => {
    setTextNotification(text);
    toggleNotification(true);
  };

  const handleToggleNotificationError = (text) => {
    setTextNotificationError(text);
    toggleNotificationError(true);
  };

  const activateFeatured = async (id) => {
    try {
      if (plan.plan_addons.properties_prominent > outstandingProperties.count) {
        await toggleFeatured({ id, featured: true });
        mutateData();
        handleToggleNotification(
          "La propiedad ha sido destacada correctamente"
        );
        outstandingMutate();
      } else {
        handleToggleNotificationError("No es posible destacar más propiedades");
      }
    } catch (error) {
      $log.error(error);
      handleToggleNotificationError(
        "Ha ocurrido un error al destacar la propiedad"
      );
    }
  };

  const deactivateFeatured = async (id) => {
    try {
      await toggleFeatured({ id, featured: false });
      mutateData();
      handleToggleNotification("La propiedad ya no es destacada");
      outstandingMutate();
    } catch (error) {
      $log.error(error);
      handleToggleNotificationError("Hubo un error al actualizar la propiedad");
    }
  };

  const activateProperty = (property) => {
    const isRent =
      property?.operation_type?.length === 1 &&
      property?.operation_type[0]?.name === "Renta";

    const canPublish =
      (isRent &&
        publishedProperties + rentProperties <
          plan?.plan_addons?.properties_quantity +
            plan?.plan_addons?.free_rents) ||
      plan?.plan_addons?.properties_quantity === -1;

    const canPublishMore =
      publishedProperties < plan?.plan_addons?.properties_quantity ||
      plan?.plan_addons?.properties_quantity === -1;

    if (canPublish) {
      toggleModal(true);
      setPropertyAction({
        id: property.id,
        action: "published",
      });
      mutateData();
    } else if (canPublishMore) {
      toggleModal(true);
      setPropertyAction({
        id: property.id,
        action: "published",
      });
      mutateData();
    } else {
      window.alert("Has alcanzado el límite de propiedades activas.");
    }
  };
  return (
    <>
      {ADMIN_ROLES.includes(role) ? (
        <div>
          <dl className="mt-2 grid grid-cols-1 gap-5 sm:grid-cols-3">
            <div className="overflow-hidden rounded-lg bg-white px-4 shadow sm:p-2 sm:px-6">
              <dt className="truncate text-sm font-medium text-gray-500">
                Destacados
              </dt>
              <dd className="mt-1 text-3xl font-semibold tracking-tight text-gray-900">
                {outstandingProperties?.count} de&nbsp;
                {plan?.plan_addons?.properties_prominent}
              </dd>
            </div>
            <div className="overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:py-2 sm:px-6">
              <dt className="truncate text-sm font-medium text-gray-500">
                Publicadas
              </dt>
              <dd className="mt-1 text-3xl font-semibold tracking-tight text-gray-900">
                {publishedProperties} de&nbsp;
                {plan?.plan_addons?.properties_quantity == -1 ? (
                  <span className="text-xl">&#8734;</span>
                ) : (
                  plan?.plan_addons?.properties_quantity
                )}
              </dd>
            </div>
            {plan?.plan_addons?.free_rents ? (
              <div className="overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:py-2 sm:px-6">
                {showRentInformation && (
                  <div className="absolute -ml-[12px] flex flex-col items-center">
                    <div className="  max-w-[230px] rounded-xl bg-[#0A2356] p-4 text-sm text-white">
                      🥳 Ahora tu membresía incluye&nbsp;
                      <span className="text-sm font-bold">
                        {plan?.plan_addons?.free_rents} Publicaciones de Renta
                        ¡GRATIS!&nbsp;
                      </span>
                      Muchas gracias por ser parte de Casas y Terrenos.
                    </div>
                    <div className="border-t-[20px] border-l-[15px] border-r-[15px] border-l-transparent border-r-transparent border-t-[#0A2356]"></div>
                  </div>
                )}
                <dt className="flex truncate text-sm font-medium text-primary-default">
                  Rentas Gratis
                  <InformationCircleIcon
                    className="ml-1 w-5"
                    onMouseEnter={() => setShowRentInformation(true)}
                    onMouseLeave={() => setShowRentInformation(false)}
                  />
                </dt>
                <dd className="mt-1 text-3xl font-semibold tracking-tight text-primary-default">
                  {rentProperties} de {plan?.plan_addons?.free_rents}
                </dd>
              </div>
            ) : null}
          </dl>
        </div>
      ) : null}

      {willExpire?.count > 0 ? (
        <div className="flex w-full flex-wrap justify-between space-y-3 rounded-lg bg-yellow-cyt p-3 shadow-lg md:space-y-0">
          <div className="flex items-center">
            <p className="ml-3 truncate font-medium text-white">
              <span className="md:hidden">
                {willExpire?.count}{" "}
                {willExpire?.count > 1
                  ? "propiedades expirarán"
                  : "propiedad expirará"}{" "}
                pronto
              </span>
              <span className="hidden md:inline">
                {willExpire?.count}{" "}
                {willExpire?.count > 1
                  ? "propiedades expirarán"
                  : "propiedad expirará"}{" "}
                muy pronto
              </span>
            </p>
          </div>
          <Link to="/properties/expiring" className="w-full md:w-auto">
            <button className="w-full rounded-md border border-transparent bg-white px-4 py-2 text-center text-sm font-medium text-yellow-cyt shadow-sm hover:bg-yellow-50 md:w-auto">
              Verlas ahora
            </button>
          </Link>
        </div>
      ) : null}
      <div
        className={
          profile?.feed?.id && profile?.feed?.id === 209
            ? "flex flex-col items-end justify-between lg:flex-row lg:items-center"
            : "flex flex-col items-end justify-end lg:flex-row lg:items-center"
        }
      >
        {profile?.feed?.id && profile?.feed?.id === 209 ? (
          <div className="mb-3 flex w-full flex-col justify-between gap-2 rounded-xl bg-blue-100 p-2 shadow-lg lg:mb-0 lg:mr-4 lg:flex-row">
            <div className="flex items-center pl-4">
              <span className="flex font-bold text-blue-700">
                Propiedades EasyBroker
                <InformationCircleIcon
                  className="ml-1 mt-0.5 h-5 w-5 cursor-pointer text-gray-400"
                  onMouseEnter={() => setInfoHovered(true)}
                  onMouseLeave={() => setInfoHovered(false)}
                  aria-hidden="true"
                />
                {infoHovered ? (
                  <div className=" absolute ml-56 rounded-xl border-2 border-gray-500 bg-white p-1.5 font-normal text-gray-700">
                    En esta sección encontraras toda la info sobre las
                    propiedades de EasyBroker
                  </div>
                ) : null}
              </span>
            </div>
            <div className="flex items-center justify-center rounded-lg bg-neutral-50 p-1 text-xs">
              Propiedades sincronizadas:
              <span className="pl-1 font-semibold">
                {ebProperties?.count + ebPropertiesPending?.count}
              </span>
            </div>
            <div className="flex items-center justify-center rounded-lg bg-neutral-50 p-1 text-xs">
              Propiedades pendientes:
              <span className="pl-1 font-semibold">
                {ebPropertiesPending?.count}
              </span>
            </div>
            <div className="flex items-center justify-center rounded-lg bg-neutral-50 p-1 text-xs">
              Propiedades publicadas:
              <span className="pl-1 font-semibold">{ebProperties?.count}</span>
            </div>
            <button
              className={
                easyBrokerProperties
                  ? "button-secondary bg-blue-600 text-xs font-bold text-white ring-2 ring-gray-500 hover:bg-blue-700 hover:text-white focus:bg-blue-600 focus:text-white"
                  : "button-secondary text-xs font-bold"
              }
              onClick={() => {
                setEasyBrokerProperties(!easyBrokerProperties);
                easyBrokerProperties
                  ? setParams({ ...params, origin: "", status: "published" })
                  : setParams({ ...params, origin: "209", status: "pending" });
              }}
            >
              {easyBrokerProperties
                ? "Todas las propiedades"
                : "Filtrar propiedades pendientes"}
            </button>
          </div>
        ) : null}
        <div className="hidden h-12 w-40 flex-row-reverse sm:flex">
          <Select
            id="ordering"
            name="ordering"
            className="input"
            options={order}
            onChange={(e) => {
              setParams({ ...params, ordering: e.target.value });
            }}
            label="Ordenar"
          />
        </div>
      </div>
      <div className=" sm:px-6 lg:px-8">
        <div className="mt-5 flex flex-col">
          <div className="-my-2 mx-2 overflow-x-auto lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
              <div className="overflow-hidden">
                <table className="min-w-full sm:divide-y sm:divide-gray-300">
                  <thead className="hidden bg-gray-50 sm:table-header-group ">
                    <tr>
                      <th className="py-3 px-12 text-left text-xs font-medium uppercase tracking-wide text-gray-500 sm:pl-6"></th>
                      <th className="whitespace-nowrap px-3 py-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500">
                        Tipo de propiedad
                      </th>
                      <th className="px-3 py-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500">
                        Estatus
                      </th>
                      {ADMIN_ROLES.includes(role) ? (
                        <th className="px-3 py-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500">
                          Promotor
                        </th>
                      ) : null}
                      <th className="px-3 py-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500">
                        Precio
                      </th>
                      {profile?.feed?.id && profile?.feed?.id != "" ? (
                        <th className="px-3 py-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500">
                          Última sincronización
                        </th>
                      ) : null}
                      <th className="px-3 py-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500">
                        Acciones
                      </th>
                      <th className="relative py-3 pl-3 pr-4 sm:pr-6">
                        <span className="sr-only">Ver más</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody className="bg-white">
                    {properties.map((property, id) => (
                      <tr
                        key={property.id}
                        className={id % 2 === 0 ? undefined : "bg-gray-50"}
                      >
                        <td className="flex items-center gap-3 py-2 align-middle sm:px-2">
                          {property.status === "published" ? (
                            property.is_featured &&
                            ADMIN_ROLES.includes(role) ? (
                              <StarIconSolid
                                onClick={() => deactivateFeatured(property.id)}
                                alt="Destacado"
                                className=" h-5 w-5 cursor-pointer text-yellow-cyt"
                              />
                            ) : (
                              <StarIconOutline
                                onClick={() => activateFeatured(property.id)}
                                alt="Destacado"
                                className=" h-5 w-5 cursor-pointer text-yellow-cyt"
                              />
                            )
                          ) : null}
                          <div className="group relative aspect-square h-[100px] w-[100px] md:h-[120px] md:w-[120px] ">
                            <img
                              className="h-full w-full rounded-lg object-cover hover:scale-125"
                              src={property.image_cover}
                            ></img>
                            <a
                              target="_blank"
                              href={`${import.meta.env.VITE_PORTAL_URL}${
                                property.slug
                                  ? property.slug
                                  : `/propiedad/${property.id}`
                              }`}
                              data-splitbee-event="contacts-link-property-lead"
                              rel="noreferrer"
                            >
                              <div className="absolute top-0 hidden h-full w-full items-center justify-center rounded-lg bg-black/50 text-white group-hover:flex">
                                Ver anuncio
                              </div>
                            </a>
                          </div>
                        </td>
                        <td className="whitespace-nowrap px-1 py-4 text-sm text-gray-500 sm:px-3">
                          <div className="flex gap-1">
                            {property.origin != "Casas y Terrenos" ? (
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth={1.5}
                                stroke="currentColor"
                                className="h-6 w-6 text-green-600"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  d="M13.19 8.688a4.5 4.5 0 011.242 7.244l-4.5 4.5a4.5 4.5 0 01-6.364-6.364l1.757-1.757m13.35-.622l1.757-1.757a4.5 4.5 0 00-6.364-6.364l-4.5 4.5a4.5 4.5 0 001.242 7.244"
                                />
                              </svg>
                            ) : (
                              <div className="h-6 w-6"></div>
                            )}
                            <div className="flex flex-col">
                              <p className="text-sm text-gray-500 sm:text-gray-900">
                                {property?.property_type?.name}
                              </p>
                              <div className="flex">
                                ID:
                                <a
                                  target="_blank"
                                  rel="noreferrer"
                                  href={`${import.meta.env.VITE_PORTAL_URL}${
                                    property.slug
                                      ? property.slug
                                      : `/propiedad/${property.id}`
                                  }`}
                                  data-splitbee-event="contacts-link-property-lead"
                                >
                                  <p
                                    className="text-link pr-2 pl-1 sm:block"
                                    id={property.id}
                                  >
                                    {property.id}
                                  </p>
                                </a>
                                <button
                                  onClick={() => {
                                    const content = document.getElementById(
                                      property.id
                                    ).innerHTML;
                                    navigator.clipboard
                                      .writeText(content)
                                      .catch((err) => {
                                        $log.error("Something went wrong", err);
                                      });
                                  }}
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="h-5 w-5"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke="rgb(13 71 161)"
                                    strokeWidth="1.5"
                                  >
                                    <rect
                                      x="9"
                                      y="9"
                                      width="13"
                                      height="13"
                                      rx="2"
                                      ry="2"
                                    ></rect>
                                    <path d="M5 15H4a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h9a2 2 0 0 1 2 2v1"></path>
                                  </svg>
                                </button>
                              </div>
                            </div>
                          </div>
                        </td>
                        <td>
                          <div
                            className={`w-min whitespace-nowrap rounded-md px-2 py-1 text-sm font-medium capitalize sm:px-3 ${
                              property.status === "published"
                                ? "bg-green-100 text-green-800"
                                : property.status === "pending"
                                ? "bg-yellow-100 text-yellow-800"
                                : "bg-gray-100 text-gray-800"
                            }`}
                          >
                            {property.status === "published"
                              ? "Publicado"
                              : property.status === "pending"
                              ? "Pendiente"
                              : "Inactivo"}
                          </div>
                        </td>
                        {ADMIN_ROLES.includes(role) ? (
                          <td>
                            <div
                              className={
                                "w-min whitespace-nowrap px-3 py-1 text-sm sm:px-2"
                              }
                            >
                              {property.membership_email}
                            </div>
                          </td>
                        ) : null}
                        <td className="hidden whitespace-nowrap px-3 py-4 text-sm text-gray-500 sm:table-cell">
                          <div className="hidden text-gray-500 sm:flex sm:flex-col">
                            {property.price_sale ? (
                              <p>
                                Venta:{" "}
                                <NumberFormat
                                  className="text-right"
                                  prefix="$"
                                  displayType="text"
                                  thousandSeparator={true}
                                  value={property.price_sale}
                                  suffix={property.currency}
                                ></NumberFormat>
                              </p>
                            ) : null}
                            {property.price_rent ? (
                              <p>
                                Renta:{" "}
                                <NumberFormat
                                  className="text-right"
                                  prefix="$"
                                  displayType="text"
                                  thousandSeparator={true}
                                  value={property.price_rent}
                                  suffix={property.currency}
                                ></NumberFormat>
                              </p>
                            ) : null}
                            {property.price_transfer ? (
                              <p>
                                Traspaso:{" "}
                                <NumberFormat
                                  className="text-right"
                                  prefix="$"
                                  displayType="text"
                                  thousandSeparator={true}
                                  value={property.price_transfer}
                                  suffix={property.currency}
                                ></NumberFormat>
                              </p>
                            ) : null}
                          </div>
                        </td>
                        {profile?.feed?.id && profile?.feed.id != "" ? (
                          <td className="hidden whitespace-nowrap px-3 py-4 text-sm text-gray-500 sm:table-cell">
                            <div className="hidden text-gray-500 sm:flex sm:flex-col">
                              {property?.last_synced_at &&
                              property?.last_synced_at != ""
                                ? format(
                                    new Date(property?.last_synced_at),
                                    "d LLLL, yyyy, h:mm a",
                                    { locale: es }
                                  )
                                : "Sin Sincronización"}
                            </div>
                          </td>
                        ) : null}
                        <td className="hidden whitespace-nowrap px-3 py-4 text-sm text-gray-500 sm:table-cell">
                          <div className="flex flex-row space-x-8">
                            <div className="has-tooltip">
                              <button
                                onClick={() => {
                                  property.status === "pending"
                                    ? publishedProperties <
                                        plan?.plan_addons
                                          ?.properties_quantity ||
                                      plan?.plan_addons?.properties_quantity ==
                                        -1
                                      ? editProperty(property.id)
                                      : window.alert(
                                          "Has alcanzado el limite de propiedades activas."
                                        )
                                    : editProperty(property.id);
                                }}
                                disabled={
                                  property.status === "inactive" ? true : false
                                }
                                className={`${
                                  property.status === "inactive"
                                    ? "cursor-not-allowed text-gray-300"
                                    : "text-blue-cyt"
                                }`}
                              >
                                <div className="relative">
                                  <div className="tooltip -top-9 -left-10 rounded bg-gray-900 px-4 py-1 text-sm font-medium text-white">
                                    Editar
                                  </div>
                                  <svg
                                    className="tooltip -top-3 fill-gray-900"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 18.2 9"
                                  >
                                    <path
                                      className="text-white"
                                      d="M9.1,9L0,0l18.2,0L9.1,9z"
                                    />
                                  </svg>
                                </div>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  className="h-5 w-5"
                                  viewBox="0 0 20 20"
                                  fill="currentColor"
                                >
                                  <path d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z" />
                                </svg>
                              </button>
                            </div>
                            {property.status === "published" ? (
                              <div className="has-tooltip">
                                <button
                                  onClick={() => {
                                    toggleModal(true);
                                    setPropertyAction({
                                      id: property.id,
                                      action: "inactive",
                                    });
                                  }}
                                  className="text-blue-cyt"
                                >
                                  <div className="relative">
                                    <div className="tooltip -top-9 -left-20 rounded bg-gray-900 px-4 py-1 text-sm font-medium text-white">
                                      Desactivar Propiedad
                                    </div>
                                    <svg
                                      className="tooltip -top-3 fill-gray-900"
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 0 18.2 9"
                                    >
                                      <path
                                        className="text-white"
                                        d="M9.1,9L0,0l18.2,0L9.1,9z"
                                      />
                                    </svg>
                                    <TrashIcon className="h-5 w-5" />
                                  </div>
                                </button>
                              </div>
                            ) : null}
                            {property.status === "pending" &&
                            property.origin !== "EasyBroker" ? (
                              <button
                                disabled
                                className="cursor-not-allowed text-gray-300"
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  className="h-5 w-5"
                                  viewBox="0 0 20 20"
                                  fill="currentColor"
                                >
                                  <path
                                    fillRule="evenodd"
                                    d="M3.707 2.293a1 1 0 00-1.414 1.414l14 14a1 1 0 001.414-1.414l-1.473-1.473A10.014 10.014 0 0019.542 10C18.268 5.943 14.478 3 10 3a9.958 9.958 0 00-4.512 1.074l-1.78-1.781zm4.261 4.26l1.514 1.515a2.003 2.003 0 012.45 2.45l1.514 1.514a4 4 0 00-5.478-5.478z"
                                    clipRule="evenodd"
                                  />
                                  <path d="M12.454 16.697L9.75 13.992a4 4 0 01-3.742-3.741L2.335 6.578A9.98 9.98 0 00.458 10c1.274 4.057 5.065 7 9.542 7 .847 0 1.669-.105 2.454-.303z" />
                                </svg>
                              </button>
                            ) : null}
                            {property.status === "pending" &&
                            property.origin === "EasyBroker" ? (
                              <button
                                className="text-blue-cyt"
                                onClick={
                                  publishedProperties <
                                    plan?.plan_addons?.properties_quantity ||
                                  plan?.plan_addons?.properties_quantity == -1
                                    ? () => {
                                        toggleModal(true);
                                        setPropertyAction({
                                          id: property.id,
                                          action: "published",
                                        }),
                                          mutateData();
                                      }
                                    : () => {
                                        window.alert(
                                          "Has alcanzado el limite de propiedades activas."
                                        );
                                      }
                                }
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  className="h-5 w-5"
                                  viewBox="0 0 20 20"
                                  fill="currentColor"
                                >
                                  <path d="M10 12a2 2 0 100-4 2 2 0 000 4z" />
                                  <path
                                    fillRule="evenodd"
                                    d="M.458 10C1.732 5.943 5.522 3 10 3s8.268 2.943 9.542 7c-1.274 4.057-5.064 7-9.542 7S1.732 14.057.458 10zM14 10a4 4 0 11-8 0 4 4 0 018 0z"
                                    clipRule="evenodd"
                                  />
                                </svg>
                              </button>
                            ) : null}
                            {property.status === "inactive" ? (
                              <div className="has-tooltip">
                                <button
                                  onClick={() => activateProperty(property)}
                                  className="text-blue-cyt "
                                >
                                  <div className="relative">
                                    <div className="tooltip -top-9 -left-7 rounded bg-gray-900 px-4 py-1 text-sm font-medium text-white">
                                      Activar
                                    </div>
                                    <svg
                                      className="tooltip -top-3 fill-gray-900"
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 0 18.2 9"
                                    >
                                      <path
                                        className="text-white"
                                        d="M9.1,9L0,0l18.2,0L9.1,9z"
                                      />
                                    </svg>
                                  </div>
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="h-5 w-5"
                                    viewBox="0 0 20 20"
                                    fill="currentColor"
                                  >
                                    <path d="M10 12a2 2 0 100-4 2 2 0 000 4z" />
                                    <path
                                      fillRule="evenodd"
                                      d="M.458 10C1.732 5.943 5.522 3 10 3s8.268 2.943 9.542 7c-1.274 4.057-5.064 7-9.542 7S1.732 14.057.458 10zM14 10a4 4 0 11-8 0 4 4 0 018 0z"
                                      clipRule="evenodd"
                                    />
                                  </svg>
                                </button>
                              </div>
                            ) : null}
                            {plan?.plan_addons?.properties_pdfs ? (
                              <div className="has-tooltip">
                                <button
                                  type="button"
                                  onClick={() => {
                                    setTimeNotification(100000);
                                    handleToggleNotification(
                                      "Descargando archivo. Puede tardar varios segundos."
                                    );
                                    downloadPropertyPdf(property.id)
                                      .then(() => {
                                        toggleNotification(false);
                                        setTimeNotification(5000);
                                        handleToggleNotification(
                                          "Archivo descargado con éxito."
                                        );
                                      })
                                      .catch(() => {
                                        toggleNotification(false);
                                        handleToggleNotificationError(
                                          "Ha ocurrido un error."
                                        );
                                      });
                                  }}
                                  disabled={
                                    property.status === "pending" ? true : false
                                  }
                                  className={`${
                                    property.status === "pending"
                                      ? "cursor-not-allowed text-gray-300"
                                      : "text-blue-cyt"
                                  }`}
                                >
                                  <div className="relative">
                                    <div className="tooltip -top-9 -left-7 rounded bg-gray-900 px-4 py-1 text-sm font-medium text-white">
                                      Descargar PDF
                                    </div>
                                    <svg
                                      className="tooltip -top-3 fill-gray-900"
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 0 18.2 9"
                                    >
                                      <path
                                        className="text-white"
                                        d="M9.1,9L0,0l18.2,0L9.1,9z"
                                      />
                                    </svg>
                                  </div>
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="h-5 w-5"
                                    viewBox="0 0 20 20"
                                    fill="currentColor"
                                  >
                                    <path
                                      fillRule="evenodd"
                                      d="M3 17a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zm3.293-7.707a1 1 0 011.414 0L9 10.586V3a1 1 0 112 0v7.586l1.293-1.293a1 1 0 111.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z"
                                      clipRule="evenodd"
                                    />
                                  </svg>
                                </button>
                              </div>
                            ) : null}
                          </div>
                        </td>
                        <td className="relative whitespace-nowrap py-4 pl-2 text-right text-sm font-normal sm:pr-6 sm:pl-4 sm:font-medium">
                          <p
                            onClick={() => {
                              toggleModal(true);
                              setSelected(property);
                              setPropertyAction({
                                id: property.id,
                                action: "detail",
                              });
                            }}
                            className="flex cursor-pointer flex-row items-center text-indigo-600 hover:text-indigo-900"
                          >
                            Ver más
                            <span className="sr-only">{property.name}</span>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              className="h-5 w-5 pl-1 sm:hidden"
                              viewBox="0 0 20 20"
                              fill="currentColor"
                            >
                              <path
                                fillRule="evenodd"
                                d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                                clipRule="evenodd"
                              />
                            </svg>
                          </p>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Notification
        open={showNotification}
        setOpen={toggleNotification}
        title={textNotification}
        timeOut={timeNotification}
      />
      <Notification
        open={showNotificationError}
        setOpen={toggleNotificationError}
        title={textNotificationError}
        type="error"
      />
      <Modal open={showModal} setOpen={toggleModal}>
        <>
          {propertyAction.action === "detail" ? (
            <PropertyDetail
              id={selected?.id}
              title={selected?.title}
              status={selected?.status}
              mutateData={mutateData}
              toggleModal={toggleModal}
              publishedProperties={publishedProperties}
              activateProperty={activateProperty}
            />
          ) : (
            <div className="max-w-md p-5">
              <p>
                Deseas
                {propertyAction?.action === "published"
                  ? " activar "
                  : " desactivar "}
                esta propiedad?
              </p>
              <div className="my-2 space-x-2">
                <button
                  className="button-primary "
                  onClick={() => {
                    toggleProperty(
                      {
                        id: propertyAction?.id,
                        status: propertyAction?.action,
                      },
                      () => {
                        toggleModal(false);
                        mutateData();
                        outstandingMutate();
                        toggleFeatured({
                          id: propertyAction?.id,
                          featured: false,
                          confirmDialog: false,
                        });
                      }
                    );
                  }}
                >
                  {
                    { inactive: "Desactivar", published: "Activar" }[
                      propertyAction?.action
                    ]
                  }
                </button>
                <button
                  onClick={() => {
                    toggleModal(false);
                  }}
                  className="button-secondary "
                >
                  Cancelar
                </button>
              </div>
            </div>
          )}
        </>
      </Modal>
    </>
  );
}

export default PropertyTable;

PropertyTable.propTypes = {
  properties: PropTypes.array.isRequired,
  mutateData: PropTypes.func,
  publishedProperties: PropTypes.number,
  rentProperties: PropTypes.number,
};
