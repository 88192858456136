import React from "react";
import PropTypes from "prop-types";
import * as Yup from "yup";
import { updateContact } from "@/_actions/contactActions";
import { Formik, Form, Field, ErrorMessage } from "formik";

const ContactStatusForm = ({ action, id, callBack }) => {
  const modalData =
    action === "contacted"
      ? {
          options: ["Correo", "Teléfono"],
          description: "Medio de contacto",
        }
      : {
          options: ["No contestó", "Datos de contacto incorrectos"],
          description: "Razón de descarte",
        };

  const ActionsSchema = Yup.object().shape({
    status_description: Yup.string().required("Selecciona una opción"),
  });

  const submit = async (values) => {
    try {
      action === "contacted" ? (values.status = 2) : "";
      action === "discarded" ? (values.status = 3) : "";
      values.id = id;
      await updateContact(id, values);
      callBack && callBack(values);
    } catch (err) {
      $log.error(err);
    }
  };

  return (
    <div>
      <Formik
        initialValues={{
          status_description: "",
        }}
        validationSchema={ActionsSchema}
        onSubmit={submit}
      >
        {({ handleSubmit, isSubmitting }) => (
          <Form>
            <div className="text-md flex flex-col space-y-4 px-4 pt-5 pb-4 font-light">
              <label htmlFor="email">
                <span className="text-red-400">*</span> {modalData.description}
              </label>
              <div className="my-6 flex justify-center space-x-12">
                {modalData.options.map((option) => (
                  <label key={option}>
                    <Field
                      name="status_description"
                      type="radio"
                      value={option}
                      key={option}
                      className="m-2"
                    />
                    {option}
                  </label>
                ))}
              </div>
              <div className="flex justify-center">
                <div className="relative whitespace-nowrap">
                  <ErrorMessage
                    component="span"
                    className="input-error absolute top-[1rem] ml-4"
                    name="status_description"
                  />
                </div>
              </div>
              <div className="flex justify-end">
                <button
                  type="submit"
                  className="button-primary"
                  onClick={handleSubmit}
                  disabled={isSubmitting}
                >
                  Guardar
                </button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

ContactStatusForm.defaultProps = {
  callBack: null,
};

ContactStatusForm.propTypes = {
  action: PropTypes.oneOf(["contacted", "discarded"]),
};

export default ContactStatusForm;
