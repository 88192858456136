import apiClient from "../utils/apiClient";
import queryString from "query-string";
import useSWR from "swr";

/**
 * @typedef {Object} SWRObject
 * @property {Object} data - The data returned by swr call
 * @property {boolean} isLoading - State of the swr call
 */
/** @function
 * @name useProfile
 * @description - Retruns object with the data for the current user
 * @returns {SWRObject} - swr Object
 */
export const useProfile = () => {
  const fetcher = (url) => apiClient.get(url).then((res) => res.data[0]);
  const { data, error, mutate } = useSWR("/accounts/me/", fetcher);

  return { data: data, isLoading: !error && !data, error, mutate };
};

/** @function
 * @name updateProfile
 * @description - Update the contact info for the current user
 * @param {Object} data - Object with the user info contact
 */
export const updateProfile = async (data) => {
  try {
    await apiClient.patch("/accounts/me/", data);
  } catch (err) {
    $log.error(err);
  }
};
/**
 * @typedef {Object} SWRObject
 * @property {object} data - The data returned by the swr call
 * @property {boolean} isLoading - State of the swr call
 */

/** @function
 * @name useUsers
 * @description - Returns object with membership info
 * @param {Object} params - params that you can send to update
 * @returns {SWRObject} - swr Object
 */
export const useUsers = (params) => {
  const fetcher = (url) => apiClient.get(url).then((res) => res?.data);
  const { data, error, mutate } = useSWR(
    `/membership?${queryString.stringify(params)}`,
    fetcher
  );

  return { data: data, isLoading: !error && !data, error, mutate };
};

/** @function
 * @name toggleUser
 * @description - Changes the user state
 * @param {String} id - User id
 * @param {Number} status - Status by user
 */

export const toggleUser = async (id, status) => {
  await apiClient.patch(`membership/${id}`, {
    id,
    status,
  });
};
