import useSWR from "swr";
import acquisitionClient from "@/services/acquisitionApiClient";

function useAcquisitionUser(uuid) {
  if (!uuid) {
    return { error: new Error("Error loading user, uuid is required") };
  }
  const fetcher = (url) => acquisitionClient.get(url).then((res) => res.data);

  const { data, error } = useSWR(`/customers/users/${uuid}/`, fetcher);

  return { data, error, isLoading: !error && !data };
}

export default useAcquisitionUser;
