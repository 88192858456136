import React, { useState, useEffect } from "react";
import { useAtom } from "jotai";
import { usePlan } from "@/_actions/authActions";

import Pagination from "@/components/navigation/Pagination";
import {
  useContacts,
  contactsParamsAtom,
  initialParams,
} from "@/_actions/contactActions";

import Analytics from "./Analytics";
import Filters from "./Filters";
import ContactList from "./ContactList";
import ContactDetail from "./ContactDetail";

export default function Index() {
  const [contactsParams, setContactParams] = useAtom(contactsParamsAtom);
  const { data: contacts, error } = useContacts(contactsParams);
  const [selected, setSelected] = useState({});
  const { data: plan } = usePlan();

  useEffect(() => {
    setSelected(contacts?.results[0]);
  }, [contacts?.results[0]]);

  return (
    <div className="sm:p-2 lg:p-4">
      <h1 className="title">Contactos</h1>
      <div className="md:grid md:grid-cols-5">
        <div className="col-span-4">
          <Filters
            onReset={() => setContactParams(initialParams)}
            onSubmit={(values) =>
              setContactParams(() => {
                if (values.status !== "all") {
                  return { ...values };
                } else {
                  const r = { ...values };
                  delete r.status;
                  return r;
                }
              })
            }
          />
        </div>
        <>
          {plan?.plan_addons.contact_analytics ? (
            <div>{<Analytics />} </div>
          ) : null}
        </>
      </div>
      <div className="my-5 md:grid md:grid-cols-5">
        <div className="col-span-1">
          <ContactList
            contacts={contacts?.results}
            selected={selected?.id}
            onSelect={setSelected}
            contact={selected}
          />
        </div>
        <div className="col-span-4 hidden md:flex">
          <ContactDetail contact={selected} />
        </div>
      </div>
      <Pagination
        total={contacts?.count}
        type={"Contacto"}
        current={contactsParams.page}
        next={contacts?.next}
        previous={contacts?.previous}
        pageSize={10}
        onPageChange={(page) => setContactParams((prev) => ({ ...prev, page }))}
      />
    </div>
  );
}
