import React, { useState } from "react";
import { Link } from "react-router-dom";
import * as Yup from "yup";
import { Formik, Form, Field, ErrorMessage } from "formik";

const LoginSchema = Yup.object().shape({
  username: Yup.string()
    .email("Ingresa un correo electrónico válido")
    .required("Este campo es requerido"),
  password: Yup.string().required("Este campo es requerido"),
});

export default function LoginForm({ onSubmit }) {
  const [showPass, toggleShowPass] = useState(false);
  return (
    <Formik
      initialValues={{ username: "", password: "" }}
      validationSchema={LoginSchema}
      onSubmit={(values) => onSubmit(values)}
    >
      <Form className="space-y-3">
        <div>
          <label className="form-label" htmlFor="username">
            Correo electrónico
          </label>
          <Field
            id="username"
            type="email"
            name="username"
            className="input"
            placeholder="correo electrónico"
          />
          <ErrorMessage
            component="span"
            className="input-error"
            name="username"
          />
        </div>
        <div>
          <label className="form-label" htmlFor="password">
            Contraseña
          </label>
          <div className="full flex">
            <Field
              type={showPass ? "text" : "password"}
              name="password"
              id="password"
              className="block w-full rounded-l border border-gray-300 shadow-sm focus:border-blue-800  focus:ring-blue-800 sm:text-sm"
              placeholder="contraseña"
            />
            <div
              onClick={() => toggleShowPass(!showPass)}
              className="flex w-10 cursor-pointer items-center rounded-r border border-gray-300 text-center hover:bg-blue-50"
            >
              {!showPass ? (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="m-auto h-6 w-6 items-center text-center"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                  />
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"
                  />
                </svg>
              ) : (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="m-auto h-6  w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M13.875 18.825A10.05 10.05 0 0112 19c-4.478 0-8.268-2.943-9.543-7a9.97 9.97 0 011.563-3.029m5.858.908a3 3 0 114.243 4.243M9.878 9.878l4.242 4.242M9.88 9.88l-3.29-3.29m7.532 7.532l3.29 3.29M3 3l3.59 3.59m0 0A9.953 9.953 0 0112 5c4.478 0 8.268 2.943 9.543 7a10.025 10.025 0 01-4.132 5.411m0 0L21 21"
                  />
                </svg>
              )}
            </div>
          </div>

          <ErrorMessage
            component="span"
            className="input-error"
            name="password"
          />
        </div>

        <div className="flex items-center justify-between">
          <div className="text-sm">
            <Link
              data-splitbee-event="forgot-password"
              to="/forgot-password"
              className="font-medium text-blue-700 hover:text-blue-700"
            >
              ¿Olvidaste tu contraseña?
            </Link>
          </div>
        </div>

        <div>
          <button
            id="Form-IniciarSesion"
            data-splitbee-event="log-in"
            type="submit"
            className="button-primary w-full"
          >
            Iniciar sesión
          </button>
        </div>
      </Form>
    </Formik>
  );
}
