import React, { useState } from "react";
import ForgotPasswordForm from "./forms/ForgotPasswordForm";
import ResetPasswordForm from "./forms/ResetPasswordForm";
import {
  sendResetPasswordCode,
  sendResetPassword,
} from "@/_actions/authActions";
import { useNavigate } from "react-router-dom";

import Notification from "@/components/overlays/Notification";
import logoCYT from "@/pages/login/img/logo-blue.svg";

export default function Login() {
  const navigate = useNavigate();
  const [showNotification, toggleNotification] = useState(false);
  const [showSuccessNotification, toggleSuccessNotification] = useState(false);
  const [username, setUserName] = useState("");
  const [step, setStep] = useState(1);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  const submitResetPassword = async (values) => {
    try {
      await sendResetPasswordCode(values);
      setUserName(values.username);
      setStep(2);
    } catch (err) {
      toggleNotification(true);
      setErrorMessage(
        "El correo electrónico ingresado no se encuentra, comunícate con un ejecutivo"
      );
      $log.error(err);
    }
  };

  const resetPassword = async (values) => {
    try {
      await sendResetPassword({ ...values, username });
      toggleSuccessNotification();
      setSuccessMessage("La contraseña fue actualizada correctamente");
      navigate("/login", { replace: true });
    } catch (err) {
      toggleNotification(true);
      setErrorMessage(
        "Hubo un error cambiando la contraseña, intente de nuevo más tarde"
      );
      $log.error(err);
    }
  };

  return (
    <>
      <div className="flex min-h-screen">
        <div className="flex flex-1 flex-col justify-center py-12 px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
          <div className="mx-auto w-full max-w-sm lg:w-96">
            <div className="sm:mx-auto sm:w-full sm:max-w-md">
              <div className="flex flex-shrink-0 items-center px-4">
                <img className=" h-12 w-auto" src={logoCYT} alt="logo-blue" />
              </div>
              <h2 className="mt-6 text-3xl font-extrabold text-gray-900">
                Restaura tu contraseña
              </h2>
              <p className="mt-2  text-sm text-gray-600">
                {step === 1
                  ? "Ingresa el correo electrónico con el que inicias sesión"
                  : null}
                {step === 2
                  ? "Enviamos un correo electrónico con un código de verificación que debes ingresar para poder restaurar la constraseña"
                  : null}
              </p>
            </div>

            <div className="mt-8">
              <div className="mt-6">
                {step === 1 ? (
                  <ForgotPasswordForm onSubmit={submitResetPassword} />
                ) : null}
                {step === 2 ? (
                  <ResetPasswordForm onSubmit={resetPassword} />
                ) : null}
              </div>
            </div>
          </div>
        </div>
        <div className="relative hidden w-0 flex-1 lg:block">
          <img
            className="absolute inset-0 h-full w-full object-cover"
            src="https://images.unsplash.com/photo-1505904267569-f02eaeb45a4c?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1908&q=80"
            alt=""
          />
        </div>
      </div>
      <Notification
        open={showNotification}
        setOpen={toggleNotification}
        timeOut={10000}
        type="error"
        title={errorMessage}
      />
      <Notification
        open={showSuccessNotification}
        setOpen={toggleSuccessNotification}
        timeOut={10000}
        type="success"
        title={successMessage}
      />
    </>
  );
}
