import React, { useState } from "react";
import { useMyProperties, updateProperty } from "@/_actions/propertiesActions";
import { sub, getISODay, formatISO } from "date-fns";

import NumberFormat from "react-number-format";
import Pagination from "@/components/navigation/Pagination";

const ExpiringProperties = () => {
  const [selected, onSelect] = useState([]);
  const ninetyDaysAgoFromNextMonday = sub(new Date(), {
    days: 90 - (7 - (getISODay(new Date()) % 7) + 1),
  });
  const [values, setValues] = useState({
    status: "published",
    feed__isnull: true,
    modified__lte: formatISO(ninetyDaysAgoFromNextMonday, {
      representation: "date",
    }),
    page: 1,
  });
  const { data: dataSource, mutate: mutateProperties } =
    useMyProperties(values);

  const onErrorImage = (e) => {
    e.target.src =
      "https://cyt-media.s3-us-west-2.amazonaws.com/img_casas/NoFoto.png";
  };

  const onChangeSelected = (id) => {
    if (selected.find((x) => x == id)) {
      onSelect(selected.filter((x) => x !== id));
    } else {
      onSelect([...selected, id]);
    }
  };

  const edit = async () => {
    const values = {
      modified: new Date(),
    };

    await selected.map(async (x) => {
      await updateProperty(x, values);
    });

    mutateProperties();
  };
  const url = `${import.meta.env.PORTALURL}/propiedad/`;

  return (
    <div className="space-y-2">
      <p className="title">Propiedades por expirar</p>
      <div className="flex flex-col">
        <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
            <div className="overflow-hidden border-b border-gray-200 shadow sm:rounded-lg">
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      className="px-6 py-3 text-center text-xs font-medium uppercase tracking-wider text-gray-500"
                    />
                    <th
                      scope="col"
                      className="px-6 py-3 text-center text-xs font-medium uppercase tracking-wider text-gray-500"
                    >
                      ID
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-center text-xs font-medium uppercase tracking-wider text-gray-500"
                    >
                      Tipo de propiedad
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-center text-xs font-medium uppercase tracking-wider text-gray-500"
                    >
                      Dirección
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-center text-xs font-medium uppercase tracking-wider text-gray-500"
                    >
                      Fecha de creación
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-center text-xs font-medium uppercase tracking-wider text-gray-500"
                    >
                      Precio
                    </th>
                    <th scope="col" className="relative px-6 py-3">
                      <span className="sr-only">Edit</span>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {dataSource?.results?.map((element) => (
                    <tr
                      key={element.id}
                      className="space-y-4 space-x-6 bg-white text-center text-sm text-gray-500"
                    >
                      <td className="whitespace-nowrap p-2">
                        <img
                          className="h-[100px] w-[100px] rounded-lg object-cover hover:scale-125 md:h-[120px] md:w-[120px]"
                          src={element.image_cover || ""}
                          alt={element?.title || ""}
                          onError={onErrorImage}
                          referrerPolicy="no-referrer-when-downgrade"
                        />
                      </td>
                      <td className="whitespace-nowrap p-2">
                        <a
                          href={`${url}/${element.id}`}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="test-center text-blue-cyt"
                        >
                          {element.id}
                        </a>
                      </td>
                      <td className="whitespace-nowrap text-center">
                        {element?.property_type?.name}
                      </td>
                      <td className="whitespace-nowrap">
                        <div className="capitalize">
                          <div>{element?.colony?.name || ""}</div>
                          <div>
                            {element?.municipality?.name || ""}
                            {element?.state?.name && (
                              <span>, {element?.state.name}</span>
                            )}
                          </div>
                        </div>
                      </td>
                      <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-500">
                        {element.date_created}
                      </td>
                      <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-500">
                        {element?.price_rent > 0 && (
                          <div key="1">
                            Renta:{" "}
                            <NumberFormat
                              className="text-right"
                              prefix="$"
                              displayType="text"
                              thousandSeparator={true}
                              value={element.price_rent}
                              suffix={element.currency}
                            ></NumberFormat>
                          </div>
                        )}
                        {element?.price_sale > 0 && (
                          <div key="2">
                            Venta:
                            <NumberFormat
                              className="text-right"
                              prefix="$"
                              displayType="text"
                              thousandSeparator={true}
                              value={element.price_sale}
                              suffix={element.currency}
                            ></NumberFormat>
                          </div>
                        )}
                        {element?.price_transfer > 0 && (
                          <div key="3">
                            Traspaso: $
                            <NumberFormat
                              className="text-right"
                              prefix="$"
                              displayType="text"
                              thousandSeparator={true}
                              value={element.price_transfer}
                              suffix={element.currency}
                            ></NumberFormat>
                          </div>
                        )}
                      </td>
                      <td className="whitespace-nowrap px-6 py-4 text-sm font-medium">
                        <div className="flex flex-wrap justify-center space-x-0 sm:space-x-2">
                          <input
                            type="checkbox"
                            id="checkbox"
                            onChange={() => onChangeSelected(element.id)}
                          />
                          <label htmlFor="checkbox">Mantener</label>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <Pagination
                total={dataSource?.count}
                type={"Propiedad"}
                current={values.page}
                next={dataSource?.next}
                previous={dataSource?.previous}
                pageSize={10}
                onPageChange={(page) =>
                  setValues((prev) => ({ ...prev, page }))
                }
              />
            </div>
          </div>
        </div>
      </div>
      {selected.length >= 1 && (
        <div className="my-7 flex justify-end" onClick={edit}>
          <button className="text-bold rounded-md bg-blue-cyt p-3 text-white">
            Guardar
          </button>
        </div>
      )}
    </div>
  );
};

export default ExpiringProperties;
