import apiClient from "../utils/apiClient";
import useSWR from "swr";
import queryString from "query-string";
/**
 * @typedef {Object} SWRObject
 * @property {object} data - The data returned by the swr call
 * @property {boolean} isLoading - State of the swr call
 */

/** @function
 * @name useStates
 * @description - Obtain the states of the mexican republic
 * @returns {SWRObject} - swr Object
 */

export const useStates = () => {
  const fetcher = (url) => apiClient.get(url).then((res) => res.data.results);
  const { data, error } = useSWR("/locations/state/", fetcher);
  return { data, isLoading: !error && !data, error };
};
/**
 * @typedef {Object} SWRObject
 * @property {object} data - The data returned by the swr call
 * @property {boolean} isLoading - State of the swr call
 */

/** @function
 * @name useMunicipalities
 * @param {Object} params - id from the state
 * @returns {SWRObject} - swr Object
 */

export const useMunicipalities = (params) => {
  const fetcher = (url) => apiClient.get(url).then((res) => res.data.results);
  const { data, error } = useSWR(
    `/locations/municipality/?${queryString.stringify(params)}`,
    fetcher
  );

  return { data, isLoading: !error && !data, error };
};

/**
 * @typedef {Object} SWRObject
 * @property {object} data - The data returned by the swr call
 * @property {boolean} isLoading - State of the swr call
 */

/** @function
 * @name useNeighborhoods
 * @param {object} params - id from the neighborhood
 * @returns {SWRObject} - swr Object
 */

export const useNeighborhoods = (params) => {
  const fetcher = (url) => apiClient.get(url).then((res) => res.data);
  const { data, error } = useSWR(
    params?.municipality
      ? `/locations/neighborhood/?${queryString.stringify(params)}`
      : null,
    fetcher
  );
  return { data, isLoading: !error && !data, error };
};
