import React, { useState, useEffect } from "react";
import EditBusiness from "./forms/EditBusiness";
import UploadLogo from "./forms/UploadLogo";
import { Formik } from "formik";
import {
  useBusiness,
  usePermaLink,
  updatePermaLink,
} from "@/_actions/businessActions";
import { useProfile } from "@/_actions/userActions";
import Notification from "@/components/overlays/Notification";
import { usePlan } from "@/_actions/authActions";
import { InformationCircleIcon } from "@heroicons/react/outline";

export default function BusinessProfile() {
  const { data: profile, mutate: mutateProfile } = useProfile();
  const { data = {}, mutate: mutateBusiness } = useBusiness(profile?.client);
  const [edit, toggleEdit] = useState(false);
  const [showNotification, toggleNotification] = useState(false);
  const [textNotification, setTextNotification] = useState("");
  const { data: plan } = usePlan();
  const [showBanner, setShowBanner] = useState(false);
  useEffect(() => {
    if (data?.business_name == "" || profile?.logo_client == "") {
      setShowBanner(true);
      toggleEdit(true);
    } else {
      setShowBanner(false);
    }
  }, [profile, data]);

  const role = profile?.role;

  const handleToggleNotification = (text) => {
    setTextNotification(text);
    toggleNotification(true);
    setTimeout(() => {
      toggleNotification(false);
    }, 5000);
  };

  return (
    <div className="sm:p-2 lg:p-4">
      {showBanner && (
        <div className="flex w-full rounded-lg border border-[#3655E0] bg-[#E7EBFA] p-2 font-bold text-[#0C2078]">
          <InformationCircleIcon className="mr-1 w-5" />
          Completa los datos👇 para publicar propiedades y comienza a recibir
          clientes potenciales 👥 🎯
        </div>
      )}
      <UploadLogo
        logoClient={profile?.logo_client}
        callBack={() => {
          mutateProfile();
          mutateBusiness();
          handleToggleNotification("Cambios realizados con éxito");
        }}
        clientId={profile?.client}
      />
      <p className="mt-1 block text-xl font-medium leading-tight text-black">
        Datos de la inmobilaria
      </p>
      <p className="mt-2 text-gray-500">
        Estos datos aparecerán en la guía inmobiliaria y en caso de que falten
        datos de contacto en alguna propiedad.
      </p>
      {!edit ? (
        <>
          <div className="mt-3 text-sm font-semibold uppercase tracking-wide text-blue-800">
            {data.business_name}
          </div>
          <div className="mt-3 flex">
            <svg
              className="h-6 w-6 flex-shrink-0 text-gray-400"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              aria-hidden="true"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z"
              />
            </svg>

            <span className="ml-3">{data?.phone_number || "-"}</span>
          </div>
          <div className="mt-3 flex">
            <svg
              className="h-6 w-6 flex-shrink-0 text-gray-400"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              aria-hidden="true"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
              />
            </svg>
            <span className="ml-3">{data?.email_contact || "-"}</span>
          </div>
          {["admin", "owner"].includes(role) ? (
            <button
              onClick={() => toggleEdit(true)}
              type="button"
              data-splitbee-event="account-edit-contact"
              className="button-secondary mt-5"
            >
              <span className="inline">Editar </span>
              <svg
                className=" ml-2 -mr-0.5 inline h-4 w-4"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"
                />
              </svg>
            </button>
          ) : null}

          {["admin", "owner"].includes(role) &&
          plan?.plan_addons?.cyt_landing ? (
            <div className="mt-7">
              <PermaLinkForm
                callBack={() =>
                  handleToggleNotification("Url actualizada con éxito")
                }
              />
            </div>
          ) : null}
        </>
      ) : (
        <EditBusiness
          data={data}
          callBack={() => {
            toggleEdit(false);
            mutateBusiness();
            handleToggleNotification("Cambios realizados con éxito");
          }}
          firstLogin={showBanner}
          cancel={() => toggleEdit(false)}
        />
      )}
      <Notification
        open={showNotification}
        setOpen={toggleNotification}
        title={textNotification}
      />
    </div>
  );
}

function PermaLinkForm({ callBack }) {
  const { data = {}, mutatePermaLink } = usePermaLink();

  const onSubmit = async (val) => {
    await updatePermaLink(val);
    mutatePermaLink();
    callBack();
  };

  return (
    <Formik
      initialValues={{
        permalink: data?.permalink || "",
      }}
      onSubmit={onSubmit}
    >
      {({ values, setFieldValue, handleSubmit }) => (
        <form>
          <label
            htmlFor="url"
            className="block text-sm font-medium text-gray-700"
          >
            Edita solo la url inmobiliaria
          </label>
          <div className="flex flex-wrap justify-between">
            <div className="flex flex-grow rounded-md pr-3 pb-3 shadow-sm">
              <span className="inline-flex items-center items-center rounded-l-md border border-r-0 border-gray-300 bg-gray-50 px-3 text-gray-500 sm:text-sm">
                <p className="hidden sm:block">
                  casasyterrenos.com/inmobiliaria/
                </p>
                <p className="inline-flex sm:hidden">/</p>
              </span>
              <input
                type="text"
                name="url"
                id="url"
                value={values?.permalink || data.permalink}
                className="input rounded-l-none"
                onChange={(e) => {
                  setFieldValue(
                    "permalink",
                    e.target.value.replace(" ", "-").toLowerCase()
                  );
                }}
                onBlur={handleSubmit}
              />
            </div>
            <div className="flex justify-end pb-3">
              <a
                className="button-secondary text-sm"
                rel="noreferrer"
                target="_blank"
                href={`${import.meta.env.VITE_PORTAL_URL}/inmobiliaria/${
                  data.permalink
                }`}
              >
                Ir a la pagina de la inmobiliaria
              </a>
            </div>
          </div>
        </form>
      )}
    </Formik>
  );
}
