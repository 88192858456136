export const fields = {
  latitude: "Latitud",
  longitude: "Longitud",
  currency_rent: "Divisa",
  currency_sale: "Divisa",
  currency_transfer: "Divisa",
  colony: "Colonia",
  price_rent: "Precio",
  price_sale: "Precio",
  municipality: "Municipio",
  state: "Estado",
  find_city: "Colonia",
  images: "Imágenes",
  property_type: "Tipo de propiedad",
  operations: "Valor de venta/renta",
  "none is not an allowed value": "Falta dato",
  "currency  not valid, should be one of mxn or usd":
    "Debe ser un tipo de divisa valida (MXN, USD)",
  "property has no images": "La propiedad no tiene imágenes",
  "city does not exists": "La ciudad elegida no existe",
};
