import React, { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

export default function NumberInputs({ onCodeChange }) {
  const inputRefs = useRef([]);
  const [code, setCode] = useState(["", "", "", "", ""]);

  useEffect(() => {
    onCodeChange(code.join(""));
  }, [code, onCodeChange]);

  const handleInputChange = (event, index) => {
    const value = event.target.value;
    if (value.match(/^\d$/)) {
      const newCode = [...code];
      newCode[index] = value;
      setCode(newCode);

      if (index < 4) {
        inputRefs.current[index + 1].focus();
      }
    } else if (value === "") {
      const newCode = [...code];
      newCode[index] = "";
      setCode(newCode);
    }
  };

  const handleKeyDown = (event, index) => {
    if (event.key === "Backspace" && code[index] === "") {
      if (index > 0) {
        inputRefs.current[index - 1].focus();
      }
    }
  };

  const handlePaste = (event) => {
    const pasteData = event.clipboardData.getData("text");
    if (pasteData.match(/^\d{1,5}$/)) {
      const newCode = pasteData.split("").slice(0, 5);
      setCode([...newCode, "", "", "", "", ""].slice(0, 5));
      newCode.forEach((num, index) => {
        if (inputRefs.current[index]) {
          inputRefs.current[index].value = num;
        }
      });
      if (newCode.length < 5) {
        inputRefs.current[newCode.length].focus();
      }
    }
    event.preventDefault();
  };

  return (
    <div
      className="flex w-full items-center justify-center space-x-3"
      onPaste={handlePaste}
    >
      {code.map((_, index) => (
        <input
          key={index}
          ref={(el) => (inputRefs.current[index] = el)}
          type="text"
          className={classNames(
            "h-[71px] w-[53px] rounded-lg border-2 text-center"
          )}
          value={code[index]}
          onChange={(event) => handleInputChange(event, index)}
          onKeyDown={(event) => handleKeyDown(event, index)}
          maxLength={1}
        />
      ))}
    </div>
  );
}

NumberInputs.propTypes = {
  onCodeChange: PropTypes.func.isRequired,
};
