import React, { useState } from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import {
  useProperty,
  usePropertyStats,
  toggleProperty,
} from "@/_actions/propertiesActions";
import NumberFormat from "react-number-format";
import Flicking from "@egjs/react-flicking";
import "@egjs/react-flicking/dist/flicking.css";
import { usePlan } from "@/_actions/authActions";
import downloadPropertyPdf from "../../utils/downloadPdf";
import Notification from "@/components/overlays/Notification";
import { usePromoter } from "@/_actions/membershipActions";

export default function PropertyDetail({
  id,
  title,
  status,
  mutateData,
  toggleModal,
  publishedProperties,
  activateProperty,
}) {
  const { data: propertyData } = useProperty(id);
  const { data: plan } = usePlan();
  const { data: stats } = usePropertyStats(id);
  const [showNotification, toggleNotification] = useState(false);
  const [textNotification, setTextNotification] = useState("");
  const [showNotificationError, toggleNotificationError] = useState(false);
  const [textNotificationError, setTextNotificationError] = useState("");
  const [timeNotification, setTimeNotification] = useState(0);
  const { data: promoter } = usePromoter(propertyData?.membership);

  const navigate = useNavigate();
  const editProperty = (id) => {
    navigate(`edit/${id}`);
  };

  const handleToggleNotification = (text) => {
    setTextNotification(text);
    toggleNotification(true);
  };

  const handleToggleNotificationError = (text) => {
    setTextNotificationError(text);
    toggleNotificationError(true);
  };

  return (
    <section>
      <div className="bg-gray-200">
        <p className="p-3">{title}</p>
      </div>
      <div className="space-y-3 p-3">
        <div>
          <Flicking
            className="grid grid-cols-1 gap-3"
            align="prev"
            circular={true}
          >
            {propertyData?.images?.map((i) => (
              <img key={i?.url} src={i?.url} />
            ))}
          </Flicking>
        </div>
        <div className="text-center md:hidden ">
          <button
            onClick={() => {
              status === "pending"
                ? publishedProperties <
                    plan?.plan_addons?.properties_quantity ||
                  plan?.plan_addons?.properties_quantity == -1
                  ? editProperty(id)
                  : window.alert(
                      "Has alcanzado el limite de propiedades activas."
                    )
                : editProperty(id);
            }}
            disabled={status === "inactive" ? true : false}
            className={`${
              status === "inactive"
                ? "button-secondary mr-2 cursor-not-allowed"
                : "button-secondary mr-2"
            }`}
          >
            Editar
          </button>
          {plan?.plan_addons?.properties_pdfs ? (
            <button
              className="button-secondary mr-2"
              onClick={() => {
                setTimeNotification(100000);
                handleToggleNotification(
                  "Descargando archivo. Puede tardar varios segundos."
                );
                downloadPropertyPdf(id)
                  .then(() => {
                    toggleNotification(false);
                    setTimeNotification(5000);
                    handleToggleNotification("Archivo descargado con éxito.");
                  })
                  .catch(() => {
                    toggleNotification(false);
                    handleToggleNotificationError("Ha ocurrido un error.");
                  });
              }}
            >
              Descargar PDF
            </button>
          ) : null}
          {status === "published" ? (
            <button
              onClick={() => {
                toggleProperty(
                  {
                    status: "inactive",
                    id: id,
                    confirm: true,
                  },
                  () => {
                    mutateData();
                    toggleModal(false);
                  }
                );
              }}
              className="button-secondary m-2"
            >
              Desactivar Propiedad
            </button>
          ) : null}
          {status === "pending" ? (
            <button disabled className="button-secondary mr-2">
              Desactivar Propiedad
            </button>
          ) : null}
          {status === "inactive" ? (
            <div className="has-tooltip">
              <button
                onClick={() => {
                  activateProperty({ ...propertyData, id: id });
                }}
                className="button-secondary mr-2"
              >
                Activar Propiedad
              </button>
            </div>
          ) : null}
        </div>
        <div className="grid grid-cols-2 gap-2">
          <span className="text-bold capitalize text-blue-cyt">ID: {id}</span>
          <div className="text-right">
            <span className="badge-green">
              {status === "published"
                ? "Publicado"
                : status === "pending"
                ? "Pendiente"
                : "Inactivo"}
            </span>
          </div>
        </div>
        <div className="space-y-3">
          <div className="flex justify-between">
            <p className="text-light text-gray-900">Promotor:</p>
            <div className="justify-end text-right">
              <p className="text-right font-bold">
                {propertyData?.contact_card?.name}
              </p>
              <p className="text-left ">{promoter?.email}</p>
              <p className="text-right ">
                <NumberFormat
                  className="text-right"
                  format="## ## ## ## ## ## ## ##"
                  displayType="text"
                  value={promoter?.whatsapp || promoter?.phone_number}
                ></NumberFormat>
              </p>
            </div>
          </div>

          {propertyData?.price_rent ? (
            <div className="grid grid-cols-2">
              <p className="text-gray-900">Precio Renta:</p>
              <NumberFormat
                className="text-right"
                prefix="$"
                displayType="text"
                thousandSeparator={true}
                value={propertyData?.price_rent}
                suffix={propertyData?.currency_rent}
              ></NumberFormat>
            </div>
          ) : null}

          {propertyData?.price_sale ? (
            <div className="grid grid-cols-2">
              <p className="text-gray-900">Precio Venta: </p>
              <NumberFormat
                className="text-right"
                prefix="$"
                displayType="text"
                thousandSeparator={true}
                value={propertyData?.price_sale}
                suffix={propertyData?.currency_sale}
              ></NumberFormat>
            </div>
          ) : null}

          {propertyData?.price_transfer ? (
            <div className="grid grid-cols-2">
              <p className="text-gray-900">Precio Traspaso: </p>{" "}
              <NumberFormat
                className="text-right"
                prefix="$"
                displayType="text"
                thousandSeparator={true}
                value={propertyData?.price_transfer}
                suffix={propertyData?.currency_transfer}
              ></NumberFormat>
            </div>
          ) : null}
          <p className="border-t-2 border-gray-200 pt-4 text-blue-cyt">
            Estadíasticas
          </p>
          <div className="grid grid-cols-2">
            {stats?.number_views ? (
              <div>
                <div className="grid-cols grid space-y-2">
                  <p className="">Número de visitas</p>
                  <p className="text-sm">{stats.number_views}</p>
                </div>
              </div>
            ) : null}
            {stats?.number_views ? (
              <div>
                <div className="grid-cols grid space-y-2">
                  <p>Número de contactos</p>
                  <p className="text-sm">{stats.number_contacts}</p>
                </div>
              </div>
            ) : null}
            {stats?.number_views ? (
              <div className="py-3">
                <div className="grid-cols grid space-y-2">
                  <p>CTR</p>
                  <p className="text-sm">{stats.ctr}</p>
                </div>
              </div>
            ) : null}
          </div>
        </div>
        <Notification
          open={showNotification}
          setOpen={toggleNotification}
          title={textNotification}
          timeOut={timeNotification}
        />
        <Notification
          open={showNotificationError}
          setOpen={toggleNotificationError}
          title={textNotificationError}
          type="error"
        />
      </div>
    </section>
  );
}

PropertyDetail.propTypes = {
  id: PropTypes.number,
  title: PropTypes.string,
  status: PropTypes.string,
  mutateData: PropTypes.func,
  toggleModal: PropTypes.func,
  publishedProperties: PropTypes.number,
  activateProperty: PropTypes.func,
};
