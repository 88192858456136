import React, { useState } from "react";
import { CheckIcon, InformationCircleIcon } from "@heroicons/react/outline";
import ModalProListings from "./ModalProListings";

export default function Plans() {
  const account = JSON.parse(localStorage.getItem("account"));
  const [showInfo2, setShowInfo2] = useState(false);
  const plans = {
    "particular-1": {
      name: "Particular 1 mes",
      benefit1: "Publica 1 Propiedad",
      benefit2: "Edita tu publicación sin límites",
      benefit3: "Acceso a analítica (Obtén reportes de tu publicación)",
      benefit4: "Sube imágenes ilimitadas y videos de tu propiedad",
      price: "600",
      time: "mensual",
    },
    "particular-3": {
      name: "Particular 3 meses",
      benefit1: "Publica 1 Propiedad",
      benefit2: "Edita tu publicación sin límites",
      benefit3: "Acceso a analítica (Obtén reportes de tu publicación)",
      benefit4: "Sube imágenes ilimitadas y videos de tu propiedad",
      price: "1,200",
      time: "trimestral",
    },
    "particular-destacado": {
      name: "Particular Destacado",
      benefit1:
        "Publica 1 Propiedad destacada, con alta visivilidad en los resultados de busqueda",
      benefit2: "Edita tu publicación sin límites",
      benefit3: "Acceso a analítica (Obtén reportes de tu publicación)",
      benefit4: "Sube imágenes ilimitadas y videos de tu propiedad",
      price: "1,600",
      time: "trimestral",
    },
    "pro-mini": {
      name: "PRO Mini",
      benefit1: "Publica 10 propiedades",
      benefit2: "1 propiedad destacada",
      benefit3: "Acceso a 1 usuario a la plataforma",
      benefit4: "Alta visibilidad en los resultados de búsqueda",
      benefit5: "Edita tus publicaciones sin límites",
      benefit6: "Acceso a analítica (Obtén reportes de tus publicaciones)",
      benefit7: "Sube imágenes y videos ilimitados",
      price: "9,000",
      time: "anual",
    },
    "pro-50": {
      name: "PRO 50",
      benefit1: "Publica 50 propiedades",
      benefit2: "8 propiedades destacadas",
      benefit3: "Acceso a 5 usuarios a la plataforma",
      benefit4: "Alta visibilidad en los resultados de búsqueda",
      benefit5: "Edita tus publicaciones sin límites",
      benefit6: "Acceso a analítica (Obtén reportes de tus publicaciones)",
      benefit7: "Sube imágenes y videos ilimitados",
      price: "12,200",
      time: "anual",
      listings: true,
    },
    "pro-ilimitado": {
      name: "PRO Ilimitado",
      benefit1: "Publica propiedades sin límite",
      benefit2: "15 propiedades destacadas",
      benefit3: "Acceso a 8 usuarios a la plataforma",
      benefit4: "Alta visibilidad en los resultados de búsqueda",
      benefit5: "Edita tus publicaciones sin límites",
      benefit6: "Acceso a analítica (Obtén reportes de tus publicaciones)",
      benefit7: "Sube imágenes y videos ilimitados",
      price: "20,500",
      time: "anual",
      listings: true,
    },
  };
  const plan = plans[account?.plan];

  return (
    <div className="w-[230px]">
      <div className="w-full p-3">
        <h2 className="mb-3 text-lg font-bold">{plan.name}</h2>
        <div className="space-y-5">
          <div className="flex items-center">
            <CheckIcon className="mr-1 h-5 w-5 rounded-full bg-[#d2ddf3] text-primary-default" />
            <span className="w-fit max-w-[180px] text-[10px]">
              {plan.benefit1}
            </span>
          </div>
          <div className="flex items-center">
            <CheckIcon className="mr-1 h-5 w-5 rounded-full bg-[#d2ddf3] text-primary-default" />
            <span className="w-fit max-w-[180px] text-[10px]">
              {plan.benefit2}
            </span>
          </div>
          <div className="flex items-center">
            <CheckIcon className="mr-1 h-5 w-5 rounded-full bg-[#d2ddf3] text-primary-default" />
            <span className="w-fit max-w-[180px] text-[10px]">
              {plan.benefit3}
            </span>
          </div>
          <div className="flex items-center">
            <CheckIcon className="mr-1 h-5 w-5 rounded-full bg-[#d2ddf3] text-primary-default" />
            <span className="w-fit max-w-[180px] text-[10px]">
              {plan.benefit4}
            </span>
          </div>
          {plan?.benefit5 && (
            <div className="flex items-center">
              <CheckIcon className="mr-1 h-5 w-5 rounded-full bg-[#d2ddf3] text-primary-default" />
              <span className="w-fit max-w-[180px] text-[10px]">
                {plan.benefit5}
              </span>
            </div>
          )}
          {plan?.benefit6 && (
            <div className="flex items-center">
              <CheckIcon className="mr-1 h-5 w-5 rounded-full bg-[#d2ddf3] text-primary-default" />
              <span className="w-fit max-w-[180px] text-[10px]">
                {plan.benefit6}
              </span>
            </div>
          )}
          {plan?.benefit7 && (
            <div className="flex items-center">
              <CheckIcon className="mr-1 h-5 w-5 rounded-full bg-[#d2ddf3] text-primary-default" />
              <span className="w-fit max-w-[180px] text-[10px]">
                {plan.benefit7}
              </span>
            </div>
          )}
          {plan?.listings && (
            <div>
              <div
                className="flex w-fit rounded-lg border border-[#d2ddf3] py-0.5 px-2 text-primary-default"
                onMouseEnter={() => setShowInfo2(true)}
                onMouseLeave={() => setShowInfo2(false)}
              >
                <InformationCircleIcon className=" mr-1 w-4" />
                <span className="text-xs">PRO Listings</span>
              </div>
              <ModalProListings className={showInfo2 ? "" : "hidden"} />
            </div>
          )}
          <div className="w-full text-center">
            <span>Inversión {plan.time}</span>
            <div className="flex items-end justify-center">
              <span>${plan.price}</span>
              <span className="ml-1 pb-0.5 text-xs">MXN</span>
              <span className="ml-1 pb-0.5 text-xs text-yellow-cyt-alt">
                + IVA
              </span>
            </div>
            <span className="text-[10px]">Pago en una sola exhibición</span>
          </div>
        </div>
      </div>
    </div>
  );
}
