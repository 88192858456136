import React, { useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import apiClient from "@/utils/apiClient";
import Notification from "@/components/overlays/Notification";
import * as Yup from "yup";
import PropTypes from "prop-types";

const UserSchema = Yup.object().shape({
  first_name: Yup.string().required(),
  last_name: Yup.string().required(),
  second_last_name: Yup.string(),
  email: Yup.string().required(),
  phone_number: Yup.string().required(),
  whatsapp: Yup.string(),
  password: Yup.string().required().min(6),
  confirmPassword: Yup.string()
    .required()
    .oneOf([Yup.ref("password"), null], "La contraseña debe coincidir"),
  role: Yup.string().required(),
});

export default function AddUserForm({ callBack }) {
  const submit = async (values) => {
    try {
      const cleanValues = Object.fromEntries(
        Object.entries(values).filter(([_, v]) => v != "")
      );
      await apiClient.post("/auth/membershipsignup/", cleanValues);
      callBack && callBack(values);
    } catch (err) {
      window.alert("El correo electrónico ya esta registrado.");
    }
  };
  return (
    <>
      <Formik
        initialValues={{
          first_name: "",
          last_name: "",
          second_last_name: "",
          email: "",
          whatsapp: "",
          password: "",
          confirmPassword: "",
          role: "broker",
        }}
        validationSchema={UserSchema}
        onSubmit={submit}
      >
        <Form className="block space-y-3">
          <div className="block">
            <label className="form-label" htmlFor="first_name">
              * Nombre
            </label>
            <Field
              id="first_name"
              className="input"
              type="text"
              name="first_name"
              placeholder="Nombre(s)"
            />
            <ErrorMessage
              render={(msg) => <div className="input-error">{msg}</div>}
              name="first_name"
            />
          </div>
          <div>
            <label className="form-label" htmlFor="last_name">
              * Apellido Paterno
            </label>
            <Field
              id="last_name"
              className="input"
              type="text"
              name="last_name"
              placeholder="Apellido Paterno"
            />
            <ErrorMessage
              render={(msg) => <div className="input-error">{msg}</div>}
              name="last_name"
            />
          </div>

          <div>
            <label className="form-label" htmlFor="second_last_name">
              Apellido Materno
            </label>
            <Field
              id="second_last_name"
              className="input"
              type="text"
              name="second_last_name"
              placeholder="Apellido Materno"
            />
            <ErrorMessage
              render={(msg) => <div className="input-error">{msg}</div>}
              name="second_last_name"
            />
          </div>

          <div>
            <label className="form-label" htmlFor="email">
              * Email
            </label>
            <Field
              id="email"
              className="input"
              type="email"
              name="email"
              placeholder="email"
            />
            <ErrorMessage
              render={(msg) => <div className="input-error">{msg}</div>}
              name="email"
            />
          </div>

          <div>
            <label className="form-label" htmlFor="whatsapp">
              Whatsapp
            </label>
            <Field
              id="whatsapp"
              className="input"
              type="text"
              name="whatsapp"
              placeholder="whatsapp"
            />
            <ErrorMessage
              render={(msg) => <div className="input-error">{msg}</div>}
              name="whatsapp"
            />
          </div>

          <div>
            <label className="form-label" htmlFor="phone_number">
              * Teléfono
            </label>
            <Field
              id="phone_number"
              className="input"
              type="text"
              name="phone_number"
              placeholder="teléfono"
            />
            <ErrorMessage
              render={(msg) => <div className="input-error">{msg}</div>}
              name="phone_number"
            />
          </div>

          <div>
            <label className="form-label" htmlFor="role">
              * Rol
            </label>
            <Field id="role" as="select" className="select" name="role">
              <option value="admin">Administrador</option>
              <option value="broker">Promotor</option>
            </Field>
            <ErrorMessage
              render={(msg) => <div className="input-error">{msg}</div>}
              name="role"
            />
          </div>

          <div>
            <label className="form-label" htmlFor="password">
              * Contraseña
            </label>
            <Field
              data-testid="password"
              id="password"
              className="input"
              type="password"
              name="password"
              placeholder="contraseña"
            />
            <ErrorMessage
              render={(msg) => <div className="input-error">{msg}</div>}
              name="password"
            />
          </div>

          <div>
            <label className="form-label" htmlFor="confirmPassword">
              * Confirmar Contraseña
            </label>
            <Field
              data-testid="confirmPassword"
              id="confirmPassword"
              className="input"
              type="password"
              name="confirmPassword"
              placeholder="confirmar contraseña"
            />
            <ErrorMessage
              render={(msg) => <div className="input-error">{msg}</div>}
              name="confirmPassword"
            />
          </div>

          <button className="button-primary w-full" type="submit">
            Crear Usuario
          </button>
        </Form>
      </Formik>
    </>
  );
}

AddUserForm.propTypes = {
  callBack: PropTypes.func,
};
