import apiClient from "../utils/apiClient";

/** @function
 * @name getPresignedUrl
 * @desc Gets an S3 presigned URL to upload files
 * @param {Object} config - Configuration object to get presigned url
 * @param {string} config.property - Property ID
 * @param {Array} config.urls - Array of objects with file extensions
 */
export const getPresignedUrl = async (config) => {
  const response = await apiClient.post("/aws_signature_url", config);
  return {
    method: "POST",
    url: response.data[0].url,
    fields: response.data[0].fields,
  };
};
