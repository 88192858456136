import React, { useState } from "react";
import { RadioGroup } from "@headlessui/react";
import LeadsReport from "./forms/LeadsReport";
import PropertiesReport from "./forms/PropertiesReport";
import InfoBanner from "@/components/elements/InfoBanner";

export default function index() {
  const [report, setReport] = useState("");
  return (
    <div className="sm:p-2 lg:p-4">
      <InfoBanner />
      <p className="title">Reportes</p>
      <div className="m-auto ">
        <RadioGroup value={report} onChange={setReport}>
          <RadioGroup.Label className="form-label">
            Selecciona el reporte que deseas consultar
          </RadioGroup.Label>
          <div className="my-3 flex  space-x-3 ">
            <RadioGroup.Option value="leads">
              {({ checked }) => (
                <span
                  className={`${checked ? "selectBox-selected" : "selectBox"}`}
                >
                  Contactos
                </span>
              )}
            </RadioGroup.Option>
            <RadioGroup.Option value="properties">
              {({ checked }) => (
                <span
                  className={`${checked ? "selectBox-selected" : "selectBox"}`}
                >
                  Propiedades
                </span>
              )}
            </RadioGroup.Option>
          </div>
        </RadioGroup>
      </div>
      <div>{report === "leads" ? <LeadsReport /> : null}</div>
      <div>{report === "properties" ? <PropertiesReport /> : null}</div>
    </div>
  );
}
