import React, { useState, useEffect } from "react";
import {
  ArrowLeftIcon,
  CheckIcon,
  XIcon,
  ExclamationCircleIcon,
} from "@heroicons/react/solid";
import DottedLine from "../signup/img/div.svg";
import WhatsAppIcon from "../contact/img/whatsapp.svg";
import * as Yup from "yup";
import { Formik, Form, Field, ErrorMessage } from "formik";
import classNames from "classnames";
import { useNavigate } from "react-router-dom";
import Modal from "@/components/overlays/Modal";
import RoundButton from "../../components/forms/RoundButton";
import acquisitionClient from "../../services/acquisitionApiClient";
import LogoPro from "/src/pages/login/img/logo-blue.svg";

export default function SignupForm() {
  const [showPass, setShowPass] = useState(false);
  const [invalidPlan, setInvalidPlan] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [showConflictMessage, setShowConflictMessage] = useState(false);
  const [email, setEmail] = useState("");
  const navigate = useNavigate();
  const urlParams = new URLSearchParams(window.location.search);
  const plan = urlParams.get("plan");
  const storedValuesString = localStorage.getItem("account");
  const account = JSON.parse(storedValuesString);
  const idPlans = {
    "particular-1": "Publica-Particular-1Mes-",
    "particular-3": "Publica-Particular-3Meses-",
    "particular-destacado": "Publica-Particular-Destacado-",
    "pro-mini": "Publica-Inmobiliaria-PROMini-",
    "pro-50": "Publica-Inmobiliaria-PRO50-",
    "pro-ilimitado": "Publica-Inmobiliaria-PROIlimitado-",
  };
  useEffect(() => {
    const allowedPlans = [
      "particular-1",
      "particular-3",
      "particular-destacado",
      "pro-mini",
      "pro-50",
      "pro-ilimitado",
    ];
    if (plan && !allowedPlans.includes(plan)) {
      setInvalidPlan(true);
    }
  }, [plan]);
  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email("Correo electrónico inválido")
      .required("Ingresa un correo electrónico válido"),
    phone: Yup.string()
      .matches(
        /^(\+1\d{10}|\+52\d{10}|\d{10})$/,
        "Debe ser un número de teléfono válido"
      )
      .required("Ingresa un numero de telefono."),
    name: Yup.string().required("Ingresa tu nombre."),
    lastName: Yup.string().required("Ingresa tu apellido."),
    password: Yup.string()
      .min(8, "La contraseña debe ser de almenos 8 caracteres")
      .required("Ingresa una contraseña valida."),
  });

  const submit = async (values) => {
    if (
      values.email === account?.values?.email &&
      values.password !== account?.values?.password
    ) {
      setShowAlert(true);
    } else {
      setEmail(values.email);
      try {
        const response = await acquisitionClient.post(
          "/customers/auto-registration/",
          values
        );
        const user = response.data;
        localStorage.setItem(
          "account",
          JSON.stringify({
            user,
            values,
            plan,
          })
        );

        navigate(`/verify-account`);
      } catch (err) {
        if (err.response.status >= 400) {
          if (err.response.status === 409) {
            setShowConflictMessage(true);
          }
        }
      }
    }
  };

  return (
    <div className="flex w-full items-center justify-center">
      <div className="flex w-full max-w-[1440px] flex-col items-center justify-center">
        <div className="flex w-full p-6">
          <img className="w-[180px]" src={LogoPro} />
        </div>
        <div className="w-full">
          <a
            id={`${idPlans[plan]}Volver`}
            className="flex w-fit items-center p-6"
            href="https://www.casasyterrenos.com/publica"
          >
            <ArrowLeftIcon className="mr-2 w-4 md:h-5 md:w-5" />
            <span className="text-lg">Volver</span>
          </a>
        </div>
        <div>
          <h1 className="mb-6 text-center text-2xl md:text-4xl lg:text-5xl ">
            Comienza a recibir clientes
          </h1>
          <div className="mx-2 flex space-x-1 md:mx-0 md:space-x-4">
            <div className="flex w-fit items-center">
              <CheckIcon className="mr-1.5 w-4 rounded-full bg-yellow-cyt-alt p-0.5 text-white md:h-5 md:w-5" />
              <h2 className="text-[10px] md:text-sm lg:text-xl">
                Elige tu paquete
              </h2>
            </div>
            <img className="hidden w-[50px] lg:flex" src={DottedLine}></img>
            <div className="flex w-fit items-center">
              <span className="mr-1.5 flex h-5 w-5 items-center justify-center rounded-full bg-primary-default text-xs text-white md:h-6 md:w-6 md:text-base">
                2
              </span>
              <h2 className="text-xs font-bold text-primary-default	md:text-lg lg:text-2xl">
                Crea tu cuenta
              </h2>
            </div>
            <img className="hidden w-[50px] lg:flex" src={DottedLine}></img>
            <div className="flex w-fit items-center">
              <span className="mr-1.5 flex h-4 w-4 items-center justify-center rounded-full bg-[#A5A5A5] text-[10px] text-white md:h-5 md:w-5 md:text-base">
                3
              </span>
              <h2 className="text-[10px] text-[#A5A5A5] md:text-sm lg:text-xl">
                Verifica tu cuenta
              </h2>
            </div>
            <img className="hidden w-[50px] lg:flex" src={DottedLine}></img>
            <div className="flex w-fit items-center">
              <span className="mr-1.5 flex h-4 w-4 items-center justify-center rounded-full bg-[#A5A5A5] text-[10px] text-white md:h-5 md:w-5 md:text-base">
                4
              </span>
              <h2 className="text-[10px] text-[#A5A5A5] md:text-sm lg:text-xl">
                Finaliza con tu pago
              </h2>
            </div>
          </div>
          <div className="flex w-full items-center justify-center">
            <div className="w-full max-w-[432px] py-5 px-4">
              <Formik
                initialValues={{
                  email: account?.values?.email || "",
                  phone: account?.values?.phone || "",
                  name: account?.values?.name || "",
                  lastName: account?.values?.lastName || "",
                  password: account?.values?.password || "",
                }}
                validationSchema={validationSchema}
                onSubmit={submit}
              >
                {({ errors, touched, isValid, values }) => (
                  <Form>
                    <div className="h-[90px]">
                      <span>Correo</span>
                      <Field
                        name="email"
                        id="phone"
                        type="text"
                        className={classNames(
                          errors.email && touched.email
                            ? "border-red-500 focus:border-red-500"
                            : "border-neutral-300",
                          "w-full rounded-lg border-[2px] p-2 ring-0 focus:border-[#8CB2FF] focus:ring-0"
                        )}
                        placeholder="Ej. tunombre@mail.com"
                      />
                      <ErrorMessage
                        component="span"
                        className="input-error"
                        name="email"
                      />
                    </div>
                    <div className="h-[90px]">
                      <span>Teléfono (WhatsApp)</span>
                      <Field
                        name="phone"
                        id="phone"
                        type="text"
                        className={classNames(
                          errors.phone && touched.phone
                            ? "border-red-500 focus:border-red-500"
                            : "border-neutral-300",
                          "w-full rounded-lg border-[2px] p-2 ring-0 focus:border-[#8CB2FF] focus:ring-0"
                        )}
                        placeholder="Teléfono fijo o móvil"
                      />
                      <ErrorMessage
                        component="span"
                        className="input-error"
                        name="phone"
                      />
                    </div>
                    <div className="h-[90px]">
                      <span>Nombre</span>
                      <Field
                        name="name"
                        id="name"
                        type="text"
                        className={classNames(
                          errors.name && touched.name
                            ? "border-red-500 focus:border-red-500"
                            : "border-neutral-300",
                          "w-full rounded-lg border-[2px] p-2 ring-0 focus:border-[#8CB2FF] focus:ring-0"
                        )}
                        placeholder="Escribe tu nombre"
                      />
                      <ErrorMessage
                        component="span"
                        className="input-error"
                        name="name"
                      />
                    </div>
                    <div className="h-[90px]">
                      <span>Apellido</span>
                      <Field
                        name="lastName"
                        id="lastName"
                        type="text"
                        className={classNames(
                          errors.lastName && touched.lastName
                            ? "border-red-500 focus:border-red-500"
                            : "border-neutral-300",
                          "w-full rounded-lg border-[2px] p-2 ring-0 focus:border-[#8CB2FF] focus:ring-0"
                        )}
                        placeholder="Escribe tu apellido"
                      />
                      <ErrorMessage
                        component="span"
                        className="input-error"
                        name="lastName"
                      />
                    </div>
                    <div className="">
                      <span>Contraseña</span>
                      <Field
                        id="password"
                        type={showPass ? "text" : "password"}
                        name="password"
                        className={classNames(
                          errors.password && touched.password
                            ? "border-red-500 focus:border-red-500"
                            : "border-neutral-300",
                          "w-full rounded-lg border-[2px] p-2 ring-0 focus:border-[#8CB2FF] focus:ring-0"
                        )}
                        placeholder="Escribe tu contraseña"
                      />
                      <ErrorMessage
                        component="span"
                        className="input-error text-red-500"
                        name="password"
                      />
                    </div>
                    <div className="mb-3">
                      Usa mínimo 8 caracteres para que sea más segura
                    </div>
                    <div className="mb-4 flex select-none">
                      <CheckIcon
                        className={classNames(
                          showPass
                            ? "border-primary-default bg-primary-default"
                            : "border-neutral-300",
                          "mr-2 w-6 cursor-pointer border text-white"
                        )}
                        onClick={() => setShowPass(!showPass)}
                      />
                      <span>Mostrar contraseña</span>
                    </div>
                    <RoundButton
                      id={`${idPlans[plan]}CrearCuenta`}
                      type="submit"
                      active={
                        isValid &&
                        values.email &&
                        values.password &&
                        values.phone
                      }
                      text="Crear cuenta"
                    />
                    <div className="px-2 pt-2">
                      <span className="text-sm">
                        Al crear la cuenta, acepto los{" "}
                        <a
                          href={
                            "https://www.casasyterrenos.com/terminos-y-condiciones"
                          }
                          target="_blank"
                          rel="noreferrer"
                          className="text-blue-700 underline"
                        >
                          Términos de uso
                        </a>{" "}
                        de Casas y Terrenos.
                      </span>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
        <Modal open={!plan || invalidPlan}>
          <div className="flex flex-col items-center justify-center p-8">
            <span className="mb-9 text-2xl">
              {invalidPlan ? "Plan invalido" : "Necesita elegir un plan"}
            </span>
            <a
              href="https://www.casasyterrenos.com/publica"
              className="z-10 w-full"
            >
              <RoundButton active={true} text={"Elegir plan"} />
            </a>
          </div>
        </Modal>
        <Modal open={showAlert}>
          <div className="flex flex-col items-center justify-center p-8">
            <span className="mb-9 text-center text-xl ">
              La contraseña debe ser la misma que ingreso anteriormente.
            </span>
            <div className="z-10 w-full" onClick={() => setShowAlert(false)}>
              <RoundButton active={true} text={"Aceptar"} />
            </div>
          </div>
        </Modal>
        <Modal open={showConflictMessage} setOpen={setShowConflictMessage}>
          <div className="w-full pl-4 pt-4">
            <XIcon
              className="w-7 cursor-pointer rounded-full p-1 text-gray-700 hover:bg-gray-200"
              onClick={() => setShowConflictMessage(false)}
            />
          </div>
          <div className="flex flex-col items-center justify-center px-6 pb-6">
            <ExclamationCircleIcon className=" mb-3 w-20 text-yellow-cyt-alt" />
            <span className="mb-2 text-center font-bold">
              Ya existe un usuario con ese correo.
            </span>
            <span className="mb-4 text-center text-sm">
              Intenta crear tu cuenta con otro correo o contacta a uno de
              nuestros asesores.
            </span>

            <div className="z-10 w-full" onClick={() => setShowAlert(false)}>
              <div className="flex w-full cursor-pointer items-center justify-center rounded-full border-2 border-green-whatsapp bg-green-whatsapp text-sm text-white hover:border-white hover:bg-green-whatsapp-darker active:border-green-whatsapp lg:text-base">
                <a
                  href={`https://api.whatsapp.com/send?phone=+523319100955&text=Hola! estoy intentando crear una cuenta con el correo ${email}, pero ya hay un usuario con ese correo.`}
                  className="flex h-full w-full items-center justify-center rounded-full border-2 border-green-whatsapp py-2 active:border-white"
                >
                  <img src={WhatsAppIcon} className="mr-2 w-5" />
                  <span>Contactar con un asesor</span>
                </a>
              </div>
            </div>
          </div>
        </Modal>
      </div>
    </div>
  );
}
