export const operationTypes = [
  { id: 1, name: "Venta" },
  { id: 2, name: "Renta" },
  { id: 3, name: "Traspaso" },
];

export const status = [
  { id: "", name: "Todos" },
  { id: "published", name: "Publicado" },
  { id: "pending", name: "Pendiente" },
  { id: "inactive", name: "Inactivo" },
];

export const order = [
  { id: "-created", name: "Más reciente" },
  { id: "created", name: "Menos reciente" },
  { id: "id", name: "ID menor a mayor" },
  { id: "-id", name: "ID mayor a menor" },
  { id: "price", name: "Menor precio" },
  { id: "-price", name: "Mayor precio" },
];
