import apiClient from "../utils/apiClient";
import useSWR from "swr";

export const IntegrationsAPI = {
  useGetCRM,
  updateCRM,
  deleteCRM,
  removeIntegration,
  useFeeds,
  updateEB,
  useGetPropertyErrors,
};

/**
 * @typedef {Object} SWRObject
 * @property {object} data - Return if the agency id is valid
 */

/** @function
 * @name updateEB
 * @description - fetching to the EB API
 * @returns {SWRObject} - swr Object
 */

async function updateEB(data) {
  return await apiClient.post("clients/crm/agency/", data);
}

/**
 * @typedef {Object} SWRObject
 * @property {object} data - The data returned by the swr call
 */

/** @function
 * @name useGetCRM
 * @description - fetching to the crm from the client
 * @returns {SWRObject} - swr Object
 */

function useGetCRM() {
  const fetcher = (url) => apiClient.get(url).then((res) => res?.data);
  const { data, error, mutate } = useSWR("clients/pro/link/crm/", fetcher);
  return { data, error, mutate };
}
/** @function
 * @name updateCRM
 * @description - Update the CRM from the client
 * @param {Object} data - Values from the CRM
 * @returns {Object} - Returns the response Axios Achema
 */
async function updateCRM(data) {
  return apiClient.patch("clients/pro/link/crm/", data);
}

/** @function
 * @name deleteCRM
 * @description - Detele the CRM from the client
 */
async function deleteCRM() {
  try {
    await apiClient.delete("clients/pro/link/crm/");
  } catch (err) {
    $log.error(err);
  }
}
/** @function
 * @name removeIntegration
 * @description - Remothe the integration from the client
 */

async function removeIntegration() {
  if (window.confirm("¿Estás seguro que quieres eliminar esta integración?")) {
    await apiClient.delete("/clients/pro/link/crm/");
  } else {
    throw new Error("Cancelled action");
  }
}
/**
 * @typedef {Object} SWRObject
 * @property {object} data - The data returned by the swr call
 */
/** @function
 * @name useFeeds
 * @description - obtain the properties feeds active
 * @returns {SWRObject} - swr Object
 */
function useFeeds() {
  const fetcher = (url) => apiClient.get(url).then((res) => res.data);
  const { data, error } = useSWR("/property/feeds?status=1", fetcher);
  return { data, isLoading: !error && !data, error };
}

function useGetPropertyErrors(params) {
  const fetcher = (url) => apiClient.get(url).then((res) => res?.data);
  const { data, error, mutate } = useSWR(
    `externalsyncerrors?created__gte=${params.date}&client_id=${params.client}&page=${params.page}&feed_id=${params.feedId}`,
    fetcher
  );
  return { data, isLoading: !error && !data, error, mutate };
}
