import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

export default function RoundButton({ active, text, ...rest }) {
  return (
    <div
      className={classNames(
        active
          ? "cursor-pointer border-primary-default bg-primary-default hover:border-white hover:text-primary-default active:border-primary-default"
          : "border-[#C7DAFF] bg-[#C7DAFF]",
        "flex w-full items-center justify-center rounded-full border-2 text-sm text-white hover:bg-[#C7DAFF] lg:text-base"
      )}
    >
      <button
        {...rest}
        disabled={!active}
        className={classNames(
          active
            ? "border-primary-default active:border-white"
            : "border-[#C7DAFF]",
          "flex h-full w-full items-center justify-center rounded-full border-2 py-2 "
        )}
      >
        {text}
      </button>
    </div>
  );
}

RoundButton.propTypes = {
  active: PropTypes.bool,
  text: PropTypes.string,
};
