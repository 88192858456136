import React, { useState } from "react";
import { Formik, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { format } from "date-fns";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import { getReports } from "@/_actions/reportActions";

const LeadsSchema = Yup.object().shape({
  start_date: Yup.string().required("Debes seleccionar un rango de fechas"),
  end_date: Yup.string().required("Recuerda seleccionar fecha inicial a final"),
});

function LeadsReport({ callBack }) {
  const [loading, setLoading] = useState(false);
  const submit = async (values, { resetForm }) => {
    try {
      setLoading(true);
      const v = { ...values };
      v.start_date = format(values.start_date, "yyyy-MM-dd");
      v.end_date = format(values.end_date, "yyyy-MM-dd");
      v.typeReport = "my_leads_report";
      let response = await getReports(v);
      callBack && callBack(v);
      const url = URL.createObjectURL(new Blob([response.data]));
      const fakeLink = document.createElement("a");
      fakeLink.href = url;
      fakeLink.setAttribute("download", "file.csv");
      document.body.appendChild(fakeLink);
      fakeLink.click();
      resetForm();
      setLoading(false);
    } catch (err) {
      $log.error(err);
    }
  };

  return (
    <div>
      <Formik
        initialValues={{
          start_date: "",
          end_date: "",
        }}
        validationSchema={LeadsSchema}
        onSubmit={submit}
      >
        {({ values, setFieldValue }) => (
          <Form className=" max-w-fit">
            <div className="my-8 md:flex md:flex-wrap md:gap-3">
              <div className="mr-4">
                <label className="form-label" htmlFor="start">
                  Selecciona un rango de fechas
                </label>
                <div className="flex flex-col">
                  <DatePicker
                    id="start"
                    data-testid="start"
                    name="start"
                    className="input w-60"
                    selectsRange={true}
                    startDate={values.start_date}
                    endDate={values.end_date}
                    allowSameDay
                    onChange={([start_date, end_date]) => {
                      setFieldValue("start_date", start_date);
                      setFieldValue("end_date", end_date || "");
                    }}
                  />
                  <ErrorMessage
                    component="span"
                    className="input-error w-60 overflow-visible whitespace-nowrap"
                    name="start_date"
                  />
                  <ErrorMessage
                    component="span"
                    className="input-error w-60 overflow-visible whitespace-nowrap"
                    name="end_date"
                  />
                </div>
              </div>
            </div>
            <div className="mt-3 flex justify-end ">
              <button
                type="submit"
                className={`button-primary ${
                  loading ? "cursor-not-allowed" : "cursor-pointer"
                }`}
                disabled={loading}
              >
                Exportar
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}

LeadsReport.defaultProps = { callBack: null };

export default LeadsReport;
