import React, { useState } from "react";
import { useProfile } from "@/_actions/userActions";
import EditProfile from "./forms/EditProfile";

export default function UserProfile() {
  const {
    data: profile = {},
    isLoading,
    error,
    mutate: profileMutate,
  } = useProfile();
  const [showEditProfile, toggleEditProfile] = useState(false);

  return (
    <div>
      <div className="pt-6 md:flex">
        <div className="">
          <p className="mt-1 block text-xl font-medium leading-tight text-black">
            Datos de contacto
          </p>
          <p className="mt-2 text-gray-500">
            Estos son los datos que los usuarios de la plataforma verán en las
            propiedades que tengas asignadas.
          </p>

          {profile && !showEditProfile ? (
            <div>
              <div className="mt-3 text-sm font-semibold uppercase tracking-wide text-blue-800">
                {profile && profile.first_name} {profile && profile.last_name}
              </div>
              <div className="mt-3 flex">
                <svg
                  className="h-6 w-6 flex-shrink-0 text-gray-400"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z"
                  />
                </svg>
                <span className="ml-3">
                  {profile ? profile?.contact?.mobile : "------------"}
                </span>
              </div>
              <div className="mt-3 flex">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6 flex-shrink-0 text-gray-400"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                  <path d="M3 21l1.65 -3.8a9 9 0 1 1 3.4 2.9l-5.05 .9" />
                  <path d="M9 10a0.5 .5 0 0 0 1 0v-1a0.5 .5 0 0 0 -1 0v1a5 5 0 0 0 5 5h1a0.5 .5 0 0 0 0 -1h-1a0.5 .5 0 0 0 0 1" />
                </svg>
                <span className="ml-3">
                  {profile ? profile?.contact?.whatsapp : "------------"}
                </span>
              </div>
              <div className="mt-3 flex">
                <svg
                  className="h-6 w-6 flex-shrink-0 text-gray-400"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
                  />
                </svg>
                <span className="ml-3">
                  {profile ? profile.email : "------------"}
                </span>
              </div>
              <button
                onClick={() => {
                  toggleEditProfile(true);
                }}
                type="button"
                data-splitbee-event="account-edit-contact"
                className="button-secondary mt-5"
              >
                <span className="inline">Editar </span>
                <svg
                  className=" ml-2 -mr-0.5 inline h-4 w-4"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"
                  ></path>
                </svg>
              </button>
            </div>
          ) : null}

          {showEditProfile ? (
            <EditProfile
              data={profile}
              callBack={() => {
                toggleEditProfile(false);
                profileMutate();
              }}
            />
          ) : null}
        </div>
      </div>
    </div>
  );
}
