import React from "react";
import UserProfile from "./User";
import BusinessProfile from "./Business";

export default function index() {
  return (
    <div className="space-y-10 divide-y">
      <BusinessProfile />
      <UserProfile />
    </div>
  );
}
